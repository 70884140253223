import instance from '../../utils/axios/api';


export const addPack = (data) => () => instance.put('pack/create/', data, {
    headers: {
        'Content-Type': 'multipart/form-data',
    }
});

export const updatePack = (data) => () => instance.post('pack/update/', data);

export const deletePack = (id) => () => instance.delete(`pack/delete/${id}`);
export const getPack = (id) => () => instance.get(`pack/byid/${id}`);


export const fetchPacks = ({
    page = 0, rowsPerPage = 10, order = 'desc', orderBy = 'updatedAt', searchText = ''
}) => () => instance.post('pack/fetch/', {
    page, rowsPerPage, order, orderBy, searchText
});

export const fetchPublicOnlyPacks = ({
    page = 0, rowsPerPage = 10, order = 'desc', orderBy = 'updatedAt', fullVersion = true,
}) => () => instance.post('pack/fetch/public', {
    page, rowsPerPage, order, orderBy, fullVersion
});

export const updatePackPicture = (data) => () => instance.post('pack/update/picture', data, {
    headers: {
        'Content-Type': 'multipart/form-data',
    }
});
