import React, { Fragment, useEffect, useState } from 'react'
import { momentLocalizer, Views } from 'react-big-calendar'
import moment from 'moment'
import 'moment/locale/fr'  // without this line it didn't work

import { Checkbox, FormControl, Stack, Typography } from '@mui/material';
import { FiStar } from 'react-icons/fi';
import PageHelmet from 'component/common/Helmet';
import { DnDCalendar, formats, getEventClassName, ALL_TYPES, messages, TYPE_COLORS, TYPE_LABELS, INIT_EVENT } from './data';
import { useDispatch } from 'react-redux';
import { fetchClientEvents } from 'state/actions/program';

import { yellow } from '@mui/material/colors';
import UserEvent from 'elements/user.event';



moment.locale('fr');
const localizer = momentLocalizer(moment)


const MyCalendar = () => {
    const dispatch = useDispatch();
    const INIT_RANGE = {
        start: moment().startOf('month').subtract(1, 'week').toDate(),
        end: moment().endOf('month').add(1, 'week').toDate()
    }


    const [events, setEvents] = useState([]);
    const [eventTypes, setEventTypes] = useState(ALL_TYPES)
    const [range, setRange] = useState(INIT_RANGE);


    const handleRangeChange = (ev) => {
        if (ev?.start && ev?.end) {
            setRange(ev);
        }
    };

    const [selectedEvent, setSelectedEvent] = useState(INIT_EVENT)
    const [newProgramDisplay, setNewProgramDisplay] = useState(false);

    const handleEventSelect = (event) => {
        setSelectedEvent(event);
        setNewProgramDisplay(true);
    }

    const convertToEvent = (data) => data.map(item => ({
        ...item,
        start: moment(`${item.date}T${item.startTime}`).toDate(),
        end: moment(`${item.date}T${item.endTime}`).toDate(),
    }));

    useEffect(() => {
        console.log('range', range)
        dispatch(fetchClientEvents(range, eventTypes)).then(({ status, data }) => {
            if (status === 200) {
                const evts = convertToEvent(data);
                setEvents(evts);
            }
        })
    }, [range, eventTypes])


    const onEventResize = (data) => {
        const { start, end } = data;
        setEvents((state) => ({ ...state, start, end }));
    };

    const eventComponent = (ev) => {
        return (
            <div id={ev.event?.id} style={{ maxHeight: 40 }}>
                <div id={`title ${ev.event?.id} `} style={{ fontSize: 12 }}>{ev.title}</div>
                <div id={`dec ${ev.event?.id} `} style={{ width: '100%', textAlign: 'right' }}>
                    {ev.event?.program?.id &&
                        (<>
                            <i id={`i ${ev.event?.id} `} style={{ fontSize: 8 }}>{ev.event?.program?.title}</i>
                            <FiStar id={`icon ${ev.event?.id} `} style={{ color: yellow[500] }} title={ev.event?.program?.title} />
                        </>)
                    }
                </div>
            </div>
        );
    }

    const handleEventTypes = (event, type) => {
        const { checked } = event.target;
        setEventTypes((state => !checked ? [...state.filter(t => t !== type)] : [...state, type]))
    }

    const renderTypeCheckbox = (type, index) =>
    (<span id={'span checkbox' + index}>
        <Checkbox
            checked={eventTypes.includes(type)}
            id={'checkbox' + index}
            onChange={(e) => handleEventTypes(e, type)}
            sx={{
                color: TYPE_COLORS[index],
                '&.Mui-checked': {
                    color: TYPE_COLORS[index],
                },
            }}
        ></Checkbox>{TYPE_LABELS[index]}</span>);

    return (
        <Fragment>
            <PageHelmet pageTitle='Calendrier' />
            <div className="rn-blog-area ptb--20 bg_color--1">

                <div className="container">
                    <Fragment>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                            <Stack direction="row" alignItems="left">
                                <Typography gutterBottom>
                                </Typography>
                                <Stack direction="row" spacing={1}>
                                    <FormControl style={{ marginTop: -9, paddingLeft: 20 }}>
                                        <div>
                                            {ALL_TYPES?.map((type, index) => renderTypeCheckbox(type, index))}
                                        </div>
                                    </FormControl>
                                </Stack>
                            </Stack>
                        </Stack>
                        <UserEvent
                            open={newProgramDisplay}
                            setOpen={setNewProgramDisplay}
                            selectedEvent={selectedEvent}
                            setSelectedEvent={setSelectedEvent}
                        />
                    </Fragment>
                    <DnDCalendar
                        className={'rbc-timeslot-group bigCalendar'}
                        localizer={localizer}
                        events={events}
                        onRangeChange={handleRangeChange}
                        defaultDate={moment().toDate()}
                        formats={formats}
                        defaultView={Views.MONTH}
                        messages={messages}
                        onEventResize={onEventResize}
                        resizable={false}
                        selectable
                        onSelectEvent={handleEventSelect}
                        eventPropGetter={(event) => ({ className: getEventClassName(event) })}
                        style={{ minHeight: 500 }}
                        components={{
                            event: eventComponent
                        }}
                    />
                </div>
            </div>
        </Fragment>
    )
}

export default MyCalendar