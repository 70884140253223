import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { CARE_COLOR, WORKSHOP_COLOR } from 'utils/constantes';
import { MONTHS } from './config';

const BarChart = (props) => {
    const { stats } = props;
    const [data, setData] = useState({ labels: [], datasets: [] })
    useEffect(() => {
        const labels = stats.map(({ month, year }) => `${MONTHS[parseInt(month, 10) - 1]} ${year} `);
        const datasets = [{
            label: 'Ateliers',
            data: stats.map(({ workshop_count }) => workshop_count),
            backgroundColor: WORKSHOP_COLOR,
            borderColor: WORKSHOP_COLOR,
        },
        {
            label: 'Soins',
            data: stats.map(({ care_count }) => care_count),
            backgroundColor: CARE_COLOR,
            borderColor: CARE_COLOR,
        },]
        setData({ ...data, labels, datasets })

    }, [stats])

    const options = {
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };

    return <Bar data={data} options={options} />;
}

export default BarChart
