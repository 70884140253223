import { Calendar } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import { CARE, CARE_COLOR, OTHER, OTHER_COLOR, WORKSHOP, WORKSHOP_COLOR } from 'utils/constantes';

export const messages = {
    allDay: 'Toute la journée',
    previous: 'Précédent',
    next: 'Suivant',
    today: 'Actuel',
    month: 'Mois',
    week: 'Semaine',
    day: 'Jour',
    agenda: 'Agenda',
    date: 'Date',
    time: 'Heure',
    event: 'Événement',
    noEventsInRange: 'Aucun événement dans cette plage de dates.',
    showMore: total => `+ ${total} de plus`,

};

export const formats = {
    dateFormat: 'dd',
}

export const DnDCalendar = withDragAndDrop(Calendar);

export const getEventClassName = (event) => {
    // Retourne la classe CSS correspondante en fonction de la couleur de l'événement
    switch (event?.service?.type) {
        case WORKSHOP:
            return 'workshop-event';
        case CARE:
            return 'care-event';
        default:
            return 'other-event';
    }
}

export const ALL_TYPES = [WORKSHOP, CARE, OTHER];
export const INIT_TYPES = [WORKSHOP, OTHER];
export const TYPE_COLORS = [WORKSHOP_COLOR, CARE_COLOR, OTHER_COLOR];
export const TYPE_LABELS = ['Ateliers', 'Soins', 'Autres'];

export const event_modal_style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '800px',
    height: '700px',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

export const INIT_EVENT = {
    id: null,
    title: '',
    date: '',
    start: '',
    end: '',
    startTime: '',
    endTime: '',
    service: null,
    program: null,
    coach: null,
    participants: [],
    guests: []
}