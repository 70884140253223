import React, { useEffect, useState } from 'react'
import PageHelmet from '../component/common/Helmet';
import Breadcrumb from '../elements/common/Breadcrumb';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from 'react-icons/fi';
import Header from '../component/header/Header';
import Footer from '../component/footer/FooterTwo';

import { useDispatch } from 'react-redux';
import { fetchTricks } from 'state/actions/trick';
import { API_URL } from 'utils/constantes';
import { Link } from 'react-router-dom';



const Gallery = () => {

    const dispatch = useDispatch();
    const [photos, setPhotos] = useState([]);
    useEffect(() => {
        const fetch = async () => {
            try {
                const { data: apiData, status } = await dispatch(fetchTricks({ rowsPerPage: 6 }));
                if (status === 200) {
                    const { rows } = apiData;
                    setPhotos(rows);
                }
            } catch (e) {
                console.log(e);
            }
        };
        fetch();
    }, []);

    return (
        <div>

            <PageHelmet pageTitle='Espace' />

            {/* Start Header Area  */}
            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
            {/* End Header Area  */}

            {/* Start Breadcrump Area */}
            <Breadcrumb title={'Espace'} />
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper  */}
            <main className="page-wrapper">

                {/* Start Portfolio Area  */}
                <div className="rn-portfolio-area ptb--120 bg_color--1 line-separator">
                    <div className="container">
                        <div className="row">
                            {photos.map((value, index) => (
                                <div className="col-lg-6" key={index}>

                                    <div className="item-portfolio-static">
                                        <div onClick={() => setState({ isOpen: true, tab1: index })}>
                                            <div className="portfolio-static">
                                                <div className="thumbnail-inner">
                                                    <div className="thumbnail">
                                                        <Link to="#">
                                                            <img src={`${API_URL}images/gallery/${value.image}`} alt="Portfolio Images" />
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="content">
                                                    <div className="inner">
                                                        <p>{value.category}</p>
                                                        <h4>{value.title}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Portfolio Area  */}
            </main>
            {/* End Page Wrapper  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            {/* Start Footer Area  */}
            <Footer />
            {/* End Footer Area  */}
        </div>
    )
}


export default Gallery;