import React from 'react';
import Sidebar from './sidebar';

const Layout = (props) => (
    <div className="service-area creative-service-wrapper pt--90 pb--90 bg_color--1">
        <section id="vendor_area" className="ptb-50">
            <div className="container-fluid">
                <div className="row">
                    <Sidebar />
                    <div className="col-sm-12 col-md-12 col-lg-9">
                        <div className="tab-content dashboard_content">
                            <div className="tab-pane fade show active">
                                {props.children}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
);

export default Layout;
