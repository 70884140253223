import React from 'react';
import {
  FiCalendar, FiPenTool,
} from 'react-icons/fi';
import { Link, useLocation } from 'react-router-dom';

const Sidebar = () => {
  const location = useLocation();
  return (
    <div className="col-sm-12 col-md-12 col-lg-3">
      <div className="dashboard_tab_button">
        <ul role="tablist" className="nav flex-column dashboard-list">
          <li>
            <Link to="/client/calendar" className={location.pathname === '/client/calendar' ? 'active' : null}>
              <FiCalendar />
              {' '}
              Mon calendrier
            </Link>
          </li>
          <li>
            <Link to="/client/requests" className={location.pathname === '/client/requests' ? 'active' : null}>
              <FiPenTool />
              {' '}
              {'Mes demandes de réservation'}
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
