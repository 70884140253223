import React, { useEffect, useState } from 'react'
import { FiCheck } from 'react-icons/fi'
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchPublicOnlyPacks } from 'state/actions/pack';

const HomePricing = () => {
    const dispatch = useDispatch();
    const [packs, setPacks] = useState([]);
    useEffect(() => {
        const fetch = async () => {
            try {
                const { data: apiData, status } = await dispatch(fetchPublicOnlyPacks({ rowsPerPage: 3 }));
                if (status === 200) {
                    const { rows } = apiData;
                    setPacks(rows);
                }
            } catch (e) {
                console.log(e);
            }
        };
        fetch();
    }, []);

    const displayPack = (pack) => {
        return (
            <>
                <div className="col-lg-4 col-md-6 col-12 mt--30" key={`pack_${pack.id}`}>
                    <div className="rn-pricing" style={{ minHeight: 550 }}>
                        <div className="pricing-table-inner">
                            <div className="pricing-header">
                                <h4 className="title">{pack.title}</h4>
                                <div className="pricing">
                                    <span className="price">{pack.price - (pack.sale || 0)}</span>
                                    <span className="subtitle">DT</span>
                                </div>
                            </div>
                            <div className="pricing-body">
                                <ul className="list-style--1">
                                    {pack.services?.slice(0, 3).map(({ occurrence, service }) =>
                                        <li key={service.id}>
                                            <FiCheck />
                                            {' '}
                                            {`${occurrence} ${service.title}`}
                                        </li>
                                    )}
                                    {pack.services?.length > 3 && (<li> ... </li>)}
                                </ul>
                            </div>
                            <div className="pricing-footer" style={{ position: 'absolute', bottom: 30, width: '10%', left: '32%', textAlign: 'center' }}>
                                <Link className="rn-btn" to={`/pack/${pack.id}`}>Découvrir</Link>
                            </div>
                        </div>
                    </div>
                </div>

            </>)
    }

    return (
        <div className="rn-pricing-table-area ptb--70 bg_color--5">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title service-style--3 text-center mb--25 mb_sm--0">
                            <h2 className="title">NOS PACKS</h2>
                            <p>
                                There are many variations of passages of Lorem
                                Ipsum available, but the majority have suffered alteration.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <>
                        {packs.map((pack) => displayPack(pack))}
                    </>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="view-more-btn mt--60 text-center">
                            <Link className="rn-button-style--2 btn-solid" to="/packs"><span>Tout nos packs</span></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomePricing