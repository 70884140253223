/* eslint-disable import/prefer-default-export */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  username: '',
  token: 'dummy_token',
  roles: [],
  authenticated: false,
  programs: [],
  packs: [],

};

// User Slice
export const userState = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // Login
    login: (state, action) => {
      const { token, parsedToken, authenticated } = action.payload;
      const username = authenticated ? parsedToken?.preferred_username : '';
      const roles = authenticated ? parsedToken?.roles : [];
      localStorage.setItem('jwtToken', token || state.token);
      return {
        ...state,
        username,
        roles,
        token: token || 'dummy_token',
        authenticated,
      };
    },
    // Register
    register: (state, action) => {
      const { user, email, pass } = action.payload;
      return {
        ...state,
        status: true,
        user: {
          name: user,
        },
      };
    },
    // Logout
    logout: (state) => ({
      ...state,
      status: false,
      isLoggedIn: false,
      roles: [],
      token: '',
      user: {
      },
    }),

    setProfile: (state, action) => ({
      ...state,
      user: {
        id: action.payload.id,
        name: action.payload.fullname,
        email: action.payload.email,
        street: action.payload.street,
        city: action.payload.city,
        zip: action.payload.zip,
        phoneNumber: action.payload.phoneNumber,
      },
    }),
  },
});
