import instance from '../../utils/axios/api';

export const addProduct = (data) => () => instance.put('product/create/', data, {
    headers: {
        'Content-Type': 'multipart/form-data',
    }
});
export const updateProduct = (data) => () => instance.post('product/update/', data);

export const deleteProduct = (id) => () => instance.delete(`product/delete/${id}`);

export const getProductById = (id) => () => instance.get(`product/byid/${id}`);

export const getProductPacks = (id) => () => instance.get(`product/packs/${id}`);


export const fetchProducts = ({
    page = 0, rowsPerPage = 10, order = 'desc', orderBy = 'updatedAt', searchText = '',
}) => () => instance.post('product/fetch/', {
    page, rowsPerPage, order, orderBy, searchText,
});
export const fetchOnlyPublicProducts = ({
    page = 0, rowsPerPage = 10, order = 'desc', orderBy = 'updatedAt',
}) => () => instance.post('product/fetch/public', {
    page, rowsPerPage, order, orderBy,
});

export const updateProductPicture = (data) => () => instance.post('product/update/picture', data, {
    headers: {
        'Content-Type': 'multipart/form-data',
    }
});

