import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

const GenericRedirect = (WrappedComponent, targetPath) => {
    return () => {
        const { id } = useParams();
        const history = useHistory();

        useEffect(() => {
            if (id) {
                history.replace({
                    pathname: targetPath,
                    state: { id: id }
                });
            }
            else {
                history.replace('/');
            }
        }, [id, history]);

        return null; // Ce composant ne rend rien.
    };
};

export default GenericRedirect;