

import React from 'react';
import {
  FiChevronUp,
} from 'react-icons/fi';
import ScrollToTop from 'react-scroll-up';
import PageHelmet from 'component/common/Helmet';
import Header from 'component/header/Header';
import FooterTwo from 'component/footer/FooterTwo';
import Breadcrumb from 'elements/common/Breadcrumb';
import Layout from './layout';
import DashboardContainer from './dashboard.container';


const Dashboard = () => {

  return (
    <>
      < PageHelmet pageTitle="Tableau de bord" />
      < Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
      <Breadcrumb title="Tableau de bord" />
      <Layout>
        <DashboardContainer />
      </Layout>
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>

      <FooterTwo />

    </>)
}

export default Dashboard;
