export const ADMIN = 'admin';
export const CARE = 'CARE';
export const WORKSHOP = 'WORKSHOP';
export const COURSE = 'COURSE';
export const PRODUCT = 'PRODUCT';
export const OTHER = 'OTHER';

export const SERVICE = 'SERVICE';

export const API_URL = process.env.API_URL;
export const KC_REALME = process.env.KC_REALME;
export const KC_CLIENT_ID = process.env.KC_CLIENT_ID;
export const KC_URL = process.env.KC_URL;
export const WORKSHOP_COLOR = '#001c43';
export const CARE_COLOR = '#f4769a';
export const COURSE_COLOR = '#8956e2';
export const PRODUCT_COLOR = '#397721';
export const OTHER_COLOR = '#8956e2';

export const SERVICE_COLOR = '#2f21b3';

export const PENDING = 'PENDING';
export const VALIDATED = 'VALIDATED';
export const REJECTED = 'REJECTED';