import React from 'react';
import { FaCalendarAlt, FaReadme, FaRegGrinStars } from 'react-icons/fa';
import {
  FiAirplay,
  FiBarChart2,
  FiBox, FiCalendar, FiCommand, FiFigma, FiGift, FiHeart, FiPenTool, FiUsers,
} from 'react-icons/fi';
import { Link, useLocation } from 'react-router-dom';

const Sidebar = () => {
  const location = useLocation();
  return (
    <div className="col-sm-12 col-md-12 col-lg-3">
      <div className="dashboard_tab_button">
        <ul role="tablist" className="nav flex-column dashboard-list">
          <li>
            <Link to="/admin/dashboard" className={location.pathname === '/admin/dashboard' ? 'active' : null}>
              <FiBarChart2 />
              Tableau de bord
              {' '}
            </Link>
          </li>
          <li>
            <Link to="/admin/calendar" className={location.pathname === '/admin/calendar' ? 'active' : null}>
              <FiCalendar />
              {' '}
              Calendrier
            </Link>
          </li>
          <li>
            {' '}
            <Link to="/admin/users" className={location.pathname === '/admin/users' ? 'active' : null}>
              <FiUsers />
              {' '}
              Clients
            </Link>
          </li>
          <li>
            <Link to="/admin/programs" className={location.pathname === '/admin/programs' ? 'active' : null}>
              <FaCalendarAlt />
              {' '}
              EVENEMENTS
            </Link>
          </li>
          <li>
            <Link to="/admin/services" className={location.pathname === '/admin/services' ? 'active' : null}>
              <FaRegGrinStars />
              {' '}
              Ateliers et soins
            </Link>
          </li>
          <li>
            <Link to="/admin/packs" className={location.pathname === '/admin/packs' ? 'active' : null}>
              <FiBox />
              {' '}
              Packs et offres
            </Link>
          </li>
          <li>
            <Link to="/admin/products" className={location.pathname === '/admin/products' ? 'active' : null}>
              <FiGift />
              {' '}
              Produits
            </Link>
          </li>
          {/* <li>
            <Link to="/admin/tricks" className={location.pathname === '/admin/tricks' ? 'active' : null}>
              <FiHeart />
              {' '}
              Astuces
            </Link>
          </li> */}
          <li>
            <Link to="/admin/coachs" className={location.pathname === '/admin/coachs' ? 'active' : null}>
              <FiUsers />
              {' '}
              Coachs
            </Link>
          </li>
          <li>
            <Link to="/admin/gallery" className={location.pathname === '/admin/gallery' ? 'active' : null}>
              <FiFigma />
              {' '}
              Espace
            </Link>
          </li>
          <li>
            <Link to="/admin/requests" className={location.pathname === '/admin/requests' ? 'active' : null}>
              <FiPenTool />
              {' '}
              {'Demandes de réservation'}
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
