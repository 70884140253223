import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet'

const PageHelmet = (props) => {
    return (
        <Fragment>
            <Helmet>
                <title>{props.pageTitle}  </title>
                <meta name="description" content="Kayani, vivre sa vérité ……" />
            </Helmet>
        </Fragment>
    )

}


export default PageHelmet;
