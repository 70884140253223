import React, { Fragment, useEffect, useState } from 'react';


import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from 'react-icons/fi';
import FooterTwo from 'component/footer/FooterTwo';
import PageHelmet from 'component/common/Helmet';
import Header from 'component/header/Header';
import Breadcrumb from 'elements/common/Breadcrumb';
import { API_URL } from 'utils/constantes';
import { Pagination } from 'elements/common/Pagination';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchPublicOnlyPacks } from 'state/actions/pack';


const PackListComponent = () => {
    const dispatch = useDispatch();
    const [data, setData] = useState({ rows: [], count: 0 });
    const [pageable, setPageable] = useState({ page: 0, rowsPerPage: 6, order: 'desc', orderBy: 'updatedAt', fullVersion: false })
    const fetchData = (pageable) => (dispatch(fetchPublicOnlyPacks(pageable)));
    const onPageChange = (currentPage) => {
        setPageable({ ...pageable, page: currentPage - 1 })
    }
    useEffect(() => {
        const fetch = async () => {
            try {
                const { data: apiData, status } = await fetchData(pageable);
                if (status === 200) {
                    const { rows, count } = apiData;
                    setData((oldState) => ({ ...oldState, rows, count }));
                }
            } catch (e) {
                console.log(e);
            }
        };
        fetch();
    }, [pageable.page]);
    const imgLink = (value) => value.image ? `${API_URL}images/pack/${value.image}` : '/assets/images/default.jpg'

    return (
        <Fragment>
            <PageHelmet pageTitle='packs' />

            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
            <Breadcrumb title={'Packs'} />

            <div className="rn-blog-area ptb--190 bg_color--1">
                <div className="container">
                    <div className='row'>
                        {data.rows?.map((value, i) => (
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i} >
                                <div className="blog blog-style--75">
                                    <div className="thumbnail">
                                        <span >
                                            <img className="w-100" src={imgLink(value)} alt={value.title} />
                                        </span>
                                    </div>
                                    <div className="content">
                                        <p className="blogtype">{value.price} DT</p>
                                        <h4 className="title">
                                            <a>
                                                {value.title}
                                            </a>
                                        </h4>
                                        <div className="blog-btn">
                                            <Link className="rn-btn text-white" to={`/pack/${value.id}`} >Plus</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    {data.rows?.length && <div className="row mt--20">
                        <div className="col-lg-12">
                            <Pagination nbPages={Math.ceil(data.count / pageable.rowsPerPage)} currentPage={pageable.page + 1} onPageChange={onPageChange} />
                        </div>
                    </div>}
                </div>
            </div >
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            <FooterTwo />
        </Fragment >
    )

}

export default PackListComponent;