import { userState } from 'state/slices/userState';
import instance from '../../utils/axios/api';

export const authenticate = () => () => instance.post('client/authenticate');

export const getProfile = (id) => (dispatch) => instance.get(`user/details/${id}`).then(({ data }) => dispatch(setProfile(data)));

export const updateProfile = (details) => (dispatch) => instance.post('user/update', details).then(({ data }) => dispatch(setProfile(data)));


export const {
  login, setProfile, register, logout,
} = userState.actions;
