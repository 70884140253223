import instance from '../../utils/axios/api';

export const fetchClients = ({
    page = 0, rowsPerPage = 10, order = 'desc', orderBy = 'createdAt', searchText = '',
}) => () => instance.post('client/all', {
    page, rowsPerPage, order, orderBy, searchText,
});

export const deleteClient = (id) => () => instance.delete(`client/delete/${id}`);

export const enableClient = (clientId) => () => instance.post('client/enable', { clientId });

export const disableClient = (clientId) => () => instance.post('client/disable', { clientId });


export const addClient = (client) => () => instance.put('client/create/', client);

export const updateClient = (client) => () => instance.post('client/update/', client);

export const checkLogin = (username, clientId) => () => instance.post('client/login/isused', { username, clientId });

export const checkEmail = (email, clientId) => () => instance.post('client/email/isused', { email, clientId });

export const getClientById = (clientId) => () => instance.get(`client/${clientId}`);