import instance from '../../utils/axios/api';

export const addTrick = (data) => () => instance.put('gallery/create/', data, {
    headers: {
        'Content-Type': 'multipart/form-data',
    }
});
export const updateTrick = (data) => () => instance.post('gallery/update/', data, {
    headers: {
        'Content-Type': 'multipart/form-data',
    }
});

export const deleteTrick = (id) => () => instance.delete(`gallery/delete/${id}`);


export const fetchTricks = ({
    page = 0, rowsPerPage = 10, order = 'desc', orderBy = 'updatedAt', searchText = '',
}) => () => instance.post('gallery/fetch/', {
    page, rowsPerPage, order, orderBy, searchText,
});


export const updateTrickPicture = (data) => () => instance.post('gallery/update/picture', data, {
    headers: {
        'Content-Type': 'multipart/form-data',
    }
});

