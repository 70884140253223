import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { CARE_COLOR, WORKSHOP_COLOR } from 'utils/constantes';
import { MONTHS } from './config';

const LineChart = (props) => {

    const { stats } = props;
    const [data, setData] = useState({ labels: [], datasets: [] })
    useEffect(() => {
        const labels = stats.map(({ month, year }) => `${MONTHS[parseInt(month, 10) - 1]} ${year} `);
        const datasets = [{
            label: 'Nombre de participants',
            data: stats.map(({ participantCount }) => participantCount),
            fill: false,
            backgroundColor: '#8956e2',
            borderColor: '#8956e2',
        },
        {
            label: 'Capacitée total',
            data: stats.map(({ totalCapacity }) => totalCapacity),
            backgroundColor: '#397721',
            fill: false,
            borderColor: '#397721',
        },]
        setData({ ...data, labels, datasets })

    }, [stats])


    const options = {
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };

    return <Line data={data} options={options} />;
}

export default LineChart
