import { Avatar } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { authenticate, login } from 'state/actions/user';
import AdminLinks from './AdminLinks';
import { ADMIN } from 'utils/constantes';
import ClientLinks from './ClientLinks';

const KeycloakComponent = () => {
    const { keycloak } = useKeycloak();
    const [user, setUser] = useState({ username: '', isAdmin: false });
    const dispatch = useDispatch();
    useEffect(() => {
        setUser((state) => (
            {
                ...state, username: keycloak.tokenParsed?.preferred_username, isAdmin: keycloak?.hasResourceRole(ADMIN)

            }));
        dispatch(login({ parsedToken: keycloak.tokenParsed, token: keycloak.token, authenticated: keycloak.authenticated }));
        //        if (keycloak.authenticated) {
        //            dispatch(authenticate())
        //        }
        keycloak.onTokenExpired = () => {
            keycloak.updateToken(50).then(() => {
                dispatch(login({ parsedToken: keycloak.tokenParsed, token: keycloak.token, authenticated: keycloak.authenticated }));
            })
        }
    }, [keycloak.authenticated, keycloak.tokenParsed?.preferred_username]);


    return (
        <>
            {
                <li className={keycloak.authenticated ? 'after_login has-droupdown visible' : 'after_login has-droupdown invisible'}>
                    <Avatar sx={{ bgcolor: '#486b6f', marginRight: 1 }}>{user.username ? user.username.charAt(0) : ''}</Avatar>
                    <Link to="#">
                        {user.username}
                        {' '}
                    </Link>
                    {(user.isAdmin) &&
                        <AdminLinks onLogout={() => { setUser({ username: '', isAdmin: false }); keycloak.logout(); }} />
                    }
                    {!user.isAdmin &&
                        <ClientLinks onLogout={() => { setUser({ username: '', isAdmin: false }); keycloak.logout(); }} />
                    }
                </li>
            }
            {
                <li style={{ width: 70, textAlign: 'center', display: !keycloak.authenticated ? 'block' : 'none' }}>
                    <a style={{ marginTop: 10 }} onClick={() => keycloak.login()} href="#" className="rn-btn">
                        <span>Login</span>
                    </a>
                </li >
            }

        </>
    )
}

export default KeycloakComponent