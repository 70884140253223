import GridList from 'elements/gridList/grid.list';
import React from 'react'
import { deletePack, fetchPacks } from 'state/actions/pack';
import { API_URL, CARE, CARE_COLOR, PRODUCT, PRODUCT_COLOR, WORKSHOP, WORKSHOP_COLOR } from 'utils/constantes';
import PackEdit from './pack.edit';

const PackList = () => {
    const initialSelected = {
        id: null,
        title: '',
        fullDescription: '',
        summary: '',
        price: '',
        sale: '',
        limit: 31,
        publish: false,
        services: [],
        image: null
    };
    const generateLink = (item) => `/pack/${item.id}`

    return (
        <GridList
            label={'Service'}
            baseUrl={`${API_URL}images/pack`}
            initialSelectedItem={initialSelected}
            fetchAction={fetchPacks}
            deleteAction={deletePack}
            generateLink={generateLink}
        >
            <PackEdit />

        </GridList>
    )
}

export default PackList