import BarChart from 'elements/charts/bar.chart';
import LineChart from 'elements/charts/line.chart';
import React, { Fragment, useEffect, useState } from 'react'
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import { useDispatch } from 'react-redux';
import { fetchServices } from 'state/actions/service';
import { API_URL, WORKSHOP } from 'utils/constantes';
import { getServicesStatistics, getParticipationRate, getStatistics } from 'state/actions/program';
import { fetchProducts } from 'state/actions/products';

const DashboardContainer = (props) => {
    const { onVisibilityChange } = props;
    const dispatch = useDispatch();
    const [participationRate, setParticipationRate] = useState([]);

    const [serviceData, setServiceData] = useState([])
    const [productData, setProductData] = useState([])

    const [serviceStats, setServiceStats] = useState([])
    const [globalStats, setGlobalStats] = useState({})

    useEffect(() => {
        dispatch(fetchServices({ page: 0, rowsPerPage: 5, order: 'desc', orderBy: 'updatedAt', types: [WORKSHOP] })).then(({ data: apiData, status }) => {
            if (status === 200) {
                const { rows } = apiData;
                setServiceData(rows);
            }
        })

        dispatch(fetchProducts({ page: 0, rowsPerPage: 5, order: 'desc', orderBy: 'updatedAt' })).then(({ data: apiData, status }) => {
            if (status === 200) {
                const { rows } = apiData;
                setProductData(rows);
            }
        })
        dispatch(getServicesStatistics()).then(({ data, status }) => {
            if (status === 200) {
                setServiceStats(data);
            }
        })

        dispatch(getParticipationRate()).then(({ data, status }) => {
            if (status === 200) {
                setParticipationRate(data);
            }
        })

        dispatch(getStatistics()).then(({ data, status }) => {
            if (status === 200) {
                setGlobalStats(data[0] || {});
            }
        })

    }, [])

    return (

        <>
            <div className="rn-blog-area ptb--20 bg_color--1">
                <div className="container">
                    <div className="row">

                        <Fragment>
                            <div className="row pb--80">
                                <div className="counterup_style--1 col-lg-3 col-md-3 col-sm-6 col-12" key='care_count' >
                                    <h5 className="counter">
                                        <VisibilitySensor onChange={onVisibilityChange} offset={{ top: 10 }} delayedCall>
                                            <CountUp end={globalStats['care_count'] ? parseInt(globalStats['care_count'], 10) : 0} />
                                        </VisibilitySensor>
                                    </h5>
                                    <p className="description">{'Soins réalisés durant le mois courant'}</p>
                                </div>

                                <div className="counterup_style--1 col-lg-3 col-md-3 col-sm-6 col-12" key='care_assistants'>
                                    <h5 className="counter">
                                        <VisibilitySensor onChange={onVisibilityChange} offset={{ top: 10 }} delayedCall>
                                            <CountUp end={globalStats['care_assistants'] ? parseInt(globalStats['care_assistants'], 10) : 0} />
                                        </VisibilitySensor>
                                    </h5>
                                    <p className="description">{'Nombre de personnes ayants assistés a des soins durant le mois courant'}</p>
                                </div>

                                <div className="counterup_style--1 col-lg-3 col-md-3 col-sm-6 col-12" key='workshop_count'>
                                    <h5 className="counter">
                                        <VisibilitySensor onChange={onVisibilityChange} offset={{ top: 10 }} delayedCall>
                                            <CountUp end={globalStats['workshop_count'] ? parseInt(globalStats['workshop_count'], 10) : 0} />
                                        </VisibilitySensor>
                                    </h5>
                                    <p className="description">{'Ateliers réalisés durant le mois courant'}</p>
                                </div>

                                <div className="counterup_style--1 col-lg-3 col-md-3 col-sm-6 col-12" key='workshop_assistants'>
                                    <h5 className="counter">
                                        <VisibilitySensor onChange={onVisibilityChange} offset={{ top: 10 }} delayedCall>
                                            <CountUp end={globalStats['workshop_assistants'] ? parseInt(globalStats['workshop_assistants'], 10) : 0} />
                                        </VisibilitySensor>
                                    </h5>
                                    <p className="description">{'Nombre de personnes ayants assistés a des ateliers durant le mois courant'}</p>
                                </div>

                            </div>
                        </Fragment>
                    </div>
                    <div className="row ">
                        <div className="col-lg-6">
                            <div className="mychart_area">
                                <LineChart stats={participationRate} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="mychart_area">
                                <BarChart stats={serviceStats} />
                            </div>
                        </div>
                    </div>
                    <div className="row pt--40">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="vendor_order_boxed pt-4">
                                <h4>Ateliers</h4>
                                <table className="table pending_table">
                                    <thead style={{ backgroundColor: '#f2f2f2', color: '#434343' }}>
                                        <tr>
                                            <th scope="col">Image</th>
                                            <th scope="col">Titre</th>
                                            <th scope="col">Prix</th>
                                            <th scope="col">Nb places</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {serviceData.map((data, index) => (
                                            <tr key={index}>
                                                <td><img width="52px" src={data.image ? `${API_URL}images/service/${data.image}` : '/assets/images/default.jpg'} alt="img" /></td>
                                                <td>{data.title}</td>
                                                <td>{data.price ? `${data.price} DT` : '-'}</td>
                                                <td>{data.capacity ? `${data.capacity} Personnes` : '-'}</td>
                                            </tr>
                                        ))}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="vendor_order_boxed pt-4">
                                <h4>{'Produits'}</h4>
                                <table className="table pending_table">
                                    <thead style={{ backgroundColor: '#f2f2f2', color: '#434343' }}>
                                        <tr>
                                            <th scope="col">Image</th>
                                            <th scope="col">Titre</th>
                                            <th scope="col">Prix</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {productData.map((data, index) => (
                                            <tr key={index}>
                                                <td><img width="52px" src={data.image ? `${API_URL}images/product/${data.image}` : '/assets/images/default.jpg'} alt="img" /></td>
                                                <td>{data.title}</td>
                                                <td>{data.price ? `${data.price} DT` : '-'}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DashboardContainer
