import instance from '../../utils/axios/api';

export const addCoach = (data) => () => instance.put('coach/create/', data, {
    headers: {
        'Content-Type': 'multipart/form-data',
    }
});
export const updateCoach = (data) => () => instance.post('coach/update/', data);

export const deleteCoach = (id) => () => instance.delete(`coach/delete/${id}`);


export const fetchCoachs = ({
    page = 0, rowsPerPage = 10, order = 'desc', orderBy = 'updatedAt', searchText = '',
}) => () => instance.post('coach/fetch/', {
    page, rowsPerPage, order, orderBy, searchText,
});

export const getAllCoachs = () => () => instance.get('coach/all/');

export const updateCoachPicture = (data) => () => instance.post('coach/update/picture', data, {
    headers: {
        'Content-Type': 'multipart/form-data',
    }
});
