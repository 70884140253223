import React from 'react'
import { Link } from 'react-router-dom';

const ClientLinks = ({ onLogout }) => {
    return (
        <ul className="submenu">
            <li><Link to="/client/calendar">Mon Calendrier</Link></li>
            <li><Link to="/client/requests">Mes réservations</Link></li>
            <li><Link to='#' onClick={onLogout}>Logout</Link></li>
        </ul>
    )
}

export default ClientLinks