import GridList from 'elements/gridList/grid.list'
import React from 'react'
import { deleteProduct, fetchProducts } from 'state/actions/products'
import { API_URL } from 'utils/constantes'
import ProductEdit from './product.edit'

const ProductList = () => {
    const initialSelectedProduct = {
        id: null,
        price: '',
        image: null,
        title: '',
        fullDescription: '',
        category: '',
        publish: false
    };
    const generateLink = (item) => `/product/${item.id}`

    return (
        <GridList
            label={'Produit'}
            baseUrl={`${API_URL}images/product`}
            initialSelectedItem={initialSelectedProduct}
            fetchAction={fetchProducts}
            deleteAction={deleteProduct}
            generateLink={generateLink}
        >
            <ProductEdit />

        </GridList>
    )
}

export default ProductList