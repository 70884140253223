/* eslint-disable import/prefer-default-export */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  clients: [],
  packs: [],
  activities: [],
  programs: [],

};

// User Slice
export const adminState = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setClients: (state, action) => {
      const clients = action.payload;
      return {
        ...state,
        clients,
      };
    },
    removeClient: (state, action) => {
      const id = action.payload;
      const clients = state.clients.filter((client) => client.id !== id);
      return {
        ...state,
        clients,
      };
    },
  },
});
