import React, { Fragment, useEffect, useState } from 'react'
import { momentLocalizer, Views } from 'react-big-calendar'
import moment from 'moment'
import 'moment/locale/fr'  // without this line it didn't work

import { Backdrop, Checkbox, CircularProgress, FormControl, Stack, Typography } from '@mui/material';
import { FiPlusCircle, FiStar } from 'react-icons/fi';
import PageHelmet from 'component/common/Helmet';
import { DnDCalendar, formats, getEventClassName, ALL_TYPES, messages, TYPE_COLORS, TYPE_LABELS, INIT_TYPES, INIT_EVENT } from './data';
import { useDispatch } from 'react-redux';
import { deleteEvent, fetchEvents } from 'state/actions/program';
import Swal from 'sweetalert2';
import EventEdit from './edit.event';
import { ExpandMore } from 'elements/expandMore';
import { yellow } from '@mui/material/colors';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { useDebounce } from 'usehooks-ts';
import { fetchClients } from 'state/actions/client';
import { fetchCoachs } from 'state/actions/coach';



moment.locale('fr');
const localizer = momentLocalizer(moment)


const ProgramCalendar = () => {
    const dispatch = useDispatch();
    const INIT_RANGE = {
        start: moment().startOf('month').subtract(1, 'week').toDate(),
        end: moment().endOf('month').add(1, 'week').toDate()
    }


    const [events, setEvents] = useState([]);
    const [coachSearchText, setCoachSearchText] = useState('');
    const clientDebouncedSearchText = useDebounce(coachSearchText, 500);
    const [eventTypes, setEventTypes] = useState(INIT_TYPES)
    const [selectedCoach, setSelectedCoach] = useState([])
    const [range, setRange] = useState(INIT_RANGE);

    useEffect(() => {
        dispatch(fetchCoachs({ searchText: coachSearchText }))
            .then(({ data }) => {
                setCoachOptions(data?.rows);
                setIsLoading(false);
            });

    }, [clientDebouncedSearchText])

    const handleRangeChange = (ev) => {
        if (ev?.start && ev?.end) {
            setRange(ev);
        }
        else if (ev.length) {
            const starDate = ev[0];
            const endDate = ev[ev.length - 1]
            if (range.start.getTime() > starDate.getTime() || range.end.getTime() < endDate.getTime()) {
                console.log('new range by week or day')
                setRange({ ...range, start: subtractOneDay(starDate), end: addOneDay(endDate) })
            }

        }
    };

    const subtractOneDay = (date) => {
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() - 1);
        return newDate;
    }

    // Fonction pour ajouter un jour
    const addOneDay = (date) => {
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() + 1);
        return newDate;
    }

    const [actions, setActions] = useState({
        delete: 0, add: 0,
    });

    const [selectedEvent, setSelectedEvent] = useState(INIT_EVENT)
    const [newProgramDisplay, setNewProgramDisplay] = useState(false);
    const [loading, setloading] = useState(false)

    const [isLoading, setIsLoading] = useState(false);
    const [coachOptions, setCoachOptions] = useState([]);

    const handleEventSelect = (event) => {
        setSelectedEvent(event);
        setNewProgramDisplay(true);
    }

    const handleNewPrg = () => {
        setSelectedEvent({ ...INIT_EVENT });
        setNewProgramDisplay(true);
    }


    const convertToEvent = (data) => data.map(item => ({
        ...item,
        start: moment(`${item.date}T${item.startTime}`).toDate(),
        end: moment(`${item.date}T${item.endTime}`).toDate(),
    }));

    useEffect(() => {
        setloading(true);
        dispatch(fetchEvents(range, eventTypes, null, selectedCoach[0]?.id)).then(({ status, data }) => {
            setloading(false);
            if (status === 200) {
                const evts = convertToEvent(data);
                setEvents(evts);
            }
        }).catch(() => setloading(false))
    }, [actions, range, eventTypes, selectedCoach])


    const handleSlotSelect = (selected) => {
        if (selected.slots.length > 2) {
            // Input time string in 12-hour format
            const startTime12hr = moment(selected.start).format('hh:mm A');
            const endTime12hr = moment(selected.end).format('hh:mm A');

            // Convert 12-hour time string to 24-hour time string
            const startTime24hr = moment(startTime12hr, 'h:mm A').format('HH:mm');
            const endTime24hr = moment(endTime12hr, 'h:mm A').format('HH:mm');
            const startDay = moment(selected.start).format('YYYY-MM-DD');
            const endDay = moment(selected.end).format('YYYY-MM-DD');

            if (startDay === endDay) {
                setSelectedEvent({
                    ...INIT_EVENT,
                    date: startDay,
                    startTime: startTime24hr,
                    endTime: endTime24hr
                })
                setNewProgramDisplay(true);
            }
        }
    }

    const onEventDrop = (selected) => {
        const startDay = moment(selected.start).format('YYYY-MM-DD');
        // Input time string in 12-hour format
        const startTime12hr = moment(selected.start).format('hh:mm A');
        const endTime12hr = moment(selected.end).format('hh:mm A');

        // Convert 12-hour time string to 24-hour time string
        const startTime24hr = moment(startTime12hr, 'h:mm A').format('HH:mm');
        const endTime24hr = moment(endTime12hr, 'h:mm A').format('HH:mm');

        setSelectedEvent(() => {
            const newEvent = {
                ...selected.event,
                date: startDay,
                startTime: startTime24hr,
                endTime: endTime24hr
            };
            delete newEvent.id;
            return newEvent;
        })
        setNewProgramDisplay(true);
        console.log(selected);
    };

    const onEventResize = (data) => {
        const { start, end } = data;
        setEvents((state) => ({ ...state, start, end }));
    };

    const handleProgramAddSuccess = (title) => {
        Swal.fire({
            icon: 'success',
            title: 'Success',
            text: `Program ${title} insérée`,
        }).then(() => setActions((state) => ({ ...state, add: state.add + 1 })));
    }

    const handleProgramAddFail = (title) => Swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: `Echec de sauvegarde de l'évenement' ${title} `,
    });

    const handleProgramDelete = async (program) => {
        const { value } = await Swal.fire({
            title: `Suppression de ${program.title}`,
            icon: 'warning',
            text: 'Vous ne pourrez pas revenir en arrière !',
            showCancelButton: true,
            confirmButtonText: 'Supprimer',
            cancelButtonText: 'Annuler',

        });
        if (value) {
            dispatch(deleteEvent(program.id)).then(() => {
                setActions((state) => ({ ...state, delete: state.delete + 1 }));
                Swal.fire({
                    icon: 'success',
                    title: 'Suppression',
                    text: `Evenement ${program.title} supprimé`,
                });
            });
        }
    };



    const eventComponent = (ev) => {
        return (
            <div id={ev.event?.id} style={{ maxHeight: 40 }}>
                <div id={`title ${ev.event?.id} `} style={{ fontSize: 12 }}>{ev.title}</div>
                <div id={`dec ${ev.event?.id} `} style={{ width: '100%', textAlign: 'right' }}>
                    {ev.event?.program?.id &&
                        (<>
                            <i id={`i ${ev.event?.id} `} style={{ fontSize: 8 }}>{ev.event?.program?.title}</i>
                            <FiStar id={`icon ${ev.event?.id} `} style={{ color: yellow[500] }} title={ev.event?.program?.title} />
                        </>)
                    }
                </div>
            </div>
        );
    }

    const handleEventTypes = (event, type) => {
        const { checked } = event.target;
        setEventTypes((state => !checked ? [...state.filter(t => t !== type)] : [...state, type]))
    }

    const renderTypeCheckbox = (type, index) =>
    (<span id={'span checkbox' + index}>
        <Checkbox
            checked={eventTypes.includes(type)}
            id={'checkbox' + index}
            onChange={(e) => handleEventTypes(e, type)}
            sx={{
                color: TYPE_COLORS[index],
                '&.Mui-checked': {
                    color: TYPE_COLORS[index],
                },
            }}
        ></Checkbox>{TYPE_LABELS[index]}</span>);

    const renderClientFilter = () => (<AsyncTypeahead
        filterBy={() => true}
        id="client_filter"
        isLoading={isLoading}
        labelKey="lastname"
        minLength={3}
        onSearch={setCoachSearchText}
        onChange={setSelectedCoach}
        defaultSelected={selectedCoach}
        options={coachOptions}
        placeholder="Filtrer par coach..."
        renderMenuItemChildren={(option) => (
            <>
                <span>{option?.firstname} {option?.lastname} </span>
            </>
        )}
    />)

    return (
        <Fragment>
            <PageHelmet pageTitle='Calendrier' />
            <div className="rn-blog-area ptb--20 bg_color--1">
                <Stack >
                    <Backdrop open={loading} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}><CircularProgress color="secondary" /></Backdrop>
                </Stack>
                <div className="container">
                    <Fragment>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                            <Stack direction="row" alignItems="left">
                                <Typography gutterBottom>
                                </Typography>
                                <Stack direction="row" spacing={1}>
                                    <FormControl style={{ marginTop: -9, paddingLeft: 20 }}>
                                        <div>
                                            {ALL_TYPES?.map((type, index) => renderTypeCheckbox(type, index))}
                                        </div>
                                    </FormControl>
                                </Stack>
                            </Stack>
                            <Stack direction="row" alignItems="center" spacing={2}>
                                {renderClientFilter()}
                                <span>
                                    <ExpandMore
                                        expand={newProgramDisplay}
                                        onClick={handleNewPrg}
                                        aria-expanded={newProgramDisplay}
                                    >
                                        <FiPlusCircle />
                                    </ExpandMore>
                                    <b>Ajouter</b>
                                </span>
                            </Stack>
                        </Stack>
                        <EventEdit
                            open={newProgramDisplay}
                            setOpen={setNewProgramDisplay}
                            selectedEvent={selectedEvent}
                            setSelectedEvent={setSelectedEvent}
                            handleProgramAddSuccess={handleProgramAddSuccess}
                            handleProgramAddFail={handleProgramAddFail}
                            handleProgramDelete={handleProgramDelete}
                        />
                    </Fragment>
                    <DnDCalendar
                        className={'rbc-timeslot-group bigCalendar'}
                        localizer={localizer}
                        events={events}
                        onRangeChange={handleRangeChange}
                        defaultDate={moment().toDate()}
                        formats={formats}
                        defaultView={Views.MONTH}
                        messages={messages}
                        onEventDrop={onEventDrop}
                        onEventResize={onEventResize}
                        resizable={false}
                        selectable
                        onSelectEvent={handleEventSelect}
                        onSelectSlot={selected => handleSlotSelect(selected)}
                        eventPropGetter={(event) => ({ className: getEventClassName(event) })}
                        style={{ minHeight: 500 }}
                        components={{
                            event: eventComponent
                        }}
                    />
                </div>
            </div>
        </Fragment>
    )
}

export default ProgramCalendar