import { CardContent, Collapse } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone';
import StyledDropZone from 'component/common/styled.dropzone';
import { API_URL } from 'utils/constantes';
import { useDispatch } from 'react-redux';
import { addCoach, updateCoach, updateCoachPicture } from 'state/actions/coach';
import { ExpandMore } from 'elements/expandMore';
import { FaWindowClose } from 'react-icons/fa';



const CoachEdit = (props) => {
    const { open, setOpen, selectedItem, setSelectedItem, successCallback, failedCallback, refreshCallback } = props;

    const handleSuccess = (title) => {
        setImage(null);
        setPath(null);
        setOpen(false);
        successCallback(title);
    };
    const handleFailed = (title) => {
        setImage(null);
        setPath(null);
        setOpen(false);
        failedCallback(title);
    };

    const [path, setPath] = useState(null);
    const [image, setImage] = useState(null);

    const onDrop = useCallback((acceptedFiles) => {
        setPath(acceptedFiles.map(file => URL.createObjectURL(file)));
        setImage(acceptedFiles ? acceptedFiles[0] : null)
        if (selectedItem.id) {
            const formData = new FormData();

            formData.append('id', selectedItem.id);
            formData.append('file', acceptedFiles[0]);
            dispatch(updateCoachPicture(formData)).then(refreshCallback)
        }
    }, [setPath, selectedItem.id]);


    useEffect(() => {
        if (selectedItem?.image) {
            const initpath = selectedItem ? `${API_URL}images/coach/${selectedItem.image}` : null;
            setPath(initpath);
        } else {
            setPath(null);
        }
    }, [selectedItem?.image])




    const dispatch = useDispatch();
    const handleItemChange = (field) => (event) => {
        setSelectedItem({ ...selectedItem, [field]: event.target.value });
    };

    const save = (e) => {
        const formData = new FormData();
        if (selectedItem?.id) {
            formData.append('id', selectedItem.id);
        }
        formData.append('firstname', selectedItem.firstname);
        formData.append('lastname', selectedItem.lastname);
        formData.append('phone', selectedItem.phone);

        formData.append('title', selectedItem.title);
        if (!selectedItem?.id) {
            formData.append('file', image);
        }
        e.preventDefault();

        const action = selectedItem.id ? dispatch(updateCoach(formData)) : dispatch(addCoach(formData));
        action.then(() => handleSuccess(selectedItem.title))
            .catch(() => handleFailed(selectedItem.title));
        e.target.reset();
    };



    useDropzone({ onDrop });
    return (
        <Collapse in={open} timeout="auto" unmountOnExit style={{ backgroundColor: '#f2f2f2', color: '#434343' }}>
            <div style={{ width: '100%', display: 'flex', alignContent: 'flex-end' }}>
                <ExpandMore
                    expand={open}
                    onClick={() => setOpen(false)}
                    aria-expanded={open}
                >
                    <FaWindowClose />
                </ExpandMore>
            </div>
            <CardContent>
                <div className="contact-form--1">
                    <div className="container">
                        <form action=""
                            onSubmit={save}
                        >
                            <div className="row row--35 align-items-start">
                                <div className="col-lg-6 order-1 order-lg-1">

                                    <div className="form-wrapper">
                                        <div className="rn-form-group">

                                            <div className="form-group">
                                                <label className="col-form-label" htmlFor="firstname"><b>Prénom: </b></label>
                                                <input
                                                    type="text"
                                                    id='firstname'
                                                    name="firstname"
                                                    value={selectedItem.firstname}
                                                    onChange={handleItemChange('firstname')}
                                                    placeholder="Prénom"
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label className="col-form-label" htmlFor="firstname"><b>Nom: </b></label>
                                                <input
                                                    type="text"
                                                    id='lastname'
                                                    name="lastname"
                                                    value={selectedItem.lastname}
                                                    onChange={handleItemChange('lastname')}
                                                    placeholder="Nom"
                                                    required
                                                />
                                            </div>

                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-6 order-1 order-lg-1">
                                    <div className="form-group">
                                        <label className="col-form-label" htmlFor="title"><b>Titre: </b></label>
                                        <input
                                            type="text"
                                            id='title'
                                            name="title"
                                            value={selectedItem.title}
                                            onChange={handleItemChange('title')}
                                            placeholder="Titre"
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className="col-form-label" htmlFor="phone"><b>Numéro: </b></label>
                                        <input
                                            type="text"
                                            name="phone"
                                            value={selectedItem.phone}
                                            onChange={handleItemChange('phone')}
                                            placeholder="Numéro"
                                            pattern='^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{2,6}$'
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="thumbnail mb_md--30 mb_sm--30" >
                                <StyledDropZone onDrop={onDrop} ></StyledDropZone>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>

                                    {path && (<img style={{ maxWidth: 200, height: 'auto' }} key={path} src={path} />)}
                                    {/* <img src="/assets/images/about/about-3.jpg" alt="kayani" /> */}
                                </div>
                            </div>
                            <div className="section-title text-center mt--20">
                                <div className="rn-form-group">
                                    <button
                                        className="rn-button-style--2 btn-solid"
                                        type="submit"
                                        value="submit"
                                        name="submit"
                                        id="mc-embedded-subscribe">Valider
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </CardContent>
        </Collapse>

    );
}

export default CoachEdit