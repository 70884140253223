import React from 'react'

const Note = () => {
    return (
        <blockquote className="rn-blog-quote">Booking sur le 58 733 233<br />
            Soyez les bienvenus<br />
            Kayani, vivre sa vérité …….
        </blockquote>
    )
}

export default Note