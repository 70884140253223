import Keycloak from 'keycloak-js';
import { KC_CLIENT_ID, KC_REALME, KC_URL } from './constantes';

const keycloakConfig = new Keycloak({
  realm: KC_REALME,
  url: KC_URL,
  clientId: KC_CLIENT_ID,
});

export default keycloakConfig;
