import GridList from 'elements/gridList/grid.list'
import React from 'react'
import { deleteTrick, fetchTricks } from 'state/actions/trick'
import { API_URL } from 'utils/constantes'
import GalleryEdit from './gallery.edit'

const GalleryList = () => {
    const initialSelected = {
        id: null,
        image: null,
        title: ''
    };
    return (
        <GridList
            label={'Gallerie'}
            baseUrl={`${API_URL}images/gallery`}
            initialSelectedItem={initialSelected}
            fetchAction={fetchTricks}
            deleteAction={deleteTrick}
        >
            <GalleryEdit />

        </GridList>
    )
}

export default GalleryList