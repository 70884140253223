import React, { Fragment, useEffect, useState } from 'react'
import { momentLocalizer, Views } from 'react-big-calendar'
import moment from 'moment'
import 'moment/locale/fr'  // without this line it didn't work

import { Checkbox, FormControl, Stack, Typography } from '@mui/material';
import { FiStar } from 'react-icons/fi';
import PageHelmet from 'component/common/Helmet';
import { useDispatch } from 'react-redux';
import { fetchEvents } from 'state/actions/program';
import { CARE, CARE_COLOR, OTHER, OTHER_COLOR, WORKSHOP, WORKSHOP_COLOR } from 'utils/constantes';
import { yellow } from '@mui/material/colors';

import { DnDCalendar, formats, messages } from 'backoffice/event/data';
import UserEvent from '../../elements/user.event';



moment.locale('fr');
const localizer = momentLocalizer(moment)
const initTypes = [WORKSHOP, CARE, OTHER];
const typeColors = [WORKSHOP_COLOR, CARE_COLOR, OTHER_COLOR];
const typesLabels = ['Ateliers', 'Soins', 'Autres'];
const UserCalendar = (props) => {
    const { userId } = props;
    const dispatch = useDispatch();
    const [events, setEvents] = useState([]);

    const [eventTypes, setEventTypes] = useState([WORKSHOP, CARE, OTHER])
    const initialRange = {
        start: moment().startOf('month').subtract(1, 'week').toDate(),
        end: moment().endOf('month').add(1, 'week').toDate()
    }
    const [range, setRange] = useState(initialRange);
    const handleRangeChange = (ev) => {
        if (ev?.start && ev?.end) {
            setRange(ev);
            console.log('Dates de début et de fin:', ev.start, ev.end);
        }
    };

    const initSelectedEvent = {
        id: null,
        title: '',
        date: '',
        start: '',
        end: '',
        startTime: '',
        endTime: '',
        service: null,
        program: null,
        participants: []
    }
    const [selectedEvent, setSelectedEvent] = useState(initSelectedEvent)
    const [newProgramDisplay, setNewProgramDisplay] = useState(false);


    const handleEventSelect = (event) => {
        setSelectedEvent(event);
        setNewProgramDisplay(true);
    }


    const convertToEvent = (data) => data.map(item => ({
        ...item,
        start: moment(`${item.date}T${item.startTime}`).toDate(),
        end: moment(`${item.date}T${item.endTime}`).toDate(),
    }));

    useEffect(() => {
        dispatch(fetchEvents(range, eventTypes, userId, null)).then(({ status, data }) => {
            if (status === 200) {
                const evts = convertToEvent(data);
                setEvents(evts);
            }
        })
    }, [range, eventTypes, userId])



    const getEventClassName = (event) => {
        // Retourne la classe CSS correspondante en fonction de la couleur de l'événement
        switch (event.service?.type) {
            case WORKSHOP:
                return 'workshop-event';
            case CARE:
                return 'care-event';
            default:
                return 'other-event';
        }
    }


    const eventComponent = (ev) => {
        return (
            <div style={{ maxHeight: 40 }}>
                <div style={{ fontSize: 12 }}>{ev.title}</div>
                <div style={{ width: '100%', textAlign: 'right' }}>
                    {ev.event?.program?.id && (<><i style={{ fontSize: 8 }}>{ev.event?.program?.title}</i><FiStar style={{ color: yellow[500] }} title={ev.event?.program?.title} /></>)}
                </div>
            </div>
        );
    }

    const handleEventTypes = (event, type) => {
        const { checked } = event.target;
        setEventTypes((state => !checked ? [...state.filter(t => t !== type)] : [...state, type]))
    }

    const renderTypeCheckbox = (type, index) =>
    (<span>
        <Checkbox
            checked={eventTypes.includes(type)}
            onChange={(e) => handleEventTypes(e, type)}
            sx={{
                color: typeColors[index],
                '&.Mui-checked': {
                    color: typeColors[index],
                },
            }}
        ></Checkbox>{typesLabels[index]}</span>);



    return (
        <Fragment>
            <PageHelmet pageTitle='Calendrier' />
            <div className="rn-blog-area ptb--20 bg_color--1">

                <div className="container">
                    <Fragment>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                            <Stack direction="row" alignItems="left">
                                <Typography gutterBottom>
                                </Typography>
                                <Stack direction="row" spacing={1}>
                                    <FormControl style={{ marginTop: -9, paddingLeft: 20 }}>
                                        <div>
                                            {initTypes?.map((type, index) => renderTypeCheckbox(type, index))}
                                        </div>
                                    </FormControl>
                                </Stack>
                            </Stack>
                            <Stack direction="row" alignItems="center" spacing={2}>
                            </Stack>
                        </Stack>
                        <UserEvent
                            open={newProgramDisplay}
                            setOpen={setNewProgramDisplay}
                            selectedEvent={selectedEvent}
                            setSelectedEvent={setSelectedEvent}
                        />
                    </Fragment>
                    <DnDCalendar
                        className={'rbc-timeslot-group bigCalendar'}
                        localizer={localizer}
                        events={events}
                        onRangeChange={handleRangeChange}
                        defaultDate={moment().toDate()}
                        formats={formats}
                        defaultView={Views.MONTH}
                        messages={messages}
                        resizable={false}
                        selectable
                        onSelectEvent={handleEventSelect}
                        eventPropGetter={(event) => ({ className: getEventClassName(event) })}
                        style={{ minHeight: 500 }}
                        components={{
                            event: eventComponent
                        }}
                    />
                </div>
            </div>
        </Fragment>
    )
}

export default UserCalendar