import React, { Fragment, useEffect, useState } from 'react';

import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from 'react-icons/fi';
import PageHelmet from 'component/common/Helmet';
import Header from 'component/header/Header';
import { useDispatch } from 'react-redux';
import { getService, getServicePacks } from 'state/actions/service';
import { API_URL, WORKSHOP } from 'utils/constantes';
import FooterTwo from 'component/footer/FooterTwo';
import HeaderDetails from '../header/header.details';
import Note from '../common/note';
import { formatDuration } from 'utils/dateutils';


const CareDetails = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    const { id } = location.state || '';

    const [packs, setPacks] = useState([])

    const [care, setCare] = useState({
        capacity: '',
        createdAt: '',
        duration: '',
        fullDescription: '',
        image: '',
        price: '',
        title: '',
        updatedAt: '',
        type: ''
    })
    useEffect(() => {
        if (!id) {
            history.replace('/');
            return;
        }
        dispatch(getService(id)).then(({ data, status }) => {
            if (status === 200) {
                const {
                    capacity,
                    createdAt,
                    duration,
                    fullDescription,
                    image,
                    price,
                    title,
                    updatedAt,
                    type } = data
                setCare({
                    ...care,
                    capacity,
                    createdAt,
                    duration,
                    fullDescription,
                    image,
                    price,
                    title,
                    updatedAt,
                    type
                })
            }

        })

        dispatch(getServicePacks(id)).then(({ data, status }) => {
            if (status === 200) {
                setPacks(data)
            }
        })

    }, [id])

    return (
        <Fragment>
            <PageHelmet pageTitle='Soin' />
            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

            {/* Start Breadcrump Area */}
            <HeaderDetails
                title={`${care.title}`}
                items={[
                    { label: care.price ? `${care.price} dt/personne` : '', id: 1, icon: 'BiMoney' },
                    { label: care.duration ? formatDuration(care.duration) : '', id: 2, icon: 'BiTime' },
                    { label: care.capacity ? `${care.capacity} participants max` : '', id: 3, icon: 'FiUsers' }

                ]}
            />
            {/* End Breadcrump Area */}

            {/* Start Blog Details */}
            <div className="rn-blog-details ptb--110 pb--70 bg_color--1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inner-wrapper">
                                <div className="inner">
                                    <p dangerouslySetInnerHTML={{ __html: care.fullDescription }}></p>
                                    {packs.length > 0 && <>
                                        <h4 className="title">Packs liées:</h4>
                                        <ul className="list-style">

                                            {packs.map(pack => (<li key={pack.id}>
                                                <Link className='client-link' to={`/pack/${pack.id}`}>{`${pack.title} `}</Link>{` à ${pack.price - pack.sale} dt`}
                                            </li>))}
                                        </ul>
                                    </>}
                                    <Note />
                                    <div className="video-wrapper position-relative mb--40">
                                        <div className="thumbnail" style={{ textAlign: 'center' }} >
                                            {care.title && <img className="w-50" src={care.image ? `${API_URL}images/service/${care.image}` : '/assets/images/default.jpg'} alt={care.title} />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Blog Details */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            <FooterTwo />

        </Fragment>
    )
}
export default CareDetails;