import React from 'react';
import Slider from 'react-slick';
import { portfolioSlick2 } from 'page-demo/script'
import { API_URL } from 'utils/constantes';
import { Link } from 'react-router-dom';


const HomeActivities = (props) => {
    const { items: workshops } = props;
    return (
        <div className="portfolio-area ptb--120 bg_color--1">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title text-center service-style--3 mb--30">
                            <h2 className="title">NOS ATELIERS</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wrapper portfolio-sacousel-inner mb--55">
                <div className="portfolio-slick-activation mt--70 mt_sm--40">
                    <Slider {...portfolioSlick2}>
                        {workshops.map((value, index) => (
                            <div className="portfolio" key={index}>
                                <div className="thumbnail-inner">
                                    <div className={'thumbnail'}>
                                        <img className='bgImage' src={`${API_URL}images/service/${value.image}`} />
                                    </div>
                                    <div className={'bg-blr-image'}></div>
                                </div>

                                <div className="content">
                                    <div className="inner">
                                        <p>{value.category}</p>
                                        <h4><Link to={`/care/${value.id}`}>{value.title}</Link></h4>
                                        <div className="portfolio-button">
                                            <Link className="rn-btn" to={`/workshop/${value.id}`}>Plus</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    )
}

export default HomeActivities