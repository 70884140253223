import React from 'react'
import { Link } from 'react-router-dom';

const AdminLinks = ({ onLogout }) => {
    return (
        <ul className="submenu">
            <li><Link to="/admin/dashboard">Tableau de bord</Link></li>
            <li><Link to="/admin/calendar">Calendrier</Link></li>
            <li><Link to="/admin/users">Clients</Link></li>
            <li><Link to='#' onClick={onLogout}>Logout</Link></li>
        </ul>
    )
}

export default AdminLinks