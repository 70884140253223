import {
  Backdrop,
  CardContent, CircularProgress, Collapse, Stack,
} from '@mui/material';
import { ExpandMore } from 'elements/expandMore';

import React, { useEffect, useState } from 'react';
import { FaWindowClose } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { checkLogin, checkEmail, updateClient, addClient } from 'state/actions/client';
import { useDebounce } from 'usehooks-ts';


const UserEdit = (props) => {
  const { open, setOpen, successCallback, failedCallback, user, setUser } = props;
  const loginDebouncedValue = useDebounce(user.login, 500)
  const emailDebouncedValue = useDebounce(user.email, 500)
  const [loading, setloading] = useState(false)

  const [isLoginUsed, setIsLoginUsed] = useState(false);
  const [isEmailUsed, setIsEmailUsed] = useState(false);
  const dispatch = useDispatch();

  const handleSuccess = (login) => {
    setOpen(false);
    successCallback(login);
  };
  const handleFailed = (login) => {
    setOpen(false);
    failedCallback(login);
  };

  useEffect(() => {
    if (loginDebouncedValue.length >= 4)
      dispatch(checkLogin(user.login, user.id)).then(res => setIsLoginUsed(res.data === true))
    else
      setIsLoginUsed(false)
  }, [loginDebouncedValue])


  useEffect(() => {
    if (loginDebouncedValue.length >= 4)
      dispatch(checkEmail(user.email, user.id)).then(res => setIsEmailUsed(res.data === true))
    else
      setIsEmailUsed(false)
  }, [emailDebouncedValue])


  const handleUserChange = (field) => (event) => {
    setUser({ ...user, [field]: event.target.value });
  };
  const handleUserLogin = () => {
    if (!user.login && user.firstname && user.lastname) {
      const login = user.firstname.charAt(0) + user.lastname;
      setUser({ ...user, login });
    }
  };

  const saveUser = (e) => {
    e.preventDefault();
    const action = user.id ? dispatch(updateClient(user)) : dispatch(addClient(user));
    setloading(true);
    action.then(() => handleSuccess(user.login))
      .catch(() => handleFailed(user.login)).finally(() => setloading(false)
      );
    e.target.reset();
  };


  return (
    <Collapse in={open} timeout="auto" unmountOnExit style={{ backgroundColor: '#f2f2f2', color: '#434343', marginBottom: 20 }}>
      <Stack >
        <Backdrop open={loading} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}><CircularProgress color="secondary" /></Backdrop>
      </Stack>
      <div style={{ width: '100%', display: 'flex', alignContent: 'flex-end' }}>
        <ExpandMore
          expand={open}
          onClick={() => setOpen(false)}
          aria-expanded={open}
        >
          <FaWindowClose />
        </ExpandMore>
      </div>
      <CardContent>
        <div className="contact-form--1">
          <div className="container">
            <form action=""
              onSubmit={saveUser}
            >

              <div className="row row--35 align-items-start">
                <div className="col-lg-6 order-2 order-lg-1">
                  <div className="form-wrapper">
                    <input
                      type="text"
                      name="firstname"
                      minLength={2}
                      value={user.firstname}
                      onChange={handleUserChange('firstname')}
                      onBlur={handleUserLogin}
                      placeholder="Prénom"
                      required
                    />
                    <input
                      type="text"
                      name="lasname"
                      minLength={2}
                      value={user.lastname}
                      onChange={handleUserChange('lastname')}
                      onBlur={handleUserLogin}
                      placeholder="Nom"
                      required
                    />
                    <input
                      type="text"
                      name="login"
                      value={user.login}
                      minLength={4}
                      onChange={handleUserChange('login')}
                      placeholder="Identifiant"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-6 order-1 order-lg-2">
                  <div className="thumbnail mb_md--30 mb_sm--30">
                    <div className="rn-form-group">
                      <input
                        type="email"
                        name="email"
                        value={user.email}
                        onChange={handleUserChange('email')}
                        placeholder="Adresse email"
                        required
                      />
                    </div>

                    <div className="rn-form-group">
                      <input
                        type="text"
                        name="phone"
                        value={user.phone}
                        onChange={handleUserChange('phone')}
                        placeholder="Numéro"
                        pattern='^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{2,6}$'
                        required
                      />
                    </div>
                    {isLoginUsed && (<p className='text-danger'>Login Déja utilisé</p>)}
                    {isEmailUsed && (<p className='text-danger'>Email Déja utilisé</p>)}
                  </div>
                </div>
              </div>
              <div className="section-title text-center mt--20">
                <div className="rn-form-group">
                  <button
                    className="rn-button-style--2 btn-solid"
                    type="submit"
                    value="submit"
                    name="submit"
                    disabled={isLoginUsed || isEmailUsed}
                    id="mc-embedded-subscribe">Valider
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

      </CardContent>
    </Collapse>


  );
};

export default UserEdit;
