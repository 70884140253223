import instance from '../../utils/axios/api';

export const addEvent = (program) => () => instance.put('event/create/', program);
export const updateEvent = (program) => () => instance.post('event/update/', program);
export const getAllEvents = () => () => instance.get('event/all');
export const fetchEvents = (range, eventTypes, clientId, coachId) => () => instance.post('event/fetch/byrange', { ...range, eventTypes, clientId, coachId })

export const fetchClientEvents = (range, eventTypes) => () => instance.post('event/client/fetch/byrange', { ...range, eventTypes })



export const fetchPublicEvents = (range, eventTypes, clientId) => () => instance.post('event/fetch/byrange/public', { ...range, eventTypes, clientId })


export const deleteEvent = (id) => () => instance.delete(`event/delete/${id}`);


export const getAllPrograms = () => () => instance.get('program/all');
export const addProgram = (program) => () => instance.put('program/create/', program, {
    headers: {
        'Content-Type': 'multipart/form-data',
    }
});

export const getServicesStatistics = () => () => instance.get('event/services/statics');

export const getStatistics = () => () => instance.get('event/statics');

export const getProgramById = (id) => () => instance.get(`program/byid/${id}`);
export const getEventById = (id) => () => instance.get(`event/byid/${id}`);

export const getNextEventsByProgram = (id) => () => instance.get(`program/nexts/${id}`);

export const getParticipationRate = () => () => instance.get('event/participationRate');

export const updateProgram = (program) => () => instance.post('program/update/', program);
export const deleteProgram = (id) => () => instance.delete(`program/delete/${id}`);
export const fetchPrograms = ({
    page = 0, rowsPerPage = 10, order = 'desc', orderBy = 'updatedAt', searchText = '',
}) => () => instance.post('program/fetch', {
    page, rowsPerPage, order, orderBy, searchText,
})

export const fetchOnlyPublicPrograms = ({
    page = 0, rowsPerPage = 10, order = 'desc', orderBy = 'updatedAt', fullVersion = true
}) => () => instance.post('program/fetch/public', {
    page, rowsPerPage, order, orderBy, fullVersion
})
export const updateProgramPicture = (data) => () => instance.post('program/update/picture', data, {
    headers: {
        'Content-Type': 'multipart/form-data',
    }
});

export const addRequest = (eventId) => () => instance.put(`request/event/create/${eventId}`)

export const fetchRequests = ({
    page = 0, rowsPerPage = 10, order = 'desc', orderBy = 'updatedAt', searchText = '', onlyPending
}) => () => instance.post('request/event/fetch', {
    page, rowsPerPage, order, orderBy, searchText, onlyPending
})

export const fetchClientRequests = ({
    page = 0, rowsPerPage = 10, order = 'desc', orderBy = 'updatedAt', searchText = '', onlyPending
}) => () => instance.post('request/event/fetch/byclient', {
    page, rowsPerPage, order, orderBy, searchText, onlyPending
})

export const deleteClientRequest = (id) => () => instance.delete(`request/event/byClient/delete/${id}`);


export const updateRequest = (data) => () => instance.post('request/event/update', data);