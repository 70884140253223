import { Pagination } from 'elements/common/Pagination';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux/es/hooks/useDispatch';
import { Link } from 'react-router-dom';

const GridEight = (props) => {
    const { generateLink, imgLink, fetchAction } = props
    const dispatch = useDispatch();
    const [data, setData] = useState({ rows: [], count: 0 });
    const [pageable, setPageable] = useState({ page: 0, rowsPerPage: 8, order: 'desc', orderBy: 'updatedAt' })
    const fetchData = (pageable) => (dispatch(fetchAction(pageable)));
    const onPageChange = (currentPage) => {
        setPageable({ ...pageable, page: currentPage - 1 })
    }
    useEffect(() => {
        const fetch = async () => {
            try {
                const { data: apiData, status } = await fetchData(pageable);
                if (status === 200) {
                    const { rows, count } = apiData;
                    setData((oldState) => ({ ...oldState, rows, count }));
                }
            } catch (e) {
                console.log(e);
            }
        };
        fetch();
    }, [pageable.page]);

    return (
        <div className="rn-blog-area ptb--200 bg_color--1">
            <div className="container">
                <div className='row'>
                    {data.rows?.map((value, i) => (
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12" key={i} >
                            <div className="blog blog-style--75">
                                <div className="thumbnail">
                                    <span >
                                        <img className="w-100" src={imgLink(value)} alt={value.title} />
                                    </span>
                                </div>
                                <div className="content">
                                    <p className="blogtype">{value.price} DT</p>
                                    <h4 className="title">
                                        <a>
                                            {value.title}
                                        </a>
                                    </h4>
                                    <div className="blog-btn">
                                        <Link className="rn-btn text-white" to={generateLink(value)} >Plus</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {data.rows?.length > 0 && <div className="row mt--20">
                    <div className="col-lg-12">
                        {/* Start Pagination Area */}
                        <Pagination nbPages={Math.ceil(data.count / pageable.rowsPerPage)} currentPage={pageable.page + 1} onPageChange={onPageChange} />
                        {/* End Pagination Area */}
                    </div>
                </div>}
            </div>
        </div >
    )
}

export default GridEight