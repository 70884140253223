import { createSlice } from '@reduxjs/toolkit';
import { CARE, WORKSHOP } from 'utils/constantes';

// Product Slice
export const ServiceState = createSlice({
  name: 'service',
  initialState: {
    filter: {
      types: [WORKSHOP, CARE],
      searchText: ''
    },

  },
  reducers: {
    addType: (state, action) => {
      const { types, searchText } = { ...state.filter };
      const newTypes = [...types, action.payload];
      return ({
        filter: {
          searchText,
          types: newTypes
        }
      });
    },
    delType: (state, action) => {
      const { types, searchText } = { ...state.filter };
      const newTypes = types.filter(type => type !== action.payload);
      return ({
        filter: {
          searchText,
          types: newTypes
        }
      });
    },
    setSearchText: (state, action) => {
      const { types } = { ...state.filter };
      return ({
        filter: {
          searchText: action.payload,
          types
        }
      });
    }
  },
});
