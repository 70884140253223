import { slickDot } from 'page-demo/script';
import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { API_URL } from 'utils/constantes';


const HomeCares = (props) => {
    const { items: cares } = props;
    return (
        < div className="portfolio-area pt--90 pb--140 bg_color--1" >
            <div className="rn-slick-dot">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title service-style--3 text-center mb--25 mb_sm--0">
                                <h2 className="title">NOS SOINS</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="slick-space-gutter--15 slickdot--20">
                                <Slider {...slickDot}>
                                    {cares.map((value, index) => (
                                        <div className="portfolio" key={index}>
                                            <div className="thumbnail-inner">
                                                <div className={'thumbnail'}>
                                                    <img className='bgImage' src={`${API_URL}images/service/${value.image}`} />
                                                </div>
                                                <div className={'bg-blr-image'}></div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                    <p>{value.category}</p>
                                                    <h4><Link to={`/care/${value.id}`}>{value.title}</Link></h4>
                                                    <div className="portfolio-button">
                                                        <Link className="rn-btn" to={`/care/${value.id}`}>Plus</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div >

    )
}

export default HomeCares