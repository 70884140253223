import GridList from 'elements/gridList/grid.list'
import React from 'react'
import { deleteService, fetchServices } from 'state/actions/service'
import { API_URL, CARE, CARE_COLOR, WORKSHOP, WORKSHOP_COLOR } from 'utils/constantes'
import ServiceEdit from './service.edit'

const ServiceList = () => {
    const initialSelected = {
        id: null,
        title: '',
        capacity: '',
        fullDescription: '',
        summary: '',
        price: '',
        duration: '',
        type: CARE,
        image: null,
        publish: false
    };
    const initTypes = [WORKSHOP, CARE];
    const typeColors = [WORKSHOP_COLOR, CARE_COLOR];
    const typesLabels = ['Ateliers', 'Soins'];
    const generateLink = (item) => item?.type === WORKSHOP ? `/workshop/${item.id}` : `/care/${item.id}`

    return (
        <GridList
            label={'Service'}
            baseUrl={`${API_URL}images/service`}
            initialSelectedItem={initialSelected}
            fetchAction={fetchServices}
            deleteAction={deleteService}
            initTypes={initTypes}
            typeColors={typeColors}
            typesLabels={typesLabels}
            generateLink={generateLink}
        >
            <ServiceEdit />

        </GridList>
    )
}

export default ServiceList