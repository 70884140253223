import { Backdrop, CardContent, CircularProgress, Collapse, FormControl, FormControlLabel, Radio, RadioGroup, Stack, Switch } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone';
import StyledDropZone from 'component/common/styled.dropzone';
import { API_URL, CARE, WORKSHOP } from 'utils/constantes';
import { useDispatch } from 'react-redux';
import { updateService, addService, updateServicePicture } from 'state/actions/service';
import { ExpandMore } from 'elements/expandMore';
import { FaWindowClose } from 'react-icons/fa';
import ReactQuill from 'react-quill';

const ServiceEdit = (props) => {
  const { open, setOpen, selectedItem, setSelectedItem, successCallback, failedCallback, refreshCallback } = props;
  const dispatch = useDispatch();
  const [path, setPath] = useState(null);
  const [image, setImage] = useState(null);
  const [loading, setloading] = useState(false)

  useEffect(() => {
    if (selectedItem?.image) {
      const initpath = selectedItem ? `${API_URL}images/service/${selectedItem.image}` : null;
      setPath(initpath)
    } else {
      setPath(null);
    }
  }, [selectedItem?.image])

  const handleSuccess = (title) => {
    setImage(null);
    setPath(null);
    setOpen(false);
    successCallback(title);
  };
  const handleFailed = (title) => {
    setImage(null);
    setPath(null);
    setOpen(false);
    failedCallback(title);
  };


  const onDrop = useCallback((acceptedFiles) => {
    setPath(acceptedFiles.map(file => URL.createObjectURL(file)));
    setImage(acceptedFiles ? acceptedFiles[0] : null)
    if (selectedItem.id) {
      const formData = new FormData();
      formData.append('id', selectedItem.id);
      formData.append('file', acceptedFiles[0]);
      dispatch(updateServicePicture(formData)).then(refreshCallback)
    }
  }, [setPath, selectedItem?.id]);
  useDropzone({ onDrop });

  const handleServiceChange = (field) => (event) => {
    setSelectedItem({ ...selectedItem, [field]: event.target.value });
  };

  const saveUser = (e) => {
    const formData = new FormData();
    if (selectedItem?.id) {
      formData.append('id', selectedItem.id);
    }
    if (!selectedItem?.id) {
      formData.append('file', image);
    }
    formData.append('price', selectedItem.price || '');
    formData.append('capacity', selectedItem.capacity || '');
    formData.append('duration', selectedItem.duration || '');
    formData.append('title', selectedItem.title);
    formData.append('publish', selectedItem.publish);

    formData.append('fullDescription', selectedItem.fullDescription);
    formData.append('summary', selectedItem.summary);

    formData.append('type', selectedItem.type);
    e.preventDefault();
    const action = selectedItem.id ? dispatch(updateService(formData)) : dispatch(addService(formData));
    setloading(true);
    action.then(() => handleSuccess(selectedItem.title))
      .catch(() => handleFailed(selectedItem.title))
      .finally(() => setloading(false));
    e.target.reset();
  };

  return (
    <Collapse in={open} timeout="auto" unmountOnExit style={{ backgroundColor: '#f2f2f2', color: '#434343' }}>
      <Stack >
        <Backdrop open={loading} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}><CircularProgress color="secondary" /></Backdrop>
      </Stack>
      <div style={{ width: '100%', display: 'flex', alignContent: 'flex-end' }}>
        <ExpandMore
          expand={open}
          onClick={() => setOpen(false)}
          aria-expanded={open}
        >
          <FaWindowClose />
        </ExpandMore>
      </div>
      <CardContent>
        <div className="contact-form--1">
          <div className="container">
            <form action=""
              onSubmit={saveUser}
            >
              <div className="row row--35 align-items-start">
                <div className="col-lg-7 order-1 order-lg-1">

                  <div className="form-wrapper">
                    <div className="rn-form-group">
                      <div className="form-group">
                        <FormControl>
                          <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            row
                            name="controlled-radio-buttons-group"
                            value={selectedItem.type}
                            onChange={handleServiceChange('type')}
                          >
                            <FormControlLabel value={CARE} control={<Radio />} label="Soin" />
                            <FormControlLabel value={WORKSHOP} control={<Radio />} label="Atelier" style={{ marginRight: 100 }} />

                            <FormControlLabel
                              control={
                                <Switch checked={selectedItem.publish} onChange={(event) => {
                                  setSelectedItem({ ...selectedItem, publish: event.target.checked });
                                }} name="publish" />
                              }
                              label="Public"
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>

                      <div className="form-group">
                        <label className="col-form-label" htmlFor="title"><b>Titre: </b></label>
                        <input
                          type="text"
                          id='title'
                          name="title"
                          value={selectedItem.title}
                          onChange={handleServiceChange('title')}
                          placeholder="Titre"
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label className="col-form-label" htmlFor="summary"><b>Résumé: </b></label>
                        <textarea
                          name="summary"
                          id='summary'
                          style={{ height: 70 }}
                          value={selectedItem.summary}
                          onChange={handleServiceChange('summary')}
                          placeholder="Résumé"
                        />
                      </div>
                      <div className="form-group">
                        <label className="col-form-label" htmlFor="fullDescription"><b>Description: </b></label>
                        <ReactQuill
                          value={selectedItem.fullDescription}
                          onChange={(value) => setSelectedItem({ ...selectedItem, fullDescription: value })}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 order-2 order-lg-2">
                  <div className="form-group">
                    <label className="col-form-label" htmlFor="price"><b>Prix: </b></label>
                    <input
                      type="number"
                      name="price"
                      id='price'
                      value={selectedItem.price}
                      onChange={handleServiceChange('price')}
                      placeholder="Prix"
                    />
                  </div>
                  <div className="form-group">
                    <label className="col-form-label" htmlFor="capacity"><b>Nombre de places: </b></label>
                    <input
                      type="number"
                      name="capacity"
                      value={selectedItem.capacity}
                      onChange={handleServiceChange('capacity')}
                      placeholder="capacitée"
                    />
                  </div>
                  <div className="form-group">
                    <label className="col-form-label" htmlFor="duration"><b>Durée (en minutes): </b></label>
                    <input
                      type="number"
                      id="duration"
                      name="duration"
                      value={selectedItem.duration}
                      onChange={handleServiceChange('duration')}
                      placeholder="Durée"
                    />
                  </div>

                  <div className="thumbnail mb_md--30 mb_sm--30" >
                    <StyledDropZone onDrop={onDrop} ></StyledDropZone>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>

                      {path && (<img style={{ maxWidth: 200, height: 'auto' }} key={path} src={path} />)}
                      {/* <img src="/assets/images/about/about-3.jpg" alt="kayani" /> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="section-title text-center mt--20">
                <div className="rn-form-group">
                  <button
                    className="rn-button-style--2 btn-solid"
                    type="submit"
                    value="submit"
                    name="submit"
                    id="mc-embedded-subscribe">Valider
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

      </CardContent>
    </Collapse>

  );
}

export default ServiceEdit