import GridList from 'elements/gridList/grid.list'
import React from 'react'
import { deleteCoach, fetchCoachs } from 'state/actions/coach'
import { API_URL } from 'utils/constantes'
import CoachEdit from './coach.edit'

const CoachList = () => {
    const initialSelectedCoach = {
        id: null,
        firstname: '',
        lastname: '',
        image: null,
        title: '',
        phone: ''
    }

    return (
        <GridList
            label={'Coach'}
            baseUrl={`${API_URL}images/coach`}
            initialSelectedItem={initialSelectedCoach}
            fetchAction={fetchCoachs}
            deleteAction={deleteCoach}
        >
            <CoachEdit />

        </GridList>
    )
}

export default CoachList