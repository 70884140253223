import React, { Fragment, useEffect, useState } from 'react'
import { momentLocalizer, Views } from 'react-big-calendar'
import moment from 'moment'
import 'moment/locale/fr'  // without this line it didn't work

import { FiStar } from 'react-icons/fi';
import PageHelmet from 'component/common/Helmet';
import { useDispatch } from 'react-redux';
import { addRequest, fetchPublicEvents } from 'state/actions/program';
import Swal from 'sweetalert2';
import { yellow } from '@mui/material/colors';
import { INIT_TYPES, DnDCalendar, formats, messages, getEventClassName } from 'backoffice/event/data';
import { formatDate, formatTime } from 'utils/dateutils';
import { useKeycloak } from '@react-keycloak/web';
import { ADMIN } from 'utils/constantes';
import { Link } from 'react-router-dom';

import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Backdrop from '@mui/material/Backdrop';


moment.locale('fr');
const localizer = momentLocalizer(moment)

const Calendar = () => {
    const dispatch = useDispatch();
    const { keycloak } = useKeycloak();
    const [events, setEvents] = useState([]);

    const INIT_RANGE = {
        start: moment().startOf('month').subtract(1, 'week').toDate(),
        end: moment().endOf('month').add(1, 'week').toDate()
    }
    const [loading, setloading] = useState(false)


    const [range, setRange] = useState(INIT_RANGE);
    const subtractOneDay = (date) => {
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() - 1);
        return newDate;
    }

    // Fonction pour ajouter un jour
    const addOneDay = (date) => {
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() + 1);
        return newDate;
    }
    const handleRangeChange = (ev) => {
        console.log(ev)
        if (ev?.start && ev?.end) {
            setRange(ev);
        } else if (ev.length) {
            const starDate = ev[0];
            const endDate = ev[ev.length - 1]
            if (range.start.getTime() > starDate.getTime() || range.end.getTime() < endDate.getTime()) {
                console.log('new range by week or day')
                setRange({ ...range, start: subtractOneDay(starDate), end: addOneDay(endDate) })
            }

        }
    };

    const handleEventSelect = async (event) => {
        if (event.start < new Date()) {
            Swal.fire({
                title: 'Activité passée',
                icon: 'error',
            })
            return;
        };
        if (!keycloak.authenticated) {
            Swal.fire({
                title: 'Authentification réquise',
                icon: 'info',
                text: 'Veuillez vous authentifier.',

            })
            return;
        }
        if (keycloak?.hasResourceRole(ADMIN)) {
            return;
        }
        const { value } = await Swal.fire({
            title: `Demande d'inscription à ${event.title}`,
            icon: 'question',
            text: `le  ${formatDate(event.date)} de ${formatTime(event.startTime)} à ${formatTime(event.endTime)}`,
            showCancelButton: true,
            confirmButtonText: 'Valider',
            cancelButtonText: 'Annuler',

        });
        if (value) {
            dispatch(addRequest(event.id)).then(({ data, status }) => {
                if (status === 200) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Demande d\'inscription',
                        text: `Demande à ${event.title} enregistrée`,
                    });
                }
            }).catch(({ response }) => {
                if (response.status === 409)
                    Swal.fire({
                        icon: 'error',
                        title: 'Demande d\'inscription',
                        text: `Une demande est déja inscrite à ${event.title}`,
                    });
            })
        }
    }

    const convertToEvent = (data) => data.map(item => ({
        ...item,
        start: moment(`${item.date}T${item.startTime}`).toDate(),
        end: moment(`${item.date}T${item.endTime}`).toDate(),
    }));

    useEffect(() => {
        setloading(true);
        dispatch(fetchPublicEvents(range, INIT_TYPES)).then(({ status, data }) => {
            setloading(false);

            if (status === 200) {
                const evts = convertToEvent(data);
                setEvents(evts);
            }
        }).catch(() => setloading(false)
        )
    }, [range])

    const eventComponent = (ev) => {
        return (
            <div id={ev.event?.id} style={{ maxHeight: 40 }}>
                <div id={`title ${ev.event?.id} `} style={{ fontSize: 12 }}>{ev.title}</div>
                <div id={`dec ${ev.event?.id} `} style={{ width: '100%', textAlign: 'right' }}>
                    {ev.event?.program?.id &&
                        (<>
                            <i id={`i ${ev.event?.id} `} style={{ fontSize: 8 }}>{ev.event?.program?.title}</i>
                            <FiStar id={`icon ${ev.event?.id} `} style={{ color: yellow[500] }} title={ev.event?.program?.title} />
                        </>)
                    }
                </div>
            </div>
        );
    }

    return (
        <Fragment>
            <PageHelmet pageTitle='Calendrier' />
            {/* End Breadcrump Area */}

            {/* Start Blog Details */}
            <div className="rn-blog-details ">
                <div className="container">
                    <div className="row pb--70 ">
                        <div className="col-lg-12">
                            <div className="inner-wrapper">
                                <div className="inner">
                                    <p>{`Pour toute demande d\'inscription, Veuillez vous connecter puis cliquer sur l\'activitée correspondante. 
                                    Si vous n'êtes pas inscrit, veuillez `} <Link to="/contact">nous contacter. </Link></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Stack >
                        <Backdrop open={loading} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}><CircularProgress color="secondary" /></Backdrop>
                    </Stack>
                    <DnDCalendar
                        className={'rbc-timeslot-group bigCalendar'}
                        localizer={localizer}
                        events={events}
                        onRangeChange={handleRangeChange}
                        defaultDate={moment().toDate()}
                        formats={formats}
                        defaultView={Views.MONTH}
                        views={[Views.MONTH, Views.DAY, Views.WEEK, Views.AGENDA]}
                        messages={messages}
                        resizable={false}
                        selectable
                        onSelectEvent={handleEventSelect}
                        eventPropGetter={(event) => ({ className: getEventClassName(event) })}
                        style={{ minHeight: 500 }}
                        components={{
                            event: eventComponent
                        }}
                    />

                </div>

            </div>
        </Fragment>
    )
}

export default Calendar