import React from 'react'
import { FiHeart, FiUsers } from 'react-icons/fi'
import { BiMoney, BiHealth, BiTime, BiInfoCircle } from 'react-icons/bi';

const HeaderDetails = (props) => {
    const { title, items } = props
    const IconComponent = ({ iconName }) => {
        let Icon;
        switch (iconName) {
            case 'BiMoney':
                Icon = BiMoney;
                break;
            case 'BiTime':
                Icon = BiTime;
                break;
            case 'FiUsers':
                Icon = FiUsers;
                break;
            case 'FiHeart':
                Icon = FiHeart;
                break;
            case 'BiInfoCircle':
                Icon = BiInfoCircle;
                break;
            default:
                Icon = BiHealth;
                break;
        }

        return <Icon />;
    }

    return (
        <div className="rn-page-title-area pt--120 pb--170 bg_image bg_image--7" data-black-overlay="7">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="blog-single-page-title text-center pt--100">
                            {title &&
                                <h2 className="title theme-gradient" style={{ textTransform: 'uppercase' }}>
                                    {title}
                                </h2>
                            }
                            <ul className="blog-meta d-flex justify-content-center align-items-center">
                                {
                                    items.map(item => (item.label && < li key={item.id} >
                                        <IconComponent iconName={item.icon} />
                                        {item.label}
                                    </li>))
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div >)
}

export default HeaderDetails