import React, { Fragment } from 'react';


import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from 'react-icons/fi';
import FooterTwo from 'component/footer/FooterTwo';
import PageHelmet from 'component/common/Helmet';
import Header from 'component/header/Header';
import Breadcrumb from 'elements/common/Breadcrumb';
import { fetchPublicOnlyServices } from 'state/actions/service';
import { API_URL, CARE, WORKSHOP } from 'utils/constantes';
import ListViewItems from 'elements/viewList/view.list';


const CareList = () => {
    const generateLink = (item) => item?.type === WORKSHOP ? `/workshop/${item.id}` : `/care/${item.id}`
    const imgLink = (value) => value.image ? `${API_URL}images/service/${value.image}` : '/assets/images/default.jpg'
    return (
        <Fragment>
            <PageHelmet pageTitle='Soins' />

            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
            {/* Start Breadcrump Area */}
            <Breadcrumb title={'Soins'} />
            {/* End Breadcrump Area */}
            <ListViewItems
                generateLink={generateLink}
                imgLink={imgLink}
                types={[CARE]}
                fetchAction={fetchPublicOnlyServices}
            />

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            <FooterTwo />

        </Fragment >
    )

}

export default CareList;