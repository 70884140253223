import moment from 'moment';
const dateFormat = 'YYYY-MM-DDTHH:mm:ss.SSSZ';

export const formatDuration = (brut) => {
    const duration = moment.duration(brut, 'minutes');
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    let strMin = minutes + '';
    if (minutes < 10) {
        strMin = '0' + minutes;

    }


    const time = `${hours}h${strMin} `;
    return time;
}

export const formatDate = (date) => {
    const tmp = moment(date); // Analyse la chaîne de caractères en tant qu'objet Moment
    return tmp.format('dddd D MMMM YYYY'); // Récupère le jour de la semaine, le mois et l'année en chaine
}

export const formatTime = (time) => {
    return moment(time, 'HH:mm:ss').format('HH[h]mm');
}


export const formatDateTime = (dateString) => {
    const momentObj = moment(dateString, dateFormat);

    // Convertir la date en format "jj/mm/aaaa hh:mm" sans les secondes
    const formattedDate = momentObj.format('DD/MM/YYYY HH:mm');
    return formattedDate;
}