import React, { Fragment, useEffect, useState } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from 'react-icons/fi';
import PageHelmet from 'component/common/Helmet';
import Header from 'component/header/Header';
import { useDispatch } from 'react-redux';
import { API_URL, WORKSHOP } from 'utils/constantes';
import FooterTwo from 'component/footer/FooterTwo';
import { getPack } from 'state/actions/pack';
import HeaderDetails from '../header/header.details';
import Note from '../common/note';


const PackDetails = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const { id } = location.state || '';
    const [pack, setPacks] = useState({
        createdAt: '',
        services: [],
        fullDescription: '',
        image: '',
        price: '',
        title: '',
        updatedAt: '',
        sale: '',
        products: []
    })
    useEffect(() => {
        if (!id) {
            history.replace('/');
            return;
        }
        dispatch(getPack(id)).then(({ data, status }) => {
            if (status === 200) {
                const {
                    createdAt,
                    services,
                    fullDescription,
                    image,
                    price,
                    title,
                    sale,
                    updatedAt,

                } = data
                setPacks({
                    ...pack,
                    createdAt,
                    fullDescription,
                    image,
                    price,
                    title,
                    updatedAt,
                    sale,
                    services,
                })
            }

        })
    }, [id])

    return (
        <Fragment>
            <PageHelmet pageTitle='Pack' />
            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
            <HeaderDetails
                title={`${pack.title}`}
                items={[
                    { label: pack.price ? `${pack.price - pack.sale} dt` : '', id: 1, icon: 'BiMoney' },
                    { label: pack.duration ? 'Validitée : 1 mois' : '', id: 2, icon: 'FiClock' },

                ]}
            />
            {/* Start Blog Details */}
            <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inner-wrapper">
                                <div className="inner">
                                    <p ><p dangerouslySetInnerHTML={{ __html: pack.fullDescription }}></p>
                                    </p>
                                    {pack.services && (<>
                                        <h4 className="title">Inclus:</h4>
                                        <ul className="list-style">

                                            {pack?.services.map(service => (
                                                <li key={service.service.id}>
                                                    {`${service.occurrence} séance(s) de `}
                                                    {service.service.publish &&
                                                        <Link
                                                            className='client-link'
                                                            to={`${service.service.type === WORKSHOP ? '/workshop/' : '/care/'}${service.service.id}`}>
                                                            {`${service.service.title} `}
                                                        </Link>
                                                    }
                                                    {!service.service.publish &&
                                                        `${service.service.title} `
                                                    }
                                                </li>
                                            ))}
                                        </ul>

                                    </>)}

                                    {pack.products.length > 0 && (<>
                                        <h4 className="title">Produits:</h4>
                                        <ul className="list-style">

                                            {pack?.products.map(product => (
                                                <li key={product.product.id}>
                                                    {`${product.occurrence} `} <Link className='client-link' to={`/pack/${product.product.id}`}>{`${product.product.title} `}</Link>
                                                </li>
                                            ))}
                                        </ul>

                                    </>)}
                                    {
                                        pack.price && pack.sale &&
                                        <p className="mt--25 mt_sm--5" style={{ textAlign: 'center', fontSize: 22 }}>
                                            <strong>{` Le tout à seulement ${(pack.price - pack.sale)}dt au lieu de`} <span style={{ textDecoration: 'line-through' }}>{pack.price}dt</span></strong>
                                        </p>
                                    }
                                    <Note />
                                    <div className="video-wrapper position-relative mb--40">
                                        <div className="thumbnail" style={{ textAlign: 'center' }} >
                                            {pack.title && <img className="w-50" src={pack.image ? `${API_URL}images/pack/${pack.image}` : '/assets/images/default.jpg'} alt={pack.title} />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            <FooterTwo />

        </Fragment>
    )
}
export default PackDetails;