import {
  createTheme, styled, useTheme, ThemeProvider,
} from '@mui/material/styles';
import {
  TableCell,
  tableCellClasses,
  TableRow,
  TableContainer,
  Paper,
  TableHead,
  TableBody,
  Box,
  TablePagination,
  TableSortLabel,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';

import * as locales from '@mui/material/locale';
import { visuallyHidden } from '@mui/utils';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#f2f2f2',
    fontWeight: 600,
    color: '#434343',
    lineHeight: 2,

    padding: '.75rem',
    fontFamily: '"Poppins",sans-serif'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    lineHeight: 2,
    fontFamily: '"Poppins",sans-serif'

  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,

  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
const CustomTablePagination = (props) => {
  const {
    fields, fetchData, actions, searchText, data, setData, controller, setController
  } = props;
  const theme = useTheme();

  const themeWithLocale = React.useMemo(
    () => createTheme(theme, locales.frFR),
    ['frFR', theme],
  );


  useEffect(() => {
    if (searchText !== controller.searchText) {
      setController((state) => ({ ...state, searchText }));
    }
  }, [searchText]);

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data: apiData, status } = await fetchData(controller);
        if (status === 200) {
          const { rows, count } = apiData;
          setData((oldState) => ({ ...oldState, rows, total: count }));
        } else {
          setData({ rows: [], total: 0 })
        }

      } catch (e) {
        setData({ rows: [], total: 0 })
        console.log(e);
      }
    };
    fetch();
  }, [controller, actions]);

  const handlePageChange = (event, newPage) => {
    setController({
      ...controller,
      page: newPage,
    });
  };
  const handleChangeRowsPerPage = (event) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };
  const createSortHandler = (property) => (event) => {
    const isAsc = controller.orderBy === property && controller.order === 'asc';
    setController((state) => ({ ...state, order: isAsc ? 'desc' : 'asc', orderBy: property }));
  };
  return (
    <TableContainer sx={{ minWidth: 700 }} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {fields.map((field) => (
              <StyledTableCell
                key={field.id}
                sortDirection={controller.orderBy === field.id ? controller.order : false}
              >
                <TableSortLabel
                  active={controller.orderBy === field.id}
                  direction={controller.orderBy === field.id ? controller.order : 'asc'}
                  onClick={createSortHandler(field.id)}
                >
                  {field.label}
                  {controller.orderBy === field.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {controller.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.rows?.map((row) => (
            <StyledTableRow key={row.id}>
              {fields
                .map(({ id, render }) => (<StyledTableCell key={id}><p></p>{render ? render(row) : row[id]}</StyledTableCell>))}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      <ThemeProvider theme={themeWithLocale}>

        <TablePagination
          sx={{
            '.MuiTablePagination-toolbar': {
              '.MuiTablePagination-select': {
                marginTop: '-12px',

              },
            },
            '.MuiSvgIcon-root': {
              marginTop: '-7px',

            },
          }}
          component="div"
          onPageChange={handlePageChange}
          page={controller.page}
          count={data.total}
          color="primary"
          rowsPerPage={controller.rowsPerPage}
          rowsPerPageOptions={[5, 10, 20]}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </ThemeProvider>
    </TableContainer>
  );
};

export default CustomTablePagination;
