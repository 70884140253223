// @ts-nocheck
import { Badge, CardContent, Chip, Collapse, Grid, Switch, Backdrop, Stack, CircularProgress } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone';
import StyledDropZone from 'component/common/styled.dropzone';
import { API_URL } from 'utils/constantes';
import { useDispatch } from 'react-redux';
import { addPack, getPack, updatePack, updatePackPicture } from 'state/actions/pack';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { useDebounce } from 'usehooks-ts';
import { fetchServices } from 'state/actions/service';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

import { ExpandMore } from 'elements/expandMore';
import { FaWindowClose } from 'react-icons/fa';
import ReactQuill from 'react-quill';

const PackEdit = (props) => {
    const { open, setOpen, selectedItem, setSelectedItem, successCallback, failedCallback, refreshCallback } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const [searchText, setSearchText] = useState('');

    const [services, setServices] = useState([]);

    const [selected, setSelected] = useState([]);
    const debouncedSearchText = useDebounce(searchText, 500);
    const [loading, setloading] = useState(false)


    useEffect(() => {
        const call = dispatch(fetchServices({ searchText: searchText }));
        call.then(({ data }) => {
            setOptions(data?.rows);
            setIsLoading(false);
        });

    }, [debouncedSearchText])

    useEffect(() => {
        if (selectedItem.id) {
            dispatch(getPack(selectedItem.id)).then(({ status, data }) => {
                if (status === 200) {
                    setSelectedItem(data)
                }

            })
        }

    }, [selectedItem.id])

    const handleClose = () => {
        setOpen(false);
        setServices([]);
        setSelectedItem({ ...selectedItem, id: null })
    }

    const handleSelectService = (e) => {
        setServices([...services, { service: e[0], occurrence: 1 }])
        setSelected([])
        setIsLoading(false);
    }


    const handleSuccess = (title) => {
        setImage(null);
        setPath(null);
        handleClose();
        successCallback(title);
    };
    const handleFailed = (title) => {
        setImage(null);
        setPath(null);
        handleClose();
        failedCallback(title);
    };

    const handleSearch = (query) => {
        setIsLoading(true);
        setSearchText(query);
    };


    const handleServiceClick = (ser) => {
        let { occurrence } = { ...ser };
        occurrence++;

        setServices((oldState) => {
            const newState = [...oldState];
            const s = newState.find(({ service }) => service.id === ser.service?.id)
            s.occurrence = occurrence;
            return newState
        }
        )
    }

    const handleServiceDelete = (ser) => {
        setServices(services.filter(({ service }) => service.id !== ser.service?.id))
    }

    const [path, setPath] = useState(null);
    const [image, setImage] = useState(null);

    const onDrop = useCallback((acceptedFiles) => {
        setPath(acceptedFiles.map(file => URL.createObjectURL(file)));
        setImage(acceptedFiles ? acceptedFiles[0] : null)
        if (selectedItem.id) {
            const formData = new FormData();
            formData.append('id', selectedItem.id);
            formData.append('file', acceptedFiles[0]);
            dispatch(updatePackPicture(formData)).then(refreshCallback)
        }
    }, [setPath, selectedItem.id]);

    useEffect(() => {
        if (selectedItem?.image) {
            const initpath = selectedItem ? `${API_URL}images/pack/${selectedItem.image}` : null;
            setPath(initpath)
        } else {
            setPath(null);
        }
    }, [selectedItem?.image])

    useEffect(() => {
        if (selectedItem?.services?.length) {
            setServices(selectedItem?.services);
        } else {
            //  setServices([]);
        }
    }, [selectedItem?.services])

    const dispatch = useDispatch();
    const handleItemChange = (field) => (event) => {
        setSelectedItem({ ...selectedItem, [field]: event.target.value });
    };

    const save = (e) => {
        const formData = new FormData();
        if (selectedItem?.id) {
            formData.append('id', selectedItem.id);
        }
        if (!selectedItem?.id) {
            formData.append('file', image);
        }

        formData.append('price', selectedItem.price || '');
        formData.append('sale', selectedItem.sale || '');
        formData.append('fullDescription', selectedItem.fullDescription);
        formData.append('summary', selectedItem.summary);
        formData.append('publish', selectedItem.publish);

        formData.append('title', selectedItem.title);
        formData.append('services', JSON.stringify(services));

        e.preventDefault();
        setloading(true);
        const action = selectedItem.id ? dispatch(updatePack(formData)) : dispatch(addPack(formData));
        action.then(() => handleSuccess(selectedItem.title))
            .catch(() => handleFailed(selectedItem.title))
            .finally(() => setloading(false));
        e.target.reset();
    };

    const renderServices = () => {
        return (<div className="form-group">
            <label className="col-form-label" htmlFor="service"><b>Services:{' '} </b></label>
            <AsyncTypeahead
                filterBy={() => true}
                id="service"
                isLoading={isLoading}
                labelKey="title"
                onChange={(e) => handleSelectService(e)}
                minLength={3}
                onSearch={handleSearch}
                options={options}
                selected={selected}
                placeholder="services..."
                renderMenuItemChildren={(option) => (
                    <>
                        <img
                            src={`${API_URL}images/service/${option.image}`}
                            style={{
                                height: '24px',
                                marginRight: '10px',
                                width: '24px',
                            }}
                        />
                        <span>{option.title}</span>
                    </>
                )}
            />
            <Grid container spacing={2}>

                {services.map((ser, index) =>
                    <Grid item xs={4} key={`grid${index}`} >
                        <span key={`item${index}`} style={{ border: 'none', background: 'transparent', }}>
                            <Badge key={`badge${index}`} badgeContent={ser.occurrence} color="success">
                                <Chip
                                    label={ser.service.title}
                                    key={index}
                                    onClick={(e) => handleServiceClick(ser)}
                                    onDelete={(e) => handleServiceDelete(ser)}
                                />
                            </Badge>
                        </span>
                    </Grid>
                )}
            </Grid>
        </div>)
    }

    useDropzone({ onDrop });
    return (
        <Collapse in={open} timeout="auto" unmountOnExit style={{ backgroundColor: '#f2f2f2', color: '#434343' }}>
            <Stack >
                <Backdrop open={loading} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}><CircularProgress color="secondary" /></Backdrop>
            </Stack>
            <div style={{ width: '100%', display: 'flex', alignContent: 'flex-end' }}>
                <ExpandMore
                    expand={open}
                    onClick={handleClose}
                    aria-expanded={open}
                    aria-label="show more"
                >
                    <FaWindowClose />
                </ExpandMore>
            </div>
            <CardContent>
                <div className="contact-form--1">
                    <div className="container">
                        <form action=""
                            onSubmit={save}
                        >
                            <div className="row row--35 align-items-start">
                                <div className="col-lg-4 order-1 order-lg-1">

                                    <div className="form-wrapper">
                                        <div className="rn-form-group">
                                            <FormControl>
                                                <FormControlLabel
                                                    control={
                                                        <Switch checked={selectedItem.publish} onChange={(event) => {
                                                            setSelectedItem({ ...selectedItem, publish: event.target.checked });
                                                        }} name="Public" />
                                                    }
                                                    label="Public"
                                                />
                                            </FormControl>
                                            <div className="form-group">
                                                <label className="col-form-label" htmlFor="title"><b>Titre: </b></label>
                                                <input
                                                    type="text"
                                                    id='title'
                                                    name="title"
                                                    value={selectedItem.title}
                                                    onChange={handleItemChange('title')}
                                                    placeholder="Titre"
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label className="col-form-label" htmlFor="price"><b>Prix: </b></label>
                                                <input
                                                    type="number"
                                                    name="price"
                                                    id='price'
                                                    value={selectedItem.price}
                                                    onChange={handleItemChange('price')}
                                                    placeholder="Prix"
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label className="col-form-label" htmlFor="sale"><b>Réduction: </b></label>
                                                <input
                                                    type="number"
                                                    name="sale"
                                                    id='sale'
                                                    value={selectedItem.sale}
                                                    onChange={handleItemChange('sale')}
                                                    placeholder="Réduction"
                                                />
                                            </div>

                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-8 order-1 order-lg-1">
                                    <div className="form-group">
                                        <label className="col-form-label" htmlFor="summary"><b>Résumé: </b></label>
                                        <textarea
                                            name="summary"
                                            id='summary'
                                            style={{ height: 70 }}
                                            value={selectedItem.summary}
                                            onChange={handleItemChange('summary')}
                                            placeholder="Résumé"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className="col-form-label" htmlFor="fullDescription"><b>Description: </b></label>
                                        <ReactQuill
                                            value={selectedItem.fullDescription}
                                            onChange={(value) => setSelectedItem({ ...selectedItem, fullDescription: value })}
                                        />
                                    </div>
                                    {
                                        renderServices()
                                    }
                                </div>

                            </div>
                            <div className="thumbnail mb_md--30 mb_sm--30" >
                                <StyledDropZone onDrop={onDrop} ></StyledDropZone>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>

                                    {path && (<img style={{ maxWidth: 200, height: 'auto' }} key={path} src={path} />)}
                                    {/* <img src="/assets/images/about/about-3.jpg" alt="kayani" /> */}
                                </div>
                            </div>
                            <div className="section-title text-center mt--20">
                                <div className="rn-form-group">
                                    <button
                                        className="rn-button-style--2 btn-solid"
                                        type="submit"
                                        value="submit"
                                        name="submit"
                                        id="mc-embedded-subscribe">Valider
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </CardContent>
        </Collapse >

    );
}

export default PackEdit