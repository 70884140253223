import React, { memo, useState, useMemo, useCallback } from 'react'
import ListBox from './components/ListBox'
import { Grid, Button } from '@mui/material'


const PickList = ({
  options,
  selectedOptions = [],
  onSelect,
  onRemove,
  displayedFields,
  onSearch
}) => {
  const [query, setQuery] = useState('')

  const availableOptions = useMemo(() => {
    return options.filter((item) => !selectedOptions.map(val => val.id).includes(item.id));
  }, [options, selectedOptions]);

  const onItemAdd = useCallback(
    (item) => {
      console.log(item)
      const sortedList = selectedOptions.slice()
      onSelect && onSelect({ item, sortedList })
    },
    [onSelect, selectedOptions]
  )

  const onItemRemove = useCallback(
    item => {
      console.log(item)
      onRemove && onRemove({ item })
    },
    [onRemove]
  )




  return (
    <>
      <div className='listHeader'>
        <input
          type="text"
          className='search'
          value={query}
          onChange={e => { setQuery(e.target.value); onSearch(e.target.value) }}
          placeholder={'Rechercher...'}
        />
      </div>
      <div className="pickList">

        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item>
            <ListBox
              data={availableOptions}
              onItemClick={onItemAdd}
              displayedFields={displayedFields}
            />

          </Grid>
          <Grid item>
            <Grid container direction="column" alignItems="center">
              <Button
                sx={{ my: 0.2 }}
                variant="outlined"
                size="small"
                aria-label="move selected right"
              >
                &gt;
              </Button>
              <Button
                sx={{ my: 0.2 }}
                variant="outlined"
                size="small"
                aria-label="move selected left"
              >
                &lt;
              </Button>
            </Grid>
          </Grid>
          <Grid item>
            <ListBox
              data={selectedOptions}
              onItemClick={onItemRemove}
              displayedFields={displayedFields}
            />
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default memo(PickList)
