import React, { useEffect, useState } from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from 'react-icons/fi';

import PageHelmet from '../component/common/Helmet';
import Header from '../component/header/Header';
import FooterTwo from 'component/footer/FooterTwo';
import { useDispatch } from 'react-redux';
import { getAllCoachs } from 'state/actions/coach';
import { API_URL } from 'utils/constantes';


const Team = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const fetchData = () => (dispatch(getAllCoachs()));

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data: apiData, status } = await fetchData();
        if (status === 200) {

          setData(apiData);
        }
      } catch (e) {
        console.log(e);
      }
    };
    fetch();
  }, []);
  const imgLink = (value) => value.image ? `${API_URL}images/coach/${value.image}` : '/assets/images/default.jpg'
  return (
    <>
      <PageHelmet pageTitle="équipe" />
      {/* Start Header Area  */}
      <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

      {/* Start Breadcrump Area */}
      <div className="rn-page-title-area ptb--80 bg_image bg_image--17" data-black-overlay="7">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="rn-page-title text-center pt--100">
                <h2 className="title theme-gradient">Notre équipe</h2>
                <p>Kayani est un centre de bien-être qui réunit des thérapeutes de différentes disciplines. </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrump Area */}
      {/* End Header Area  */}

      {/* Start Page Wrapper  */}
      <main className="page-wrapper">
        {/* Start Team Area  */}
        <div className="rn-team-wrapper ptb--120 bg_color--1">
          <div className="rn-team-area">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title text-center mb--30">
                    <h2>NOTRE PERSONNELS</h2>
                    <p>
                      There are many variations of passages of Lorem Ipsum available,<br />
                      {' '}
                      but the majority have suffered alteration.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                {data.map((value, i) => (
                  <div className={'col-lg-3'} key={i}>
                    <div className={'team team-style--bottom'}>
                      <div className="thumbnail">
                        <img src={imgLink(value)} alt="Blog Images" />
                      </div>
                      <div className="content">
                        <h4 className="title">{value.title}</h4>
                        <p className="designation">{`${value.firstname} ${value.lastname}`}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {/* End Team Area  */}

      </main>
      {/* End Page Wrapper  */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      {/* Start Footer Area  */}
      <FooterTwo />
      {/* End Footer Area  */}

    </>

  )
};

export default Team;
