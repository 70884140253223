import React, { memo } from 'react'
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import { ListItemButton } from '@mui/material'

const ListBox = ({
  data = [],
  onItemClick,
  displayedFields = []
}) => {

  const label = (item) => displayedFields.map(field => item[field]).join(' ');

  return (
    <div className='listBox'>
      <Paper sx={{ width: 228, height: 150, overflow: 'auto' }}>
        <List dense component="div" role="list">
          {data.map((item) => {
            const labelId = `transfer-list-item-${item.id}-label`;

            return (
              <ListItemButton
                key={item.id}
                role="listitem"
                onClick={() => onItemClick(item)}
              >
                <ListItemText id={labelId} primary={label(item)} />
              </ListItemButton>
            );
          })}
        </List>
      </Paper>
    </div>
  )
}

export default memo(ListBox)
