import { CardContent, Collapse, FormControl, FormControlLabel, Switch } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone';
import StyledDropZone from 'component/common/styled.dropzone';
import { API_URL } from 'utils/constantes';
import { useDispatch } from 'react-redux';
import { addProduct, updateProductPicture, updateProduct } from 'state/actions/products';
import { ExpandMore } from 'elements/expandMore';
import { FaWindowClose } from 'react-icons/fa';
import ReactQuill from 'react-quill';

const ProductEdit = (props) => {
  const { open, setOpen, selectedItem, setSelectedItem, successCallback, failedCallback, refreshCallback } = props;

  const handleSuccess = (title) => {
    setImage(null);
    setPath(null);
    setSelectedItem({})
    setOpen(false);
    successCallback(title);
  };
  const handleFailed = (title) => {
    setImage(null);
    setPath(null);
    setSelectedItem({})
    setOpen(false);
    failedCallback(title);
  };

  const [path, setPath] = useState(null);
  const [image, setImage] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    setPath(acceptedFiles.map(file => URL.createObjectURL(file)));
    setImage(acceptedFiles ? acceptedFiles[0] : null)
    if (selectedItem.id) {
      const formData = new FormData();
      formData.append('id', selectedItem.id);
      formData.append('file', acceptedFiles[0]);
      dispatch(updateProductPicture(formData)).then(refreshCallback)
    }
  }, [setPath, selectedItem.id]);

  useEffect(() => {
    if (selectedItem?.image) {
      const initpath = selectedItem ? `${API_URL}images/product/${selectedItem.image}` : null;
      setPath(initpath)
    } else {
      setPath(null);
    }
  }, [selectedItem?.image])




  const dispatch = useDispatch();
  const handleItemChange = (field) => (event) => {
    setSelectedItem({ ...selectedItem, [field]: event.target.value });
  };

  const saveProduct = (e) => {
    const formData = new FormData();
    if (selectedItem?.id) {
      formData.append('id', selectedItem.id);
    }
    if (!selectedItem?.id) {
      formData.append('file', image);
    }

    formData.append('price', selectedItem.price || '');
    formData.append('title', selectedItem.title);
    formData.append('publish', selectedItem.publish);

    formData.append('fullDescription', selectedItem.fullDescription || '');
    formData.append('category', selectedItem.category || '');
    e.preventDefault();
    const action = selectedItem.id ? dispatch(updateProduct(formData)) : dispatch(addProduct(formData));
    action.then(() => handleSuccess(selectedItem.title))
      .catch(() => handleFailed(selectedItem.title));
    e.target.reset();
  };



  useDropzone({ onDrop });
  return (
    <Collapse in={open} timeout="auto" unmountOnExit style={{ backgroundColor: '#f2f2f2', color: '#434343' }}>
      <div style={{ width: '100%', display: 'flex', alignContent: 'flex-end' }}>
        <ExpandMore
          expand={open}
          onClick={() => setOpen(false)}
          aria-expanded={open}
        >
          <FaWindowClose />
        </ExpandMore>
      </div>
      <CardContent>
        <div className="contact-form--1">
          <div className="container">
            <form action=""
              onSubmit={saveProduct}
            >
              <div className="row row--35 align-items-start">
                <div className="col-lg-6 order-1 order-lg-1">

                  <div className="form-wrapper">
                    <div className="rn-form-group">

                      <div className="form-group">
                        <FormControl>

                          <FormControlLabel
                            control={
                              <Switch checked={selectedItem.publish} onChange={(event) => {
                                setSelectedItem({ ...selectedItem, publish: event.target.checked });
                              }} name="publish" />
                            }
                            label="Public"
                          />
                        </FormControl>
                      </div>

                      <div className="form-group">
                        <label className="col-form-label" htmlFor="title"><b>Titre: </b></label>
                        <input
                          type="text"
                          id='title'
                          name="title"
                          value={selectedItem.title}
                          onChange={handleItemChange('title')}
                          placeholder="Titre"
                          required
                        />

                      </div>
                      <div className="form-group">
                        <label className="col-form-label" htmlFor="category"><b>Category: </b></label>
                        <input
                          type="text"
                          id='category'
                          name="category"
                          value={selectedItem.category}
                          onChange={handleItemChange('category')}
                          placeholder="Catégorie"
                        />

                      </div>
                      <div className="form-group">
                        <label className="col-form-label" htmlFor="price"><b>Prix: </b></label>
                        <input
                          type="number"
                          name="price"
                          id='price'
                          value={selectedItem.price}
                          onChange={handleItemChange('price')}
                          placeholder="Prix"
                        />
                      </div>

                    </div>
                  </div>
                </div>
                <div className="col-lg-6 order-2 order-lg-2">
                  <div className="form-group">
                    <label className="col-form-label" htmlFor="fullDescription"><b>Description: </b></label>
                    <ReactQuill
                      value={selectedItem.fullDescription}
                      onChange={(value) => setSelectedItem({ ...selectedItem, fullDescription: value })}
                    />
                  </div>
                  <div className="thumbnail mb_md--30 mb_sm--30" >
                    <StyledDropZone onDrop={onDrop} ></StyledDropZone>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>

                      {path && (<img style={{ maxWidth: 200, height: 'auto' }} key={path} src={path} />)}
                      {/* <img src="/assets/images/about/about-3.jpg" alt="kayani" /> */}
                    </div>
                  </div>

                </div>
              </div>
              <div className="section-title text-center mt--20">
                <div className="rn-form-group">
                  <button
                    className="rn-button-style--2 btn-solid"
                    type="submit"
                    value="submit"
                    name="submit"
                    id="mc-embedded-subscribe">Valider
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

      </CardContent>
    </Collapse>


  );
}

export default ProductEdit