/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import HeaderNav from './header.nav';
import KeycloakComponent from './keycloak'

const Header = (props) => {

  const { color = 'default-color' } = props;

  useEffect(() => {
    const elements = document.querySelectorAll('.has-droupdown > a');
    for (let i = 0; i < elements.length; i++) {
      elements[i].onclick = function () {
        this.parentElement.querySelector('.submenu').classList.toggle('active');
        this.classList.toggle('open');
      }
    }

    const loadListener = () => {
      console.log('All assets are loaded');
    }
    window.addEventListener('load', loadListener);

    return () => {
      window.removeEventListener('load', loadListener);
    };
  }, []);


  const logoUrl = <img src="/assets/images/logo/logo.png" alt="Logo images" />

  return (
    <header className={`header-area formobile-menu header--transparent ${color}`}>
      <div className="header-wrapper" id="header-wrapper">
        <div className="header-left">
          <div className="logo">
            <Link to="/">
              {logoUrl}
            </Link>
          </div>
        </div>
        <div className="header-right">
          <HeaderNav />
        </div>
      </div>
    </header >
  );
};
export default Header;
