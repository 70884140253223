import { Accordion, AccordionDetails, AccordionSummary, Box, FormControlLabel, IconButton, InputBase, Paper, Stack, Switch, Tooltip, Typography } from '@mui/material'
import EventEdit from 'backoffice/event/edit.event';
import CustomTablePagination from 'component/common/customTablePagination';
import React, { Fragment, useState } from 'react'
import { FaRegCheckCircle, FaWindowClose } from 'react-icons/fa';
import { FiArrowDown, FiSearch, FiWatch } from 'react-icons/fi'
import { useDispatch } from 'react-redux';
import { fetchRequests, getEventById, updateRequest } from 'state/actions/program';
import Swal from 'sweetalert2';
import { useDebounce } from 'usehooks-ts';
import { PENDING, REJECTED, VALIDATED } from 'utils/constantes';
import { formatDateTime, formatTime } from 'utils/dateutils';


const EventRequests = () => {
    const dispatch = useDispatch();
    const fetchData = (pageable) => (dispatch(fetchRequests(pageable)));
    const [data, setData] = useState({ rows: [], total: 0 });
    const [searchText, setSearchText] = useState('');
    const debouncedSearchText = useDebounce(searchText, 500);
    const [actions, setActions] = useState({ update: new Date() });

    const [selectedEvent, setSelectedEvent] = useState({});
    const [eventDisplay, setEventDisplay] = useState(false);

    const [controller, setController] = useState({
        page: 0,
        rowsPerPage: 10,
        order: 'asc',
        orderBy: 'createdAt',
        searchText,
        onlyPending: false
    });

    const handleSearchTextChange = ({ target }) => {
        setSearchText(target.value);
    };
    const handleOnlyPendingChange = (event) => {
        setController({ ...controller, onlyPending: event.target.checked });
    };
    const fields = [
        { id: 'client.firstname', label: 'client', render: (row) => `${row.client?.firstname} ${row.client?.lastname}` },
        { id: 'client.phone', label: 'numéro', render: (row) => `${row.client?.phone}` },
        {
            id: 'event.title', label: 'programme', render: (row) =>

                <Box
                    onClick={() => handleEventSelect(row.event.id)}

                    style={{ marginTop: '-20px' }}>
                    <span className='client-link' >
                        {`${row.event?.title}`}
                    </span>
                </Box>

        },
        { id: 'event.date', label: 'séance', render: (row) => `${row.event?.date} ${formatTime(row.event?.startTime)} -  ${formatTime(row.event?.endTime)}` },
        {
            id: 'status', label: 'Statut', render: (row) =>
                row.status === PENDING ? <span className="badge badge-warning" style={{ marginTop: '-10px', position: 'absolute' }}>En attente</span>
                    : row.status === REJECTED ? <span className="badge badge-danger" style={{ marginTop: '-10px', position: 'absolute' }}>Déclinée</span>
                        : <span className="badge badge-success" style={{ marginTop: '-10px', position: 'absolute' }}>Validée</span>
        },
        { id: 'createdAt', label: 'Date création', render: (row) => formatDateTime(row.createdAt) },
        { id: 'updatedAt', label: 'Date mise à jour', render: (row) => formatDateTime(row.updatedAt) },
        {
            id: 'id', label: 'actions', render: (row) =>
                <Box style={{ marginTop: -20 }}>
                    {
                        row.status !== VALIDATED &&
                        <Tooltip title="Confirmée" onClick={() => dispatch(updateRequest({ id: row.id, status: VALIDATED })).then(() => {
                            setActions((state) => ({ ...state, update: new Date() }));
                        })}>
                            <IconButton  >
                                <FaRegCheckCircle style={{ color: '#1e7e34', fontSize: 18 }} />
                            </IconButton>
                        </Tooltip>
                    }
                    {row.status !== REJECTED &&
                        <Tooltip title="Déclinée" onClick={() => dispatch(updateRequest({ id: row.id, status: REJECTED })).then(() => {
                            setActions((state) => ({ ...state, update: new Date() }));
                        })}>
                            <IconButton>
                                <FaWindowClose style={{ color: '#dc3545', fontSize: 18 }} />
                            </IconButton>
                        </Tooltip>}

                    {row.status !== PENDING &&
                        <Tooltip title="En attente" onClick={() => dispatch(updateRequest({ id: row.id, status: PENDING })).then(() => {
                            setActions((state) => ({ ...state, update: new Date() }));
                        })} >
                            <IconButton>
                                <FiWatch style={{ color: '#d39e00', fontSize: 18 }} />
                            </IconButton>
                        </Tooltip>}
                </Box>
        }
    ];

    const handleEventSelect = (id) => dispatch(getEventById(id)).then(({ status, data }) => {
        if (status === 200) {
            setSelectedEvent(data)
            setEventDisplay(true)
        }
    })

    const handleProgramAddSuccess = (title) => {
        Swal.fire({
            icon: 'success',
            title: 'Success',
            text: `Utilisateur ajouté à ${title}`,
        });
    }

    const handleProgramAddFail = (title) => Swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: `Echec de sauvegarde de ${title} `,
    });
    return (

        <Fragment>
            <div className="rn-blog-area ptb--20 bg_color--1">
                <div className="container">

                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography gutterBottom>
                            <b style={{ textTransform: 'uppercase' }}>{`TOTAL : ${data.total} `}</b>
                        </Typography>
                        <FormControlLabel

                            control={
                                <Switch checked={controller.onlyPending} onChange={handleOnlyPendingChange} name="gilad" />
                            }
                            label="En attente uniquement"
                        />

                        <Paper sx={{ width: 200, height: 40, display: 'flex', alignItems: 'center' }}>

                            <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                placeholder="Recherche"
                                inputProps={{ 'aria-label': 'Recherche' }}
                                value={searchText}
                                onChange={handleSearchTextChange}

                            />
                            <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                                <FiSearch />
                            </IconButton>
                        </Paper>
                        {' '}
                    </Stack>
                    <EventEdit
                        open={eventDisplay}
                        setOpen={setEventDisplay}
                        selectedEvent={selectedEvent}
                        onlyUserChange={true}
                        setSelectedEvent={setSelectedEvent}
                        handleProgramAddSuccess={handleProgramAddSuccess}
                        handleProgramAddFail={handleProgramAddFail}
                    />
                    <CustomTablePagination
                        controller={controller}
                        setController={setController}
                        fields={fields}
                        actions={actions}
                        searchText={debouncedSearchText}
                        fetchData={fetchData}
                        data={data}
                        setData={setData}
                    />
                </div>
            </div>
        </Fragment>

    )
}

export default EventRequests