import Accordion01 from 'elements/Accordion';
import React, { useState } from 'react'
import ModalVideo from 'react-modal-video'
const HomeAbout = () => {
    const [state, setState] = useState({
        isOpen: false,
    });

    const openModal = () => {
        setState({ isOpen: true });
    };
    return (
        <div className="rn-about-area ptb--120 bg_color--5">
            <div className="container">
                <div className="row row--35 align-items-center">
                    <div className="col-lg-6 order-2 order-lg-1">
                        <div className="about-inner inner">
                            <div className="section-title">
                                <h2 className="title">A PROPOS</h2>
                                <p className="description">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable.</p>
                            </div>

                            <div className="accordion-wrapper mt--30">
                                <Accordion01 />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 order-1 order-lg-2">
                        <div className="thumbnail position-relative">
                            <img className="w-100" src="/assets/images/about/about-kayani.jpg" alt="About Images" />
                            <ModalVideo channel="youtube" isOpen={state.isOpen} videoId="d1OQhVO69KA" onClose={() => setState({ isOpen: false })} />
                            <button className="video-popup position-top-center theme-color" onClick={openModal}><span className="play-icon" /></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
}

export default HomeAbout