/* eslint-disable react/jsx-filename-extension */
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import { Provider } from 'react-redux';
import App from './App';
//import * as serviceWorker from './serviceWorker';
import { store } from './state/store';


ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </StrictMode>,
  document.getElementById('root'),
);

//serviceWorker.register();
