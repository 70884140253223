import React, { Fragment, useState } from 'react';

// @mui
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  InputBase,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  FiArrowDown,
  FiBell,
  FiBellOff,
  FiEdit,
  FiPlusCircle,
  FiSearch,
  FiTrash2
} from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { deleteClient, fetchClients, enableClient, disableClient } from 'state/actions/client';
import Swal from 'sweetalert2';
import CustomTablePagination from 'component/common/customTablePagination';
import { useDebounce } from 'usehooks-ts';
import UserEdit from './editUser';
import { ExpandMore } from 'elements/expandMore';
import PageHelmet from 'component/common/Helmet';
import UserCalendar from './user.calendar';

const Users = () => {
  const dispatch = useDispatch();
  const [actions, setActions] = useState({ update: new Date() });
  const [calendarDisplay, setCalendarDisplay] = useState(false)
  const [selectedUser, setSelectedUser] = useState({})

  const initUser = {
    firstname: '',
    lastname: '',
    login: '',
    email: '',
    phone: ''
  };

  const [editUser, setEditUser] = useState(initUser);
  const [data, setData] = useState({ rows: [], total: 0 });

  const [searchText, setSearchText] = useState('');
  const debouncedSearchText = useDebounce(searchText, 500);
  const [userDialogDisplay, setUserDialogDisplay] = useState(false);

  const fetchData = (pageable) => (dispatch(fetchClients(pageable)));
  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: 10,
    order: 'desc',
    orderBy: 'createdAt',
    searchText,
  });

  const fields = [
    {
      id: 'login', label: 'Identifiant', render: (row) =>
        <Box
          onClick={() => handleUserSelect(row)}

          style={{ marginTop: '-15px' }}>
          <span className={row.id === selectedUser?.id ? 'client-link selected' : 'client-link'}>
            {row.login}
          </span>
        </Box>
    },
    { id: 'firstname', label: 'Prénom' },
    { id: 'lastname', label: 'Nom' },
    { id: 'phone', label: 'Numéro' },
    { id: 'email', label: 'Email' },
    {
      id: 'enabled', label: 'Statut', render: (row) => row.enabled ? <span className="badge badge-success" style={{ marginTop: '-10px', position: 'absolute' }}>Activé</span>
        : <span className="badge badge-danger" style={{ marginTop: '-10px', position: 'absolute' }}>Désactivé</span>
    },
    { id: 'createdAt', label: 'Date création' },
    {
      id: 'id', label: 'actions', render: (row) =>
        <Box style={{ marginTop: -20 }}>
          <Tooltip title="Supprimer" onClick={() => handleRemoveClient(row)}>
            <IconButton>
              <FiTrash2 style={{ color: '#dc3545', fontSize: 18 }} />
            </IconButton>
          </Tooltip>

          <Tooltip title="Modifier" onClick={() => handleEditAction(row)} >
            <IconButton>
              <FiEdit style={{ color: '#d39e00', fontSize: 18 }} />
            </IconButton>
          </Tooltip>

          {!row.enabled &&
            <Tooltip title="Activer" onClick={() => handleEnableAction(row)} >
              <IconButton>
                <FiBell style={{ color: '#1e7e34', fontSize: 18 }} />
              </IconButton>
            </Tooltip>
          }
          {row.enabled &&
            <Tooltip title="Désactiver" onClick={() => handleDisableAction(row)} >
              <IconButton>
                <FiBellOff style={{ color: '#dc3545', fontSize: 18 }} />
              </IconButton>
            </Tooltip>
          }
        </Box>
    }
  ];

  const handleSearchTextChange = ({ target }) => {
    setSearchText(target.value);
  };


  const handleEditAction = (user) => {
    setEditUser(user);
    setUserDialogDisplay(true)
  }

  const handleEnableAction = async (user) => {
    dispatch(enableClient(user.id)).then(() => {
      setActions((state) => ({ ...state, update: new Date() }));
    });
  };

  const handleDisableAction = async (user) => {
    dispatch(disableClient(user.id)).then(() => {
      setActions((state) => ({ ...state, update: new Date() }));
    });
  };


  const handleRemoveClient = async (user) => {
    const { value } = await Swal.fire({
      title: `Suppression de ${user.login}`,
      icon: 'warning',
      text: 'Vous ne pourrez pas revenir en arrière !',
      showCancelButton: true,
      confirmButtonText: 'Supprimer',
      cancelButtonText: 'Annuler',

    });
    if (value) {
      dispatch(deleteClient(user.id)).then(() => {
        setActions((state) => ({ ...state, update: new Date() }));
        Swal.fire({
          icon: 'success',
          title: 'Suppression',
          text: `Utilisateur ${user.login} supprimé`,
        });
      });
    }
  };


  const userUpdateSuccess = (login) => {
    setEditUser(initUser);
    Swal.fire({
      icon: 'success',
      title: 'Succès',
      text: `Utilisateur ${login} modifié`,
    }).then(() => setActions((state) => ({ ...state, update: new Date() })));
  };
  const userUpdateFailed = (login) => {
    setEditUser(initUser);
    Swal.fire({
      icon: 'error',
      title: 'Erreur',
      text: `Echec de modification de l'utilisateur ${login} `,
    })
  };

  const handleUserSelect = (row) => {
    if (row?.id !== selectedUser?.id) {
      setSelectedUser(row)
      setCalendarDisplay(true);
    } else {
      setSelectedUser({})
      setCalendarDisplay(false);
    }
  }

  return (
    <Fragment>
      <PageHelmet pageTitle='Calendrier' />
      <div className="rn-blog-area ptb--20 bg_color--1">
        <div className="container">

          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography gutterBottom>
              <b style={{ textTransform: 'uppercase' }}>{`TOTAL : ${data.total} `}</b>
            </Typography>


            <Stack direction="row" alignItems="center">
              {' '}
              <div>
                <Stack direction="row" alignItems="center">
                  <Paper
                    style={{ marginRight: '10px' }}
                    sx={{
                      display: 'flex', alignItems: 'left', width: 200, height: 40,
                    }}
                  >
                    <InputBase
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="Recherche"
                      inputProps={{ 'aria-label': 'Recherche' }}
                      value={searchText}
                      onChange={handleSearchTextChange}

                    />
                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                      <FiSearch />
                    </IconButton>
                  </Paper>
                  {' '}
                  <ExpandMore
                    expand={userDialogDisplay}
                    onClick={() => setUserDialogDisplay(!userDialogDisplay)}
                    aria-expanded={userDialogDisplay}
                    aria-label="show more"
                  >
                    <FiPlusCircle />
                  </ExpandMore> <b>Ajouter</b>
                </Stack>
                {' '}

              </div>

            </Stack>

          </Stack>
          <UserEdit user={editUser} setUser={setEditUser} open={userDialogDisplay} setOpen={setUserDialogDisplay} successCallback={userUpdateSuccess} failedCallback={userUpdateFailed} />

          <CustomTablePagination
            fields={fields}
            searchText={debouncedSearchText}
            fetchData={fetchData}
            actions={actions}
            data={data}
            setData={setData}
            controller={controller}
            setController={setController}
          />

          {calendarDisplay && (
            <Accordion style={{
              marginTop: 10,
              backgroundColor: '#f2f2f2',
              color: '#434343',
              fontFamily: '"Poppins",sans-serif'
            }}>
              <AccordionSummary
                expandIcon={<FiArrowDown />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography style={{ textTransform: 'uppercase' }}><b>{`Calendrier de ${selectedUser.firstname} ${selectedUser.lastname}`}</b></Typography>
              </AccordionSummary>
              <AccordionDetails>
                <UserCalendar userId={selectedUser?.id}></UserCalendar>
              </AccordionDetails>
            </Accordion>)
          }
        </div>
      </div>
    </Fragment>
  );
};

export default Users;


