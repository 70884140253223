/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectAuthenticated, selectRoles } from 'state/selectors/user';

const PrivateRoute = (props) => {
  const { children, roles } = props;

  const auth = useSelector(selectAuthenticated);
  const currentRoles = useSelector(selectRoles);
  return (
    <Route
      render={(routeProps) => {
        if (!auth)
          return (
            <Redirect
              to={{
                pathname: '/',
                state: { from: routeProps?.location },
              }}
            />
          );

        if (!roles)
          return children;

        if (currentRoles && currentRoles.some(role => roles.includes(role))) {
          return children;
        }

        return (
          <Redirect
            to={{
              pathname: '/',
              state: { from: routeProps?.location },
            }}
          />
        );
      }}
    />
  );
};

export default PrivateRoute;
