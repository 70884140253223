import GridList from 'elements/gridList/grid.list'
import React from 'react'
import { deleteProgram, fetchPrograms } from 'state/actions/program'
import { API_URL } from 'utils/constantes'
import ProgramEdit from './program.Edit'

const ProgramList = () => {
    const initialSelected = {
        id: null,
        title: '',
        price: '',
        sale: '',
        image: '',
        fullDescription: '',
        shortDescription: '',
        events: [],
        publish: false,
    };
    const generateLink = (item) => `/event/${item.id}`

    return (
        <GridList
            label={'Programme'}
            baseUrl={`${API_URL}images/program`}
            initialSelectedItem={initialSelected}
            fetchAction={fetchPrograms}
            deleteAction={deleteProgram}
            generateLink={generateLink}
        >
            <ProgramEdit />

        </GridList>
    )
}

export default ProgramList