import React, { Children, Fragment, useEffect, useState } from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp, FiEdit2, FiEye, FiPlusCircle, FiSearch, FiTrash2 } from 'react-icons/fi';
import { Pagination } from 'elements/common/Pagination';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { Checkbox, FormControl, IconButton, InputBase, Paper, Stack, Typography } from '@mui/material';
import { useDebounce } from 'usehooks-ts';
import { ExpandMore } from 'elements/expandMore';
import { Link } from 'react-router-dom';

const GridList = (props) => {
    const { children,
        baseUrl,
        initialSelectedItem,
        fetchAction,
        deleteAction,
        label,
        initTypes: types,
        typesLabels,
        typeColors,
        generateLink = () => '' } = props;
    const dispatch = useDispatch();

    const [data, setData] = useState({ rows: [], count: 0 })
    const [pageable, setPageable] = useState({ page: 0, rowsPerPage: 6, order: 'desc', orderBy: 'updatedAt', searchText: '', types })
    const [selectedItem, setSelectedItem] = useState(initialSelectedItem)
    const [itemDisplay, setItemDisplay] = useState(false);

    const [actions, setActions] = useState({
        refresh: 0
    });
    const debouncedSearchText = useDebounce(pageable.searchText, 500);

    const handleTextFilter = ({ target }) => {
        if (target.value !== pageable.searchText)
            setPageable({ ...pageable, searchText: target.value, page: 0 });
    };

    const onPageChange = (currentPage) => {
        setPageable({ ...pageable, page: currentPage - 1 })
    }

    const handleTypeFilter = (event, field) => {
        const { checked } = event.target;
        const { types } = pageable;
        const newTypes = checked ? [...types, field] : [...types].filter(type => type !== field)
        setPageable({ ...pageable, types: newTypes, page: 0 })
    };

    const fetchData = (pageable) => (dispatch(fetchAction(pageable)));

    const handleRemoveItem = async (item) => {
        const { value } = await Swal.fire({
            title: `Suppression de ${item.title}`,
            icon: 'warning',
            text: 'Vous ne pourrez pas revenir en arrière !',
            showCancelButton: true,
            confirmButtonText: 'Supprimer',
            cancelButtonText: 'Annuler',

        });
        if (value) {
            dispatch(deleteAction(item.id))
                .then(() => {
                    setActions((state) => ({ ...state, refresh: state.refresh + 1 }));
                    setSelectedItem(initialSelectedItem);
                    setItemDisplay(false)

                    Swal.fire({
                        icon: 'success',
                        title: 'Suppression',
                        text: `${label} ${item.id} supprimé`,
                    });
                }).catch((e) => {
                    console.log(e);
                    setSelectedItem(initialSelectedItem);
                    setItemDisplay(false)

                    Swal.fire({
                        icon: 'error',
                        title: 'Suppression',
                        text: `${label} ${item.id} non supprimé`,
                    });
                });
        }
    };

    useEffect(() => {
        const fetch = async () => {
            try {
                const { data: apiData, status } = await fetchData(pageable);
                if (status === 200) {
                    const { rows, count } = apiData;
                    setData((oldState) => ({ ...oldState, rows, count }));
                }
            } catch (e) {
                console.log(e);
            }
        };
        fetch();
    }, [actions, debouncedSearchText, pageable.page, pageable.types]);



    const onUpdateSuccess = (title) => {
        setSelectedItem(initialSelectedItem);
        Swal.fire({
            icon: 'success',
            title: 'Success',
            text: `${label} ${title} inséré(e)`,
        }).then(() => setActions((state) => ({ ...state, refresh: state.refresh + 1 })));
    };
    const onUpdateFailed = (title) => {
        setSelectedItem(initialSelectedItem);
        Swal.fire({
            icon: 'error',
            title: 'Erreur',
            text: `Echec de sauvegarde de ${label} ${title} `,
        })
    }

    const handleEditItem = (item) => {
        setSelectedItem({ ...item });
        setItemDisplay(true)
    }

    const renderChildren = () => {
        return (
            <div>
                {Children.map(children, (child) => {
                    return React.cloneElement(child, {
                        selectedItem: selectedItem,
                        setSelectedItem: setSelectedItem,
                        open: itemDisplay,
                        setOpen: setItemDisplay,
                        successCallback: onUpdateSuccess,
                        refreshCallback: () => setActions((state) => ({ ...state, refresh: state.refresh + 1 })),
                        failedCallback: onUpdateFailed
                    });
                })}
            </div>
        );
    }

    const renderTypeCheckbox = (type, index) =>

    (<span>
        <Checkbox
            {...label}
            checked={pageable.types.includes(type)}
            onChange={(e) => handleTypeFilter(e, type)}
            sx={{
                color: typeColors[index],
                '&.Mui-checked': {
                    color: typeColors[index],
                },
            }}
        ></Checkbox>{typesLabels[index]}</span>);

    return (
        <React.Fragment>
            {/* Start Blog Area */}
            <div className="rn-blog-area ptb--20 bg_color--1">

                <div className="container">
                    <Fragment>

                        <Stack direction="row" alignItems="center" spacing={{ xs: 1, sm: 1 }} justifyContent="space-between" mb={5}>
                            <Stack direction="row" alignItems="left">
                                <Typography gutterBottom>
                                    <b style={{ textTransform: 'uppercase' }}>{`TOTAL: ${data.count} `}</b>
                                </Typography>
                                <FormControl style={{ marginTop: -9, paddingLeft: 20 }}>
                                    <div>
                                        {types?.map((type, index) => renderTypeCheckbox(type, index))}
                                    </div>
                                </FormControl>

                            </Stack>
                            <Stack direction="row" alignItems="center">
                                <Paper
                                    style={{ marginRight: '10px' }}
                                    sx={{
                                        display: 'flex', alignItems: 'left', width: 200, height: 40,
                                    }}
                                >
                                    <InputBase
                                        sx={{ ml: 1, flex: 1 }}
                                        placeholder="Recherche"
                                        inputProps={{ 'aria-label': 'Recherche' }}
                                        value={pageable.searchText}
                                        onChange={handleTextFilter}

                                    />
                                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                                        <FiSearch />
                                    </IconButton>
                                </Paper>
                                {' '}
                                {!itemDisplay && <>
                                    <ExpandMore
                                        expand={itemDisplay}
                                        onClick={() => {
                                            setSelectedItem(initialSelectedItem);
                                            setItemDisplay(true);
                                        }}
                                        aria-expanded={itemDisplay}
                                        aria-label="show more"
                                    >
                                        <FiPlusCircle />
                                    </ExpandMore> <b>Ajouter</b>
                                </>}
                            </Stack>
                        </Stack>

                        {renderChildren()}
                        <div style={itemDisplay ? { opacity: '0.2', pointerEvents: 'none' } : {}}>
                            <div className="row" style={{ marginTop: 10 }}>
                                {data.rows.map((value, i) => {
                                    return (
                                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                            <div className="blog blog-style--1">
                                                <div className="thumbnail" style={value.publish === false ? { opacity: '0.3' } : {}}>
                                                    <Link to={generateLink(value)}>
                                                        {/* <img className="w-100" src={`${API_URL}images/activity/${value.image}`} alt={value.title} /> */}
                                                        <img className="w-100" src={value.image ? `${baseUrl}/${value.image}` : '/assets/images/default.jpg'} alt={value.title} />
                                                    </Link>
                                                </div>
                                                <div className="content">
                                                    <p className="blogtype">{value.type}</p>
                                                    <h4 className="title"><a>{value.title}</a></h4>

                                                    <div className="blog-btn">
                                                        <Link style={{ cursor: 'pointer' }} to='#' className="rn-btn text-white" onClick={() => { handleEditItem(value); }}><FiEdit2 title='Editer' /></Link>
                                                        {' '}
                                                        <Link style={{ cursor: 'pointer' }} to='#' className="rn-btn text-white" onClick={() => handleRemoveItem(value)}><FiTrash2 title='supprimer' /></Link>
                                                        {' '}
                                                        <Link className="rn-btn text-white" to={generateLink(value)}><FiEye title='visualiser' /></Link>{ }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </Fragment>
                    <div className="row mt--20">
                        <div className="col-lg-12">
                            {/* Start Pagination Area */}
                            <Pagination nbPages={Math.ceil(data.count / pageable.rowsPerPage)} currentPage={pageable.page + 1} onPageChange={onPageChange} />
                            {/* End Pagination Area */}
                        </div>
                    </div>
                </div>
            </div >
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
        </React.Fragment >
    )
}

export default GridList