import { Backdrop, Box, Chip, CircularProgress, Grid, Hidden, Modal, Stack, Step, StepButton, StepLabel, Stepper } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { useDispatch } from 'react-redux';
import { fetchServices } from 'state/actions/service';
import { fetchClients } from 'state/actions/client';
import { useDebounce } from 'usehooks-ts';
import { API_URL } from 'utils/constantes';
import PickList from 'elements/pickList/index'
import { FiCheckSquare, FiTrash2 } from 'react-icons/fi';
import 'moment/locale/fr'  // without this line it didn't work

import { addEvent, fetchPrograms, updateEvent } from 'state/actions/program';
import { event_modal_style } from './data';
import { fetchCoachs } from 'state/actions/coach';

const steps = ['Configuration', 'Participants', 'Date'];

const EventEdit = (props) => {
    moment.locale('fr');

    const { open, setOpen, selectedEvent, setSelectedEvent, handleProgramAddSuccess, handleProgramAddFail, handleProgramDelete, onlyUserChange } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [serviceOptions, setServiceOptions] = useState([]);
    const [coachOptions, setCoachOptions] = useState([]);
    const [programOptions, setProgramOptions] = useState([]);
    const [clientOptions, setClientOptions] = useState([]);

    const [serviceSearchText, setServiceSearchText] = useState('');
    const [coachSearchText, setCoachSearchText] = useState('')
    const [programSearchText, setProgramSearchText] = useState('')
    const [clientSearchText, setClientSearchText] = useState('')
    const [loading, setloading] = useState(false)

    const [selectedCoach, setSelectedCoach] = useState([])
    const [selectedService, setSelectedService] = useState([]);
    const [selectedProgram, setSelectedProgram] = useState([])
    const [participants, setParticipants] = useState([]);

    const serviceDebouncedSearchText = useDebounce(serviceSearchText, 500);
    const programDebouncedSearchText = useDebounce(programSearchText, 500);
    const coachDebouncedSearchText = useDebounce(coachSearchText, 500);
    const clientDebouncedSearchText = useDebounce(clientSearchText, 500);

    const dispatch = useDispatch();
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});

    const handleStep = (step) => () => {
        setActiveStep(step);
        console.log(step)
    };

    const handleAddGuest = (ev) => {
        if (ev.target.value)
            setSelectedEvent({ ...selectedEvent, guests: [...selectedEvent.guests, ev.target.value] })
    }
    const handleDeleteGuest = (str) => {
        setSelectedEvent({ ...selectedEvent, guests: selectedEvent.guests.filter(gst => gst !== str) })
        console.log(selectedEvent.guests)
    }

    useEffect(() => {
        dispatch(fetchServices({ searchText: serviceSearchText }))
            .then(({ data }) => {
                setServiceOptions(data?.rows);
                setIsLoading(false);
            });

    }, [serviceDebouncedSearchText])

    useEffect(() => {
        dispatch(fetchPrograms({ searchText: programSearchText }))
            .then(({ data }) => {
                setProgramOptions(data?.rows);
            });
    }, [programDebouncedSearchText])

    useEffect(() => {
        dispatch(fetchClients({ searchText: clientSearchText }))
            .then(({ data }) => {
                setClientOptions(data?.rows);
                setIsLoading(false);
            });

    }, [clientDebouncedSearchText])

    useEffect(() => {
        dispatch(fetchCoachs({ searchText: coachSearchText }))
            .then(({ data }) => {
                setCoachOptions(data?.rows);
            });

    }, [coachDebouncedSearchText])

    // useEffect(() => {
    //     if (selectedService[0]?.title)
    //         setSelectedEvent({ ...selectedEvent, title: selectedService[0]?.title })
    // }, [selectedService])



    useEffect(() => {
        setParticipants(selectedEvent?.participants || []);
    }, [selectedEvent?.participants])

    useEffect(() => {
        setSelectedService(selectedEvent?.service ? [selectedEvent?.service] : [])
    }, [selectedEvent?.service, open])

    useEffect(() => {
        setSelectedCoach(selectedEvent?.coach ? [selectedEvent?.coach] : [])
    }, [selectedEvent?.coach, open])

    useEffect(() => {
        setSelectedProgram(selectedEvent?.program ? [selectedEvent?.program] : [])
    }, [selectedEvent?.program, open])

    const handleEventChange = (field) => (e) => {
        setSelectedEvent({ ...selectedEvent, [field]: e.target.value });
    };
    const handleDelete = (ev, e) => {
        e.preventDefault();
        setOpen(false);
        handleProgramDelete(selectedEvent)
    }

    const saveEvent = (e) => {
        e.preventDefault();

        const toSave = {
            id: selectedEvent?.id,
            serviceId: selectedService[0]?.id || null,
            programId: selectedProgram[0]?.id || null,
            coachId: selectedCoach[0]?.id || null,
            title: selectedEvent.title,
            date: selectedEvent.date,
            startTime: selectedEvent.startTime,
            endTime: selectedEvent.endTime,
            guests: selectedEvent.guests || [],
            participantsIds: participants.map(par => par.id)
        }

        if (!selectedEvent?.id)
            delete toSave.id;

        const action = selectedEvent.id ? updateEvent : addEvent;
        setloading(true);
        dispatch(action(toSave))
            .then(() => {
                setOpen(false);
                ; handleProgramAddSuccess(toSave.title)
            })
            .catch(() => {
                setOpen(false);
                handleProgramAddFail(toSave.title)
            })
            .finally(() => {
                setloading(false);
                setOpen(false);
                setSelectedEvent({
                    title: '',
                    date: '',
                    start: '',
                    end: ''
                })
            })
        e.target.reset();
    }

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
        >
            <Box sx={{ ...event_modal_style }}>
                <Stack >
                    <Backdrop open={loading} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}><CircularProgress color="secondary" /></Backdrop>
                </Stack>
                <div className="contact-form--1">
                    <div className="container">
                        <form onSubmit={saveEvent}>
                            <div className="rn-form-group">
                                <div className="section-title text-left mb--50">
                                    <h2 className="title" style={{ fontSize: 20, textAlign: 'center', paddingTop: 10 }}>{selectedEvent.title}</h2>
                                </div>
                                <Stepper nonLinear activeStep={activeStep}>
                                    {steps.map((label, index) => (
                                        <Step key={label} completed={completed[index]}>
                                            <StepButton color="inherit" onClick={handleStep(index)}>
                                                {label}
                                            </StepButton>
                                        </Step>
                                    ))}
                                </Stepper>
                                <br />
                                <div className="row row--35 align-items-start">
                                    <div className="col-lg-12 order-1 order-lg-1">
                                        {(activeStep === 0) && <div className="form-wrapper">
                                            <div className="form-group">
                                                <label className="col-form-label" htmlFor="title"><b>TITRE:{' '} </b></label>
                                                <input
                                                    type="text"
                                                    name="title"
                                                    id='title'
                                                    disabled={onlyUserChange}
                                                    value={selectedEvent.title}
                                                    onChange={handleEventChange('title')}
                                                    placeholder="Titre"
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label className="col-form-label" htmlFor="service"><b>Atelier/soin:{' '} </b></label>
                                                <AsyncTypeahead
                                                    filterBy={() => true}
                                                    id="service"
                                                    disabled={onlyUserChange}
                                                    isLoading={isLoading}
                                                    labelKey="title"
                                                    onChange={setSelectedService}
                                                    defaultSelected={selectedService}
                                                    minLength={3}
                                                    onSearch={setServiceSearchText}
                                                    options={serviceOptions}
                                                    placeholder="services..."
                                                    renderMenuItemChildren={(option) => (
                                                        <>
                                                            <img
                                                                src={`${API_URL}images/service/${option.image}`}
                                                                style={{
                                                                    height: '24px',
                                                                    marginRight: '10px',
                                                                    width: '24px',
                                                                }}
                                                            />
                                                            <span>{option.title}</span>
                                                        </>
                                                    )}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label className="col-form-label" htmlFor="program"><b>Evénement:{' '} </b></label>
                                                <AsyncTypeahead
                                                    filterBy={() => true}
                                                    id="program"
                                                    labelKey="title"
                                                    disabled={onlyUserChange}
                                                    onChange={setSelectedProgram}
                                                    defaultSelected={selectedProgram}
                                                    minLength={3}
                                                    onSearch={setProgramSearchText}
                                                    options={programOptions}
                                                    placeholder="événements..."
                                                    renderMenuItemChildren={(option) => (
                                                        <>
                                                            <img
                                                                src={`${API_URL}images/program/${option.image}`}
                                                                style={{
                                                                    height: '24px',
                                                                    marginRight: '10px',
                                                                    width: '24px',
                                                                }}
                                                            />
                                                            <span>{option.title}</span>
                                                        </>
                                                    )}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label className="col-form-label" htmlFor="service"><b>Coach:{' '} </b></label>
                                                <AsyncTypeahead
                                                    filterBy={() => true}
                                                    disabled={onlyUserChange}
                                                    id="coach"
                                                    labelKey="firstname"
                                                    onChange={setSelectedCoach}
                                                    defaultSelected={selectedCoach}
                                                    minLength={3}
                                                    onSearch={setCoachSearchText}
                                                    options={coachOptions}
                                                    placeholder="coach..."
                                                    renderMenuItemChildren={(option) => (
                                                        <>
                                                            <img
                                                                src={`${API_URL}images/coach/${option.image}`}
                                                                style={{
                                                                    height: '24px',
                                                                    marginRight: '10px',
                                                                    width: '24px',
                                                                }}
                                                            />
                                                            <span>{`${option.firstname} ${option.lastname}`}</span>
                                                        </>
                                                    )}
                                                />
                                            </div>
                                        </div>}
                                        {(activeStep === 1) && <div className="form-wrapper">

                                            <div className="form-group">
                                                <label className="col-form-label" >
                                                    <b style={selectedService[0]?.capacity && selectedService[0]?.capacity < participants.length ? { color: 'red' } : {}}>
                                                        {selectedService[0]?.capacity ? `Participants:  Total : ${participants.length + (selectedEvent.guests || []).length},  Max autorisé: ${selectedService[0]?.capacity} ` :
                                                            `Participants:  Total : ${participants.length + (selectedEvent.guests || []).length}`}
                                                    </b>
                                                </label>
                                                <label className="col-form-label" htmlFor="guests"><b>Clients:{' '} </b></label>
                                                <PickList
                                                    options={clientOptions}
                                                    selectedOptions={participants}
                                                    onSelect={({ item }) => {
                                                        setParticipants([...participants, item])
                                                    }}
                                                    onRemove={({ item }) => {
                                                        setParticipants([...participants.filter(i => i.id !== item.id)])
                                                    }}
                                                    onSearch={setClientSearchText}
                                                    displayedFields={['firstname', 'lastname', 'phone']}

                                                    onRemoveAll={() => setParticipants([])}
                                                    onSort={({ sortedList }) =>
                                                        setParticipants([...sortedList.map(i => i.value)])
                                                    }
                                                />

                                            </div>

                                            <div className="form-group">
                                                <label className="col-form-label" htmlFor="guests"><b>Invités:{' '} </b></label>
                                                <input
                                                    type="text"
                                                    name="guests"
                                                    id='guests'
                                                    disabled={onlyUserChange}
                                                    onBlur={handleAddGuest}
                                                    placeholder="Invités"
                                                />
                                            </div>
                                            <div style={{ maxHeight: 80, overflow: 'auto' }}>
                                                <Grid container spacing={1}>

                                                    {(selectedEvent.guests || []).map((str, index) =>
                                                        <Grid item xs={4} key={`grid${index}`} >

                                                            <Chip label={str} variant="outlined" onDelete={() => handleDeleteGuest(str)} />
                                                        </Grid>
                                                    )}
                                                </Grid>

                                            </div>

                                        </div>}
                                        {(activeStep === 2) && <div className="form-wrapper">
                                            <div className="form-group">
                                                <label className="col-form-label" htmlFor="date"><b>Date:{' '} </b></label>
                                                <input
                                                    type="date"
                                                    name="date"
                                                    id='date'
                                                    disabled={onlyUserChange}
                                                    value={selectedEvent.date}
                                                    onChange={handleEventChange('date')}
                                                    placeholder="Date"
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label className="col-form-label" htmlFor="startTime"><b>Début:{' '} </b></label>
                                                <input
                                                    type="time"
                                                    name="startTime"
                                                    id='startTime'
                                                    disabled={onlyUserChange}
                                                    value={selectedEvent.startTime}
                                                    onChange={handleEventChange('startTime')}
                                                    placeholder="Début"
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label className="col-form-label" htmlFor="endTime"><b>Fin:{' '} </b></label>
                                                <input
                                                    type="time"
                                                    name="endTime"
                                                    id='endTime'
                                                    disabled={onlyUserChange}
                                                    value={selectedEvent.endTime}
                                                    onChange={handleEventChange('endTime')}
                                                    placeholder="Fin"
                                                    required
                                                />
                                            </div>


                                        </div>}
                                    </div>
                                    <div className="col-lg-12 order-2 order-lg-2" style={{ position: 'fixed', top: '600px' }}>
                                        <div className="section-title text-center mt--20">
                                            <button
                                                className="rn-button-style--2 btn-solid"
                                                type="submit"
                                                value="submit"
                                                name="submit"
                                                disabled={!selectedEvent.title || !selectedEvent.date || !selectedEvent.startTime || !selectedEvent.endTime}
                                                id="mc-embedded-subscribe">Valider
                                            </button>
                                            {'   '}
                                            {selectedEvent.id && !onlyUserChange && <button
                                                className="rn-button-style--2 btn-solid"
                                                onClick={(e) => handleDelete(selectedEvent, e)}
                                                value="delete"
                                                name="delete"
                                                id="mc-embedded-delete">Supprimer
                                            </button>
                                            }
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Box >
        </Modal >

    );
}

export default EventEdit