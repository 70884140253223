import React, { Fragment, useEffect, useState } from 'react';


import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from 'react-icons/fi';
import PageHelmet from 'component/common/Helmet';
import Header from 'component/header/Header';
import { useDispatch } from 'react-redux';
import { API_URL } from 'utils/constantes';
import FooterTwo from 'component/footer/FooterTwo';
import HeaderDetails from '../header/header.details';
import { getProductById, getProductPacks } from 'state/actions/products';
import Note from '../common/note';


const ProductDetails = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    const { id } = location.state || '';
    const [packs, setPacks] = useState([])

    const [product, setProduct] = useState({
        createdAt: '',
        price: '',
        fullDescription: '',
        image: '',
        title: '',
        updatedAt: '',
    })

    useEffect(() => {
        if (!id) {
            history.replace('/');
            return;
        }
        dispatch(getProductById(id)).then(({ data, status }) => {
            if (status === 200) {
                const {
                    createdAt,
                    fullDescription,
                    image,
                    title,
                    price,
                    category,
                    updatedAt,
                } = data
                setProduct({
                    ...product,
                    createdAt,
                    fullDescription,
                    image,
                    title,
                    price,
                    category,
                    updatedAt,
                })
            }

        })

        dispatch(getProductPacks(id)).then(({ data, status }) => {
            if (status === 200) {
                setPacks(data)
            }
        })

    }, [id])

    return (
        <Fragment>
            <PageHelmet pageTitle='Produit' />
            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
            <HeaderDetails
                title={`${product.title}`}
                items={[
                    { label: product.price ? `${product.price} dt` : '', id: 1, icon: 'BiMoney' },
                    { label: product.category ? product.category : '', id: 2, icon: 'BiInfoCircle' },
                ]}
            />
            {/* Start Blog Details */}
            <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inner-wrapper">
                                <div className="inner">
                                    <p dangerouslySetInnerHTML={{ __html: product.fullDescription }}></p>
                                    {packs.length > 0 && <>

                                        <h4 className="title">Packs liées:</h4>
                                        <ul className="list-style">

                                            {packs.map(pack => (<li key={pack.id}>
                                                <Link className='client-link' to={`/pack/${pack.id}`}>{`${pack.title} `}</Link>{` à ${pack.price - pack.sale} dt`}
                                            </li>))}
                                        </ul>
                                    </>}

                                    <Note />

                                    <div className="video-wrapper position-relative mb--40">
                                        <div className="thumbnail" style={{ textAlign: 'center' }} >
                                            {product.title && <img className="w-50" src={product.image ? `${API_URL}images/product/${product.image}` : '/assets/images/default.jpg'} alt={product.title} />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Blog Details */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            <FooterTwo />

        </Fragment>
    )
}
export default ProductDetails;