/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import HeaderNav from './header.nav';

const HeaderFive = ({ color, headerPosition }) => {

  useEffect(() => {
    const elements = document.querySelectorAll('.has-droupdown > a');
    for (let i = 0; i < elements.length; i++) {
      elements[i].onclick = function () {
        this.parentElement.querySelector('.submenu').classList.toggle('active');
        this.classList.toggle('open');
      }
    }

    const loadListener = () => {
      console.log('All assets are loaded');
    }
    window.addEventListener('load', loadListener);

    return () => {
      window.removeEventListener('load', loadListener);
    };
  }, []);

  const logoUrl = <img src="/assets/images/logo/logo-all-dark.png" alt="Trydo" />;

  return (
    <header className={`header-area formobile-menu ${headerPosition} ${color}`}>
      <div className="header-wrapper" id="header-wrapper">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-3 col-md-4 col-6">
              <div className="header-left">
                <div className="logo">
                  <Link to="/">
                    {logoUrl}
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-md-8 col-6">
              <div className="header-right justify-content-end">
                <HeaderNav />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header >
  );
};

export default HeaderFive;
