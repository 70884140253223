import { Box, Modal } from '@mui/material';
import moment from 'moment';
import React from 'react'
import 'moment/locale/fr'  // without this line it didn't work
import { FiStar } from 'react-icons/fi';
import { yellow } from '@mui/material/colors';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '430px',
    height: '230px',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

const UserEvent = (props) => {
    moment.locale('fr');

    const { open, setOpen, selectedEvent } = props;


    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
        >
            <Box sx={{ ...style }}>
                <div className="contact-form--1">
                    <div className="container">
                        <div className="section-title text-left mb--20">
                            <h2 className="title" style={{ fontSize: 20, textAlign: 'center', textTransform: 'uppercase', paddingTop: 10 }}>{selectedEvent?.title}</h2>
                            {selectedEvent?.program && ((<><i style={{ fontSize: 12 }}>{selectedEvent?.program?.title}</i><FiStar style={{ color: yellow[500] }} title={selectedEvent?.program?.title} /></>))}
                        </div>
                        <div className="row row--20 align-items-start">
                            <div className="col-lg-12 order-1 order-lg-1">
                                {selectedEvent?.service && (<div className="form-group">
                                    <label className="col-form-label" htmlFor="service"><b>Atelier/Soin:{' '} </b>  {selectedEvent?.service?.title}</label>

                                </div>)}
                                {selectedEvent?.coach && (<div className="form-group">
                                    <label className="col-form-label" htmlFor="service"><b>Coach:{' '} </b>  {selectedEvent?.coach?.firstname}</label>

                                </div>)}
                                <div className="form-wrapper">
                                    <div className="form-group">
                                        <label className="col-form-label" htmlFor="date"><b>Date:{' '} </b>Le {selectedEvent?.date}, de  {moment(selectedEvent?.startTime, 'HH:mm:ss').format('HH:mm')} au  {moment(selectedEvent?.endTime, 'HH:mm:ss').format('HH:mm')}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Box >
        </Modal >

    );
}

export default UserEvent