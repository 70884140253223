import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Pagination } from 'elements/common/Pagination';
import { formatDate, formatDuration, formatTime } from 'utils/dateutils';
import { WORKSHOP } from 'utils/constantes';


const ListViewItems = (props) => {
    const { types, generateLink, imgLink, fetchAction } = props
    const dispatch = useDispatch();
    const [data, setData] = useState({ rows: [], count: 0 });
    const [pageable, setPageable] = useState({
        page: 0,
        rowsPerPage: 5,
        order: 'desc',
        orderBy: 'updatedAt',
        types,
        publicOnly: true,
        fullVersion: types?.includes(WORKSHOP) && types?.length === 1 // pour les ateliers recupérer les prochaines séances
    })
    const fetchData = (pageable) => (dispatch(fetchAction(pageable)));
    const onPageChange = (currentPage) => {
        setPageable({ ...pageable, page: currentPage - 1 })
    }
    useEffect(() => {
        const fetch = async () => {
            try {
                const { data: apiData, status } = await fetchData(pageable);
                if (status === 200) {
                    const { rows, count } = apiData;
                    setData((oldState) => ({ ...oldState, rows, count }));
                }
            } catch (e) {
                console.log(e);
            }
        };
        fetch();
    }, [pageable.page, pageable.types]);


    return (
        <div className="rn-blog-area ptb--200 bg_color--1">
            <div className="container">
                {data.rows?.map((value, i) => (
                    <div className="row" key={i + 'row'} style={{ overflow: 'hidden', marginBottom: 20 }}>

                        <div className="col-lg-3 col-md-6 col-sm-12 col-12" key={i} >
                            <div className="blog blog-style--75">
                                <div className="thumbnail">
                                    <span >
                                        <img className="w-100" src={imgLink(value)} alt={value.title} />
                                    </span>
                                    <div className="content">
                                        <div className="blog-btn">
                                            <Link className="rn-btn text-white" to={generateLink(value)} >Plus</Link>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-9 col-md-6 col-sm-12 col-12"
                            style={{
                                fontSize: 16,
                                color: 'rgba(29, 29, 36, .75)',
                            }} key={i}

                        >
                            <div  >
                                <h4  >
                                    <Link style={{ fontSize: 20, textTransform: 'uppercase' }} className='client-link' to={generateLink(value)} > {value.title}</Link>
                                </h4>
                                {value.duration && <div style={{ fontSize: 12 }}>Durée: {formatDuration(value.duration)}</div>}
                                {value.events?.length > 0 &&
                                    <div id={`next_${i}`} style={{ fontSize: 12 }}>
                                        prochaine séance: {`${formatDate(value.events[0].date)} à ${formatTime(value.events[0].startTime)} `}
                                    </div>
                                }

                            </div>
                            <div style={{ paddingTop: 15, overflow: 'hidden' }} >
                                {value.summary}
                            </div>
                        </div>
                    </div>

                ))}
                {data.rows?.length > 0 && <div className="row mt--20">
                    <div className="col-lg-12">
                        {/* Start Pagination Area */}
                        <Pagination nbPages={Math.ceil(data.count / pageable.rowsPerPage)} currentPage={pageable.page + 1} onPageChange={onPageChange} />
                        {/* End Pagination Area */}
                    </div>
                </div>}
            </div>
        </div >
    )

}

export default ListViewItems;