// React Required
import React from 'react';

// Create Import File

import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { ReactKeycloakProvider, } from '@react-keycloak/web';

import keycloakConfig from 'utils/keycloak';

import Dashboard from 'backoffice/dashboard';
import UsersDashboard from 'backoffice/users/users.dashboard';
import PageScrollTop from './component/PageScrollTop';

// Home layout
import CorporateBusiness from './home/CorporateBusiness';

// Element Layout
import Contact from './elements/Contact';
import error404 from './elements/error404';

// Blocks Layout

import Team from './blocks/Team';
import Gallery from './blocks/Gallery';
import PrivateRoute from './utils/privateRoute';
import { ADMIN } from 'utils/constantes';
import EventDashboard from 'backoffice/event/dashboard';
import GalleryDashboard from 'backoffice/gallery/dashboard';
import CoachDashboard from 'backoffice/coachs/dashboard';
import ProductDashboard from 'backoffice/products/dashboard';
import PackDashboard from 'backoffice/packs/dashboard';
import ServiceDashboard from 'backoffice/services/dashboard';
import ProgramDashboard from 'backoffice/programs/dashboard';
import WorkshopDetails from 'component/workshop/workshop.details';
import PackDetails from 'component/pack/pack.details';
import ProgramDetails from 'component/program/program.detail';
import CareDetails from 'component/care/care.details';
import WorkShopList from 'component/workshop/workshop.list';
import CareList from 'component/care/care.list';
import ProductDetails from 'component/product/product.details';
import ProductListComponent from 'component/product/product.list';
import ProgramListComponent from 'component/program/program.list';
import CalendarContainer from 'elements/calendar/calendar.container';
import PackListComponent from 'component/pack/pack.list';
import RequestDashboard from 'backoffice/requests/dashboard';
import ClientCalendarDashboard from 'client/calendar/dashboard';
import ClientRequestDashboard from 'client/requests/dashboard';
import GenericRedirect from 'utils/generic-redirect';

const App = () => {
  return (
    < ReactKeycloakProvider authClient={keycloakConfig} >
      <BrowserRouter basename="/">
        <PageScrollTop>
          <Switch>
            <Route exact path={`/`} component={CorporateBusiness} />

            {/* Element Layot */}
            <Route path={`/contact`} component={Contact} />


            {/* Blocks Elements  */}
            <Route path={`/team`} component={Team} />
            <Route path={`/gallery`} component={Gallery} />

            <Route path={`/workshop/:id`} component={GenericRedirect(WorkshopDetails, '/workshop')} />
            <Route path={`/workshop`} component={WorkshopDetails} />

            <Route path={`/care/:id`} component={GenericRedirect(CareDetails, '/care')} />
            <Route path={`/care`} component={CareDetails} />

            <Route path={`/pack/:id`} component={GenericRedirect(PackDetails, '/pack')} />
            <Route path={`/pack`} component={PackDetails} />

            <Route path={`/product/:id`} component={GenericRedirect(ProductDetails, '/product')} />
            <Route path={`/product`} component={ProductDetails} />


            <Route path={`/event/:id`} component={GenericRedirect(ProgramDetails, '/event')} />
            <Route path={`/event`} component={ProgramDetails} />

            <Route path={`/workshops`} component={WorkShopList} />
            <Route path={`/cares`} component={CareList} />
            <Route path={`/products`} component={ProductListComponent} />
            <Route path={`/events`} component={ProgramListComponent} />
            <Route path={`/calendar`} component={CalendarContainer} />
            <Route path={`/packs`} component={PackListComponent} />
            <Route path={`/client/calendar`} component={ClientCalendarDashboard} />
            <Route path={`/client/requests`} component={ClientRequestDashboard} />


            <PrivateRoute path="/admin/dashboard" roles={[ADMIN]}>
              <Dashboard />
            </PrivateRoute>
            <PrivateRoute path="/admin/users" roles={[ADMIN]} keycloak>
              <UsersDashboard />
            </PrivateRoute>
            <PrivateRoute path="/admin/services" roles={[ADMIN]} keycloak>
              <ServiceDashboard />
            </PrivateRoute>
            <PrivateRoute path="/admin/calendar" roles={[ADMIN]} keycloak>
              <EventDashboard />
            </PrivateRoute>
            <PrivateRoute path="/admin/gallery" roles={[ADMIN]} keycloak>
              <GalleryDashboard />
            </PrivateRoute>
            <PrivateRoute path="/admin/coachs" roles={[ADMIN]} keycloak>
              <CoachDashboard />
            </PrivateRoute>
            <PrivateRoute path="/admin/products" roles={[ADMIN]} keycloak>
              <ProductDashboard />
            </PrivateRoute>
            <PrivateRoute path="/admin/packs" roles={[ADMIN]} keycloak>
              <PackDashboard />
            </PrivateRoute>
            <PrivateRoute path="/admin/programs" roles={[ADMIN]} keycloak>
              <ProgramDashboard />
            </PrivateRoute>
            <PrivateRoute path="/admin/requests" roles={[ADMIN]} keycloak>
              <RequestDashboard />
            </PrivateRoute>
            {/* <Route path={`${process.env.PUBLIC_URL}/404`} component={error404} />
            <Route component={error404} /> */}

          </Switch>
        </PageScrollTop>
      </BrowserRouter>
    </ReactKeycloakProvider >
  )
};

export default App;
