import React from 'react';
import PageHelmet from '../component/common/Helmet';
import { FiHeadphones, FiMail, FiMapPin } from 'react-icons/fi';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from 'react-icons/fi';
import Header from '../component/header/Header';
import FooterTwo from 'component/footer/FooterTwo';


const Contact = () => {

    return (
        <React.Fragment >
            <PageHelmet pageTitle='Contact' />

            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

            {/* Start Breadcrump Area */}
            <div className="rn-page-title-area ptb--80 bg_image bg_image--17" data-black-overlay="7">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="rn-page-title text-center pt--100">
                                <h2 className="title theme-gradient">Nous contacter</h2>
                                <p>Kayani est un centre de bien-être qui réunit des thérapeutes de différentes disciplines. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Breadcrump Area */}


            {/* Start Contact Top Area  */}
            <div className="rn-contact-top-area ptb--120 bg_color--5">
                <div className="container">

                    <div className="row">
                        {/* Start Single Address  */}
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="rn-address">
                                <div className="icon">
                                    <FiHeadphones />
                                </div>
                                <div className="inner">
                                    <h4 className="title">Par mobile</h4>
                                    <p><a href="tel:+216 58 733 233">+216 58 733 233</a></p>
                                </div>
                            </div>
                        </div>
                        {/* End Single Address  */}

                        {/* Start Single Address  */}
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
                            <div className="rn-address">
                                <div className="icon">
                                    <FiMail />
                                </div>
                                <div className="inner">
                                    <h4 className="title">Adresse email</h4>
                                    <p>kayani.therapy@gmail.com</p>
                                </div>
                            </div>
                        </div>
                        {/* End Single Address  */}

                        {/* Start Single Address  */}
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                            <div className="rn-address">
                                <div className="icon">
                                    <FiMapPin />
                                </div>
                                <div className="inner">
                                    <h4 className="title">Location</h4>
                                    <p>Immeuble Chebil Centre, Bloc B25, 2ème étage.<br /> Avenue Farhat Hached - Sidi Rezig Mégrine, Tunisie</p>
                                </div>
                            </div>
                        </div>
                        {/* End Single Address  */}
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%', alignItems: 'center', marginTop: 80 }}>
                    <img src='/assets/images/map_kayani.png' alt="img" />

                    {/* <GoogleMapReact
                        defaultCenter={defaultProps.center}
                        defaultZoom={defaultProps.zoom}
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
                        bootstrapURLKeys={{ key: 'AIzaSyDkuERRE_XQwrAEly28V_9ddBdJAKoEyzI' }}
                    >
                    </GoogleMapReact> */}
                </div>
            </div>
            {/* End Contact Top Area  */}

            {/* Start Contact Map  */}
            {/* <div className="rn-contact-map-area position-relative"> */}

            {/* </div> */}
            {/* End Contact Map  */}



            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            <FooterTwo />

        </React.Fragment >
    )
}
export default Contact