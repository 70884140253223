import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { API_URL } from 'utils/constantes';

const HomeEvents = (props) => {
    const { items: programs } = props;
    const [gridClass, setGridClass] = useState('col-lg-4 col-md-4 mt--30');
    useEffect(() => {
        switch (programs.length) {
            case 1:
                setGridClass('col-lg-6 col-md-6 mt--30');
                break;
            case 2:
                setGridClass('col-lg-6 col-md-6 mt--30');
                break;
            case 3:
                setGridClass('col-lg-4 col-md-4 mt--30');
                break;


        }
    }, [programs?.length])


    return (
        < div className="rn-featured-service-area pt--90 pb--120 bg_color--5" >
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title service-style--3 text-center mb--25 mb_sm--0">
                            <Link to='/events' ><h2 className="title"> NOS ÉVÉNEMENTS</h2></Link>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {programs.map((value, index) => (
                        <div className={gridClass} key={index}>

                            <div className="portfolio" key={index}>
                                <div className="thumbnail-inner">
                                    <div className={'thumbnail'}>
                                        <img className='bgImage' src={`${API_URL}images/program/${value.image}`} />
                                    </div>
                                    <div className={'bg-blr-image'}></div>
                                </div>
                                <div className="content">
                                    <div className="inner">
                                        <p>{value.category}</p>
                                        <h4><Link to={`/event/${value.id}`}>{value.title}</Link></h4>
                                        <div className="portfolio-button">
                                            <Link className="rn-btn" to={`/event/${value.id}`}>Plus</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

            </div>
            {/* End Single Service  */}

        </div>
    )
}

export default HomeEvents