import React from 'react'
import { FiMenu, FiX } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import KeycloakComponent from './keycloak'

const HeaderNav = () => {
    const menuTrigger = () => {
        document.querySelector('.header-wrapper').classList.toggle('menu-open');
    }

    const closeMenuTrigger = () => {
        document.querySelector('.header-wrapper').classList.remove('menu-open');
    }

    return (
        <>
            <nav className="mainmenunav d-lg-block">
                <ul className="mainmenu">
                    <li className="has-droupdown">
                        <Link to="#">Découvrir</Link>
                        <ul className="submenu">
                            <li><Link to="/workshops">Nos ateliers</Link></li>
                            <li><Link to="/cares">Nos soins</Link></li>
                            <li><Link to="/products">Nos produits</Link></li>
                        </ul>
                    </li>
                    <li><Link to="/packs">Packs</Link></li>
                    <li><Link to="/calendar">Planning</Link></li>
                    <li><Link to="/events">Événements</Link></li>

                    <li className="has-droupdown">
                        <Link to="#">A propos</Link>
                        <ul className="submenu">
                            <li><Link to="/team">Notre équipe</Link></li>
                            <li><Link to="/contact">Nous contacter</Link></li>
                        </ul>
                    </li>
                    <KeycloakComponent />
                </ul>
            </nav>
            <div className="header-btn">
            </div>
            {/* Start Humberger Menu  */}
            <div className="humberger-menu d-block d-lg-none pl--20">
                <span onClick={menuTrigger} className="menutrigger text-white"><FiMenu /></span>
            </div>
            {/* End Humberger Menu  */}
            <div className="close-menu d-block d-lg-none">
                <span onClick={closeMenuTrigger} className="closeTrigger"><FiX /></span>
            </div>

        </>
    )
}

export default HeaderNav