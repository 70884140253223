import React, { Fragment } from 'react';


import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from 'react-icons/fi';
import FooterTwo from 'component/footer/FooterTwo';
import PageHelmet from 'component/common/Helmet';
import Header from 'component/header/Header';
import Breadcrumb from 'elements/common/Breadcrumb';
import { API_URL } from 'utils/constantes';
import GridEight from 'elements/gridList/grid.front';
import { fetchOnlyPublicProducts } from 'state/actions/products';


const ProductListComponent = () => {
    const generateLink = (item) => `/product/${item.id}`;
    const imgLink = (value) => value.image ? `${API_URL}images/product/${value.image}` : '/assets/images/default.jpg'

    return (
        <Fragment>
            <PageHelmet pageTitle='products' />

            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
            {/* Start Breadcrump Area */}
            <Breadcrumb title={'Produits'} />
            {/* End Breadcrump Area */}

            <GridEight
                fetchAction={fetchOnlyPublicProducts}
                generateLink={generateLink}
                imgLink={imgLink}
            />



            {/* End Blog Area */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            <FooterTwo />

        </Fragment >
    )

}

export default ProductListComponent;