import axios from 'axios';
import Swal from 'sweetalert2';
import { API_URL } from 'utils/constantes';

const instance = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use(
  (req) => {
    const loginToken = localStorage.getItem('jwtToken') || 'dummy_token';
    if (loginToken) { req.headers.Authorization = `Bearer ${loginToken}`; }
    req.headers['Access-Control-Allow-Origin'] = '*';
    return req;
  },
  (err) => Promise.reject(err),
);

instance.interceptors.response.use(
  (res) => {
    // Add configurations here
    if (res.status === 201) {
      console.log('Posted Successfully');
    }
    return res;
  },
  (err) => {
    if (err.response?.status === 403) {
      Swal.fire({
        icon: 'error',
        title: 'API ERROR',
        text: 'Non authorisé',
      });
    }
    return Promise.reject(err);
  },
);

export default instance;
