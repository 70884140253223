import React from 'react';
import {
    FiChevronUp,
} from 'react-icons/fi';
import ScrollToTop from 'react-scroll-up';
import PageHelmet from 'component/common/Helmet';
import Header from 'component/header/Header';
import FooterTwo from 'component/footer/FooterTwo';
import Breadcrumb from 'elements/common/Breadcrumb';
import Layout from '../layout';
import ServiceList from './service.list';

const ServiceDashboard = () => (
    <>
        <PageHelmet pageTitle="Services" />

        {/* Start Header Area  */}
        <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
        {/* End Header Area  */}
        {/* Start Breadcrump Area */}
        <Breadcrumb title="Services" />
        {/* End Breadcrump Area */}

        {/* Start Service Area */}
        <Layout>
            <ServiceList />
        </Layout>
        {/* End Service Area */}

        {/* Start Back To Top */}
        <div className="backto-top">
            <ScrollToTop showUnder={160}>
                <FiChevronUp />
            </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <FooterTwo />

    </>
);
export default ServiceDashboard;
