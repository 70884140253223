import React, { Fragment } from 'react';


import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from 'react-icons/fi';
import FooterTwo from 'component/footer/FooterTwo';
import PageHelmet from 'component/common/Helmet';
import Header from 'component/header/Header';
import Calendar from './calendar';
import Breadcrumb from 'elements/common/Breadcrumb';





const CalendarContainer = () => {
    return (
        <Fragment>
            <PageHelmet pageTitle='calendrier' />

            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
            <Breadcrumb title={'Planning'} />

            <div className="rn-blog-area ptb--120 bg_color--1">
                <div className="container">
                    <Calendar />
                </div>
            </div>
            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            <FooterTwo />

        </Fragment >
    )

}

export default CalendarContainer;