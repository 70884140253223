import React from 'react';
import { Link } from 'react-router-dom';
import { FaAngleRight } from 'react-icons/fa';

export const Pagination = (props) => {
    const { nbPages, currentPage, onPageChange } = props;

    const onPageClick = (id) => { onPageChange(id) }
    const pageRender = (nb, current) => {
        if (current === 1)
            return (<>
                <li className="active"><Link to="#">1</Link></li>
                {current + 1 <= nb && (<li onClick={() => onPageClick(current + 1)}> <Link to="#">{current + 1}</Link></li>)}
                {current + 2 <= nb && (<li onClick={() => onPageClick(current + 1)}><Link to="#">{current + 2}</Link></li>)}
            </>
            )
        else
            return (<>
                <li onClick={() => onPageClick(current - 1)} ><Link to="#">{current - 1}</Link></li>
                <li className="active"><Link to="#">{current}</Link></li>
                {current + 1 <= nb && (<li onClick={() => onPageClick(current + 1)}><Link to="#">{current + 1}</Link></li>)}
            </>
            )

    }
    return (
        <div className="rn-pagination text-center">
            <ul className="page-list">
                {pageRender(nbPages, currentPage)}
                <li onClick={() => onPageClick(nbPages)}><Link to="#"><FaAngleRight /></Link></li>
            </ul>
        </div>
    )
}