import React, { Fragment, useEffect, useState } from 'react';


import { useHistory, useLocation, useParams } from 'react-router-dom';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from 'react-icons/fi';
import PageHelmet from 'component/common/Helmet';
import Header from 'component/header/Header';
import { useDispatch } from 'react-redux';
import { API_URL } from 'utils/constantes';
import FooterTwo from 'component/footer/FooterTwo';
import { getNextEventsByProgram, getProgramById } from 'state/actions/program';
import Note from '../common/note';
import HeaderDetails from 'component/header/header.details';
import moment from 'moment';


const ProgramDetails = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const { id } = location.state || '';
    const [nextSessions, setNextSessions] = useState([])
    const [program, setProgram] = useState({
        createdAt: '',
        shortDescription: '',
        fullDescription: '',
        image: '',
        title: '',
        updatedAt: '',
    })
    useEffect(() => {
        if (!id) {
            history.replace('/');
            return;
        }
        dispatch(getProgramById(id)).then(({ data, status }) => {
            if (status === 200) {
                const {
                    createdAt,
                    shortDescription,
                    fullDescription,
                    image,
                    title,
                    updatedAt,
                } = data
                setProgram({
                    ...program,
                    createdAt,
                    shortDescription,
                    fullDescription,
                    image,
                    title,
                    updatedAt,
                })
            }
        })

        dispatch(getNextEventsByProgram(id)).then(({ data, status }) => {
            if (status === 200) {
                setNextSessions(data)
            }
        })
    }, [id])

    const formatDate = (date) => {
        const tmp = moment(date); // Analyse la chaîne de caractères en tant qu'objet Moment
        return tmp.format('dddd D MMMM YYYY'); // Récupère le jour de la semaine, le mois et l'année en chaine
    }

    const formatTime = (time) => {
        return moment(time, 'HH:mm:ss').format('HH[h]mm');
    }
    return (
        <Fragment>
            <PageHelmet pageTitle='Soin' />
            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

            <HeaderDetails
                title={`${program.title}`}
                items={[]}
            />
            <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inner-wrapper">
                                <div className="inner">
                                    <p dangerouslySetInnerHTML={{ __html: program.fullDescription }}></p>
                                    <br />
                                    {nextSessions.length > 0 && <>
                                        <h4 className="title">Prochaines séances:</h4>
                                        <ul className="list-style">
                                            {
                                                nextSessions.map(ev => (
                                                    <li key={ev.id}>
                                                        {/* {ev.service && <> Activitée : <Link className='client-link' to={`${ev.service.type === WORKSHOP ? '/workshop/' : '/care/'}${ev.service.id}`}>{`${ev.service.title} `}</Link>,</>} */}
                                                        <strong>{ev.title} </strong>
                                                        {`${formatDate(ev.date)} de ${formatTime(ev.startTime)} à ${formatTime(ev.endTime)}`}
                                                        <strong style={{ fontSize: 12, color: '#397721' }}>{ev?.coach && ` (coach ${ev.coach.firstname})`}</strong>
                                                    </li>))
                                            }
                                        </ul>

                                    </>
                                    }
                                    <Note />

                                    <div className="video-wrapper position-relative mb--40">
                                        <div className="thumbnail" style={{ textAlign: 'center' }} >
                                            {program.title && <img className="w-50" src={program.image ? `${API_URL}images/program/${program.image}` : '/assets/images/default.jpg'} alt={program.title} />}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Blog Details */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            <FooterTwo />

        </Fragment>
    )
}
export default ProgramDetails;