import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import { ServiceState } from './slices/serviceState';
import { userState } from './slices/userState';
import { adminState } from './slices/adminState';
// store.js
const persistConfig = {
  key: 'user',
  storage,
};

const reducers = combineReducers({
  service: ServiceState.reducer,
  user: userState.reducer,
  admin: adminState.reducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});
