import React, { useEffect, useState } from 'react';
import ScrollToTop from 'react-scroll-up';
import Slider from 'react-slick';
import {
  FiClock, FiChevronUp, FiCheck, FiCalendar, FiPackage,
} from 'react-icons/fi';
import { slideSlick } from '../page-demo/script';

import Header from '../component/header/HeaderFive';
import FooterTwo from '../component/footer/FooterTwo';
import Helmet from '../component/common/Helmet';

import HomeEvents from './events';
import HomeActivities from './activities';
import { Link } from 'react-router-dom';
import HomeAbout from './about';
import HomePricing from './pricing';
import { CARE, WORKSHOP } from 'utils/constantes';
import { fetchPublicOnlyServices } from 'state/actions/service';
import { useDispatch } from 'react-redux';
import HomeCares from './cares';
import { fetchOnlyPublicPrograms } from 'state/actions/program';
const namesItemOne = [
  'The Philosophy Of business analytics',
  'Fast-Track Your business',
  'Lies And Damn Lies About business',
];
const namesItemTwo = [
  'Proof That business Really Works',
  'Here Is What You Should Do For Your business',
];
const SlideList = [
  {
    textPosition: 'text-right',
    bgImage: 'bg_image--32',
    category: '',
    title: 'Kayani',
    description: 'Kayani est un centre de bien-être qui réunit des thérapeutes de différentes disciplines complémentaires.',
  },
  {
    textPosition: 'text-right',
    bgImage: 'bg_image--29',
    category: '',
    title: 'Massothérapie et soins du corps',
    description: 'Kayani est un centre de bien-être qui réunit des thérapeutes de différentes disciplines complémentaires.',
  },
  {
    textPosition: 'text-right',
    bgImage: 'bg_image--11',
    category: '',
    title: 'Thérapies brèves et Hypnothérapie',
    description: 'Kayani est un centre de bien-être qui réunit des thérapeutes de différentes disciplines complémentaires.',
  },

  {
    textPosition: 'text-right',
    bgImage: 'bg_image--24',
    category: '',
    title: 'Thérapies en realtion d\'aide',
    description: 'Kayani est un centre de bien-être qui réunit des thérapeutes de différentes disciplines complémentaires.',
  },
  {
    textPosition: 'text-right',
    bgImage: 'bg_image--33',
    category: '',
    title: 'Soins énergétiques',
    description: 'There are many variations of passages but the majority have suffered alteration.',
    buttonText: 'Découvrir',
    buttonLink: '/cares',
  },
  {
    textPosition: 'text-left',
    bgImage: 'bg_image--18',
    category: '',
    title: 'Ateliers thérapeutiques',
    description: 'There are many variations of passages but the majority have suffered alteration.',
    buttonText: 'Découvrir',
    buttonLink: '/workshops',
  },
];

const ServiceListOne = [
  {
    icon: <FiCalendar />,
    title: 'Planning du mois',
    description: 'Matin ou soir, notre planning des horaires vous permet une grande flexibilité.',
    link: '/calendar'
  },
  {
    icon: <FiPackage />,
    title: 'Nos packs',
    description: 'Débutant ou plus avancé, nous vous proposons des cours adaptés à vos besoins.',
    link: '/packs'
  },
  {
    icon: <FiClock />,
    title: 'Nos événements',
    description: 'Le Yoga, se pratique pieds nus et requiert le port d’une tenue confortable (jogging ou legging).',
    link: '/events'
  },
];



const CorporateBusiness = () => {

  const dispatch = useDispatch();
  const [cares, setCares] = useState([]);
  const [workshops, setWorkshops] = useState([]);
  const [prgs, setPrgs] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data: caresData, status: caresStatus } = await dispatch(fetchPublicOnlyServices({ rowsPerPage: 10, types: [CARE], fullVersion: false }));
        if (caresStatus === 200) {
          const { rows } = caresData;
          setCares(rows);
        }

        const { data: workshopsData, status: wkStatus } = await dispatch(fetchPublicOnlyServices({ rowsPerPage: 10, types: [WORKSHOP], fullVersion: false }));
        if (wkStatus === 200) {
          const { rows } = workshopsData;
          setWorkshops(rows);
        }

        const { data: prgData, status: prgStatus } = await dispatch(fetchOnlyPublicPrograms({ rowsPerPage: 3, fullVersion: false }));
        if (prgStatus === 200) {
          const { rows } = prgData;
          setPrgs(rows);
        }

      } catch (e) {
        console.log(e);
      }
    };
    fetch();
  }, []);


  return (
    <>
      <Helmet pageTitle="Kayani - Centre thérapeutique" />

      {/* Start Header Area  */}
      <Header headerPosition="header--static logoresize" color="color-black" />
      {/* End Header Area  */}

      {/* Start Slider Area   */}
      <div className="slider-wrapper">
        <div className="slider-activation">
          <Slider className="rn-slick-dot dot-light" {...slideSlick}>
            {SlideList.map((value, index) => (
              <div className={`slide slide-style-2 slider-box-content without-overlay d-flex align-items-center justify-content-center bg_image ${value.bgImage}`} key={`slide${index}`}>
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className={`inner ${value.textPosition}`}>
                        {value.category ? <span>{value.category}</span> : ''}
                        {value.title ? <h1 className="title" >{value.title}</h1> : ''}
                        {value.description ? <p className="description">{value.description}</p> : ''}
                        {value.buttonText ? <div className="slide-btn"><Link className="rn-button-style--2 btn-solid" to={`${value.buttonLink}`}>{value.buttonText}</Link></div> : ''}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
      {/* End Slider Area   */}

      {/* Start Service Area */}
      <div className="service-area ptb--30 bg_color--1">
        {/* <div className="service-area creative-service-wrapper ptb--30 bg_color--1"> */}
        <div className="container">
          <div className="row creative-service">
            {ServiceListOne.map((val, i) => (
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                <Link className="text-center" to={val.link}>
                  <div className="service service__style--2">
                    <div className="icon">
                      {val.icon}
                    </div>
                    <div className="content">
                      <h3 className="title">{val.title}</h3>
                      <p>{val.description}</p>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* End Service Area */}

      {/* Start About Area  */}
      <div className="rn-about-area ptb--120 bg_color--5">
        <div className="container">
          <div className="row row--35 align-items-center">
            <div className="col-lg-6">
              <div className="thumbnail">
                <img className="w-100" src="/assets/images/about/space.jpg" alt="About Images" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-inner inner">
                <div className="section-title">
                  <h2 className="title">NOTRE ESPACE</h2>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Enim exercitationem impedit iure quia quo recusandae?</p>
                </div>

                <div className="mt--30">
                  <h4>Lorem ipsum dolor sit.</h4>
                  <ul className="list-style--1">
                    {namesItemOne.map((name, index) => (
                      <li key={index}>
                        <FiCheck />
                        {' '}
                        {name}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mt--30">
                  <h4>Lorem ipsum dolor sit.</h4>
                  <ul className="list-style--1">
                    {namesItemTwo.map((name, index) => (
                      <li key={index}>
                        <FiCheck />
                        {' '}
                        {name}
                      </li>
                    ))}
                  </ul>
                </div>


                <div className="about-button mt--50">
                  <Link className="rn-button-style--2 btn-solid" to="/gallery">Découvrir</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End About Area  */}

      {cares.length > 2 && <HomeCares items={cares} />}
      {prgs.length > 0 && <HomeEvents items={prgs} />}

      {workshops.length > 4 && <HomeActivities items={workshops} />}



      {/* Start About Area  */}
      <HomeAbout />
      {/* End About Area  */}

      {/* Start Pricing Tbale Area  */}
      <HomePricing />
      {/* End Pricing Tbale Area  */}
      {/* Start Footer Style  */}
      <FooterTwo />
      {/* End Footer Style  */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}
    </>
  );
}
export default CorporateBusiness;
