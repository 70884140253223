import React, { Fragment, useEffect, useState } from 'react';

import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import ScrollToTop from 'react-scroll-up';
import { FiCheck, FiChevronUp } from 'react-icons/fi';
import PageHelmet from 'component/common/Helmet';
import Header from 'component/header/Header';
import { useDispatch } from 'react-redux';
import { getNextEventsByService, getService, getServicePacks } from 'state/actions/service';
import { API_URL, WORKSHOP } from 'utils/constantes';
import FooterTwo from 'component/footer/FooterTwo';
import HeaderDetails from '../header/header.details';
import Note from '../common/note';
import { formatDuration, formatDate, formatTime } from 'utils/dateutils';


const WorkshopDetails = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const { id } = location.state || '';

    const [nextSessions, setNextSessions] = useState([])
    const [packs, setPacks] = useState([])

    const [workshop, setWorkshop] = useState({
        capacity: '',
        createdAt: '',
        duration: '',
        fullDescription: '',
        image: '',
        price: '',
        title: '',
        updatedAt: '',
        type: ''
    })
    useEffect(() => {
        if (!id) {
            history.replace('/');
            return;
        }
        dispatch(getService(id)).then(({ data, status }) => {
            if (status === 200) {
                const {
                    capacity,
                    createdAt,
                    duration,
                    fullDescription,
                    image,
                    price,
                    title,
                    updatedAt,
                    type } = data
                setWorkshop({
                    ...workshop,
                    capacity,
                    createdAt,
                    duration,
                    fullDescription,
                    image,
                    price,
                    title,
                    updatedAt,
                    type
                })
            }

        })

        dispatch(getNextEventsByService(id)).then(({ data, status }) => {
            if (status === 200) {
                setNextSessions(data)
            }
        })

        dispatch(getServicePacks(id)).then(({ data, status }) => {
            if (status === 200) {
                setPacks(data)
            }
        })

    }, [id])


    return (
        <Fragment>
            <PageHelmet pageTitle='Atelier' />
            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
            <HeaderDetails
                title={`${workshop.title}`}
                items={[
                    { label: workshop.price ? `${workshop.price} dt/personne` : '', id: 1, icon: 'BiMoney' },
                    { label: workshop.duration ? formatDuration(workshop.duration) : '', id: 2, icon: 'BiTime' },
                    { label: workshop.capacity ? `${workshop.capacity} participants max` : '', id: 3, icon: 'FiUsers' },

                ]}
            />

            {/* Start Blog Details */}
            <div className="rn-blog-details ptb--110 pb--70 bg_color--1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inner-wrapper">
                                <div className="inner">
                                    <p dangerouslySetInnerHTML={{ __html: workshop.fullDescription }}></p>

                                    {nextSessions.length > 0 && <>
                                        <h4 className="title">Prochaines séances:</h4>
                                        <ul className="list-style">
                                            {
                                                nextSessions.map(ev => (
                                                    <li key={ev.id}>
                                                        <strong>{ev.title} </strong>
                                                        {` ${formatDate(ev.date)} de ${formatTime(ev.startTime)} à ${formatTime(ev.endTime)}`}
                                                        <strong style={{ fontSize: 12, color: '#397721' }}>{ev?.coach && ` (coach ${ev.coach.firstname})`}</strong>
                                                    </li>))
                                            }
                                        </ul>

                                    </>
                                    }
                                    <br />
                                    {packs.length > 0 && <>

                                        <h4 className="title">Packs liées:</h4>
                                        <ul className="list-style">

                                            {packs.map(pack => (<li key={pack.id}>
                                                <Link className='client-link' to={`/pack/${pack.id}`}> {`${pack.title} `}</Link>
                                            </li>))}
                                        </ul>
                                    </>}
                                    <Note />

                                    <div className="video-wrapper position-relative mb--40">
                                        <div className="thumbnail" style={{ textAlign: 'center' }} >
                                            {workshop.title && <img className="w-50" src={workshop.image ? `${API_URL}images/service/${workshop.image}` : '/assets/images/default.jpg'} alt={workshop.title} />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Blog Details */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            <FooterTwo />

        </Fragment>
    )
}
export default WorkshopDetails;