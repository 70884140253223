import React, { Fragment } from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from 'react-icons/fi';
import FooterTwo from 'component/footer/FooterTwo';
import PageHelmet from 'component/common/Helmet';
import Header from 'component/header/Header';
import Breadcrumb from 'elements/common/Breadcrumb';
import { API_URL } from 'utils/constantes';
import ListViewItems from 'elements/viewList/view.list';
import { fetchOnlyPublicPrograms } from 'state/actions/program';

const ProgramListComponent = () => {
    const generateLink = (item) => `/event/${item.id}`;
    const imgLink = (value) => value.image ? `${API_URL}images/program/${value.image}` : '/assets/images/default.jpg'
    return (
        <Fragment>
            <PageHelmet pageTitle='événements' />
            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
            <Breadcrumb title={'événements'} />
            <ListViewItems
                generateLink={generateLink}
                imgLink={imgLink}
                fetchAction={fetchOnlyPublicPrograms}
            />
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            <FooterTwo />

        </Fragment >
    )

}

export default ProgramListComponent;