import { ServiceState } from 'state/slices/serviceState';
import { CARE, WORKSHOP } from 'utils/constantes';
import instance from '../../utils/axios/api';



export const addService = (data) => () => instance.put('service/create/', data, {
    headers: {
        'Content-Type': 'multipart/form-data',
    }
});

export const updateService = (data) => () => instance.post('service/update/', data
);

export const updateServicePicture = (data) => () => instance.post('service/update/picture', data, {
    headers: {
        'Content-Type': 'multipart/form-data',
    }
});


export const deleteService = (id) => () => instance.delete(`service/delete/${id}`);

export const getService = (id) => () => instance.get(`service/byid/${id}`);

export const getNextEventsByService = (id) => () => instance.get(`service/nexts/${id}`);

export const getServicePacks = (id) => () => instance.get(`service/packs/${id}`);


export const fetchServices = ({
    page = 0, rowsPerPage = 10, order = 'desc', orderBy = 'updatedAt', searchText = '', types = [WORKSHOP, CARE]
}) => () => instance.post('service/fetch/', {
    page, rowsPerPage, order, orderBy, searchText, types
});


export const fetchPublicOnlyServices = ({
    page = 0, rowsPerPage = 10, order = 'desc', orderBy = 'updatedAt', searchText = '', types = [WORKSHOP, CARE], fullVersion = true
}) => () => instance.post('service/fetch/public/', {
    page, rowsPerPage, order, orderBy, searchText, types, fullVersion
});


export const {
    addType, delType, setSearchText: setText
} = ServiceState.actions;
