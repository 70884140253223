import { Box, IconButton, InputBase, Paper, Stack, Tooltip, Typography } from '@mui/material'
import CustomTablePagination from 'component/common/customTablePagination';
import React, { Fragment, useState } from 'react'
import { FiSearch, FiTrash2 } from 'react-icons/fi'
import { useDispatch } from 'react-redux';
import { deleteClientRequest, fetchClientRequests } from 'state/actions/program';
import Swal from 'sweetalert2';
import { useDebounce } from 'usehooks-ts';
import { PENDING, REJECTED } from 'utils/constantes';
import { formatDateTime, formatTime } from 'utils/dateutils';


const ClientEventRequests = () => {
    const dispatch = useDispatch();
    const fetchData = (pageable) => (dispatch(fetchClientRequests(pageable)));
    const [data, setData] = useState({ rows: [], total: 0 });
    const [searchText, setSearchText] = useState('');
    const debouncedSearchText = useDebounce(searchText, 500);
    const [actions, setActions] = useState({ update: new Date() });


    const [controller, setController] = useState({
        page: 0,
        rowsPerPage: 10,
        order: 'asc',
        orderBy: 'createdAt',
        searchText,
        onlyPending: false
    });

    const handleSearchTextChange = ({ target }) => {
        setSearchText(target.value);
    };


    const handleDeleteRequest = async (request) => {
        const { value } = await Swal.fire({
            title: `Suppression de ${request?.event?.title}`,
            icon: 'warning',
            text: 'Vous ne pourrez pas revenir en arrière !',
            showCancelButton: true,
            confirmButtonText: 'Supprimer',
            cancelButtonText: 'Annuler',

        });
        if (value) {
            dispatch(deleteClientRequest(request.id)).then(() => {
                setActions((state) => ({ ...state, update: new Date() }));
                Swal.fire({
                    icon: 'success',
                    title: 'Suppression',
                    text: `Demande ${request.event.title} supprimé`,
                });
            });
        }
    };


    const fields = [
        { id: 'client.firstname', label: 'client', render: (row) => `${row.client?.firstname} ${row.client?.lastname}` },
        { id: 'client.phone', label: 'numéro', render: (row) => `${row.client?.phone}` },
        { id: 'event.title', label: 'programme' },
        { id: 'event.date', label: 'séance', render: (row) => `${row.event?.date} ${formatTime(row.event?.startTime)} -  ${formatTime(row.event?.endTime)}` },
        {
            id: 'status', label: 'Statut', render: (row) =>
                row.status === PENDING ? <span className="badge badge-warning" style={{ marginTop: '-10px', position: 'absolute' }}>En attente</span>
                    : row.status === REJECTED ? <span className="badge badge-danger" style={{ marginTop: '-10px', position: 'absolute' }}>Déclinée</span>
                        : <span className="badge badge-success" style={{ marginTop: '-10px', position: 'absolute' }}>Validée</span>
        },
        { id: 'createdAt', label: 'Date création', render: (row) => formatDateTime(row.createdAt) },
        { id: 'updatedAt', label: 'Date mise à jour', render: (row) => formatDateTime(row.updatedAt) },
        {
            id: 'id', label: 'actions', render: (row) =>
                <Box style={{ marginTop: -20 }}>
                    {
                        row.status === PENDING &&
                        <Tooltip title="Annuler" onClick={() => dispatch(() => handleDeleteRequest(row))}>
                            <IconButton  >
                                <FiTrash2 style={{ color: '#dc3545', fontSize: 18 }} />
                            </IconButton>
                        </Tooltip>
                    }
                </Box>
        }
    ];

    return (

        <Fragment>
            <div className="rn-blog-area ptb--20 bg_color--1">
                <div className="container">

                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography gutterBottom>
                            <b style={{ textTransform: 'uppercase' }}>{`TOTAL : ${data.total} `}</b>
                        </Typography>
                        <Paper sx={{ width: 200, height: 40, display: 'flex', alignItems: 'center' }}>

                            <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                placeholder="Recherche"
                                inputProps={{ 'aria-label': 'Recherche' }}
                                value={searchText}
                                onChange={handleSearchTextChange}

                            />
                            <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                                <FiSearch />
                            </IconButton>
                        </Paper>
                        {' '}
                    </Stack>
                    <CustomTablePagination
                        controller={controller}
                        setController={setController}
                        fields={fields}
                        actions={actions}
                        searchText={debouncedSearchText}
                        fetchData={fetchData}
                        data={data}
                        setData={setData}
                    />
                </div>
            </div>
        </Fragment>
    )
}

export default ClientEventRequests